// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .node-type-wngv-web-product {
    .top-product {
      .sse-product-texts {
        .product-features {
          width: 100%;
        }
      }
      .main-tabs-container {
        .tabs-container {
          .tabs {
            .tab {
              padding: 15px .625rem;
              .tab-content {
                .tab-pane {
                  .title-mobile {
                      @include x-rem('font-size', 18px);
                      font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
    }

    .sse-product-related {
      @include x-rem('padding-left', 10px);
      @include x-rem('padding-right', 10px);

      .field-name-field-web-related-product {
        & > .field-items {
          flex-wrap: wrap;
          justify-content: flex-start;

          & > .field-item {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px); // HACK IE11
            @include x-rem('margin-right', 10px);
            @include x-rem('margin-bottom', 10px);

            &:last-child {
              @include x-rem('margin-right', 10px);
              @include x-rem('margin-bottom', 10px);
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  .node-type-wngv-web-product {
    .top-product {
      padding: 0 0 30px;
      .sse-product-img-variant-addr {
        display: block;

        .sse-product-img {
          margin: 0 auto;
        }

        .introduction {
          .cta-links {
            display: block;
            margin-top: 20px;

            .cta-link {
              @include x-rem('font-size', 20px);
              margin: 10px auto;
            }
          }
        }
      }
      .sse-product-texts {
        .video-container {
          width: 100%;
        }
      }
      .main-tabs-container {
        .tabs-container {
          div.tabs {
            div.tab {
              div.tab-content {
                .tab-pane {
                  flex-direction: column;
                  .title-mobile {
                    order: 1;
                  }
                  .col-left_text {
                    order: 3;
                  }
                  .col-right_image {
                    order: 2;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  div.node-wngv-web-product {
    &.node-teaser {
      h1 {
        @include x-rem('font-size', 14px);
        line-height: 16px;
      }

      div.more-details {
        .btn {
          @include x-rem('padding', 12px 20px);
          @include x-rem('font-size', 11px);
        }
      }
    }
  }
}
