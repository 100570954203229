// ### RESET
@import "base/_normalize";

// ### HELPERS
@import "helpers/_variables";
@import "helpers/_mixins";

// ### BASE
@import "base/_global";
@import "base/_layout";
@import "base/_typo";
@import "base/_admin";
@import "base/_responsive";

// ### COMPONENTS WEBSITE
@import "components/website/_global";
@import "components/website/_header";
@import "components/website/_footer";
@import "components/website/_arrows";
@import "components/website/_form";
@import "components/website/_breadcrumb";
@import "components/website/_user";
@import "components/website/_commonform";
@import "components/website/_banner";
@import "components/website/_text-vs-image";
@import "components/website/_homepage";
@import "components/website/_offers";
@import "components/website/_type-product";
@import "components/website/_product";
@import "components/website/_business_line";
@import "components/website/_partners";
@import "components/website/_eden_in";
@import "components/website/_career";
@import "components/website/_help";
@import "components/website/_well-being";
@import "components/website/_blog";
@import "components/website/_faq";
@import "components/website/_repertory";
@import "components/website/_popin";
@import "components/website/_cta";
@import "components/website/_template-landing";
@import "components/website/_messages";
@import "components/website/_admin";
@import "components/website/_template-landing-form-column";
@import "components/website/_business_line_cro";
@import "components/website/_banner_cro.scss";
@import "components/website/_catalog.scss";
@import "components/website/_client.scss";

// ### COMPONENTS WEBSITE RESPONSIVE
@import "components/website/responsive/_header";
@import "components/website/responsive/_footer";
@import "components/website/responsive/_webform";
@import "components/website/responsive/_breadcrumb";
@import "components/website/responsive/_user";
@import "components/website/responsive/_commonform";
@import "components/website/responsive/_banner";
@import "components/website/responsive/_text-vs-image";
@import "components/website/responsive/_homepage";
@import "components/website/responsive/_offers";
@import "components/website/responsive/_product";
@import "components/website/responsive/_partners";
@import "components/website/responsive/_eden_in";
@import "components/website/responsive/_career";
@import "components/website/responsive/_help";
@import "components/website/responsive/_well-being";
@import "components/website/responsive/_blog";
@import "components/website/responsive/_faq";
@import "components/website/responsive/_repertory";
@import "components/website/responsive/_popin";
@import "components/website/responsive/_cta";
@import "components/website/responsive/_template-landing";
@import "components/website/responsive/_admin";
@import "components/website/responsive/_template-landing-form-column";
@import "components/website/responsive/_business_line_cro";
@import "components/website/responsive/_banner_cro.scss";
@import "components/website/responsive/_catalog.scss";

// ### DIGITAL ONBOARDING COMPONENTS
@import "components/digital_onboarding/_header";
@import "components/digital_onboarding/_footer";
@import "components/digital_onboarding/_digital-onboarding";

// ### DIGITAL ONBOARDING COMPONENTS RESPONSIVE
@import "components/digital_onboarding/responsive/_digital-onboarding";

// ### RESIDENTIAL ONBOARDING COMPONENTS
@import "components/rob/_header";
@import "components/rob/_rob";

// ### RESIDENTIAL ONBOARDING COMPONENTS RESPONSIVE
@import "components/rob/responsive/_rob";

.simple-login{

  #quick-links{
    display: none;
  }
}