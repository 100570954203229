// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  body.node-type-wngv-business-line-cro {
    .waves {
      .icons {
        flex-wrap: wrap;

        .icon-item {
          flex: 0 0 50%;
          margin-bottom: 10px;

          &:first-child,
          &:last-child {
            margin-top: 0;
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .packages {
      .field-name-field-three-pack-ref {
        & > .field-items {
          display: block;

          & > .field-item {
            margin-bottom: 60px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    // Flexbox component
    .flexbox-component {   
      .field-name-field-flexbox-items {
        .field-items {
          display: flex;        
          flex-direction: column;
        }
      }
    }
  }
}
