// MOBILE < 490
@media only screen and (max-width: $size-medium-1) {
  body:not(.page-digital-onboarding) {
    .page {
      .breadcrumb {
        top: 10px;

        .breadcrumbs {
          @include x-rem('font-size', 12px);
          line-height: 12px;
        }
      }
    }
  }

  body.node-type-wngv-web-product {
    .page {
      .breadcrumb {
        padding: 10px;
        position: static;
      }
    }
  }
}

