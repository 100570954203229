#block-edensprings-website-ng-welcome-popin-eden-welcome-popin {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8) 100% no-repeat;
  z-index: 802;
  display: none;

  #eden-welcome-popin {
    top: 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    img {
      display: block;
    }

    .close-popin {
      position: absolute;
      top: 0;
      right: 0px;
      width: 40px;
      height: 40px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 5px;
        right: 5px;
        content: '×';
        @include x-rem('font-size', 40px);
        line-height: 32px;
        font-weight: normal;
        color: #fff;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        text-align: center;
        background-color: transparent;
        -webkit-transition: background-color .25s;
        transition: background-color .25s;
        z-index: 803;
        width: 30px;
        height: 30px;
      }
    }
  }
}
