// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .node-type-wngv-repertory {
    .main-banner-title {
      .left {
        width: 100%;
      }
    }

    .locations {
      .field-name-field-location {
        & > .field-items {
          & > .field-item {
            flex: 0 0 calc(50% - 30px);
            max-width: calc(50% - 30px);
          }
        }
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .node-type-wngv-repertory {

    .main-banner-title {
      top: 100px;

      .left {
        width: 100%;

        h1 {
          @include x-rem('font-size', 30px);
          line-height: 28px;
        }

        .field-name-field-description {
          display: none; // to see the popin
        }
      }
    }

    .locations {
      h2 {
        @include x-rem('font-size', 24px);
        line-height: 24px;
      }

      .field-name-field-location {
        & > .field-items {
          display: block;

          & > .field-item {
            max-width: 100%;
            margin-right: 0;

            .location {
              display: block;
              text-align: center;

              .location-container {
                display: block;

                .image {
                  margin: 0;
                }

                .infos .phone-number {
                  text-align: center;
                }
              }

              .links {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
