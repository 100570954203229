html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  background-color: #fff;
  margin: 0;

  &:not(.cke_editable) {
    span.current-month {
      display: none;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

ol, ul {
  list-style: none;
}

figure {
  margin: 0;
}

div {
  word-wrap: break-word;
}

a,
span.js-link {
  cursor: pointer;
  color: $grey;
  text-decoration: none;

  &:hover {
    color: $blue_light;
    text-decoration: none;
  }
}

button.ui-btn {
  background: #fff;
  border-radius: 30px;
  color: $blue;
  border: 1px solid $blue;
  @include x-rem('padding', 12px 25px);
  font-weight: bold;
  @include x-rem('font-size', 12px);
  @include x-rem('letter-spacing', 1.70px);
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 1.5px 1.5px 0 $blue;
  }

  &.blue {
    background: $blue;
    color: #fff;

    &:hover {
      box-shadow: 1.5px 1.5px 0 $blue_grey;
    }
  }

  &:disabled {
    background: $grey_disabled;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../images/select-arrow.png) no-repeat right 5px;
}

// IE10+ remove arrow on select.
select::-ms-expand {
  display: none;
}

// Remove loader ajax
.ajax-progress-throbber {
  display: none;
}

// remove platform.sh black bar on self-service
#platform-indicator {
  display: none!important;
}

body.platform-indicator {
  margin-bottom: 0;
}

body.hide-main-menu {
  .menu {
    display: none;
  }

  #header-content {
    margin: 8px 0;
  }
}

// IE message
.browser-update {
  background: yellow;
  text-align: center;
  padding: 10px;
  margin: 0;
  color: #000;

  a {
    color: $orange;

    &:hover {
      text-decoration: underline;
    }
  }
}
