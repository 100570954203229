body.page-residential-onboarding,
body.page-business-onboarding {

  &.disable-click {
    pointer-events:none;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      background: rgba(0,0,0,0.2);
      display: block;
      z-index: 999;
    }
  }
  .page {
    background: none;
  }

  .ss-messages {
    margin-top: 20px;

    a {
      color: $blue_light;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .page-step {
    padding: 30px 0;
  }

  .top-btn {
    text-align: right;
    margin-bottom: 10px;

    a.btn-back {
      color: $grey_value;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  label {
    line-height: 18px;
  }

  // Step 1.
  #edensprings-rob-customer-form-step1 {
    width: 75%;
    margin: 80px auto;
    text-align: center;

    .zip-intro {
      margin-bottom: 30px;
      display: block;
      font-weight: bold;
    }

    label {
      font-weight: normal;
    }

    input#edit-zip-code {
      width: auto;
    }

    input#edit-zip-code:not(:placeholder-shown) {
      text-transform: uppercase;
    }

    input#edit-city {
      width: 50%;
    }
  }

  // Step 2.  
  #edit-main-container {
    display: flex;
    //border-bottom: 1px solid $grey_disabled;

    input#edit-zip-code:not(:placeholder-shown) {
      text-transform: uppercase;
    }

    #edit-left {
      flex: 1;
    }

    #edit-right {
      flex: 0 0 40%;
      margin: 0 0 0 15px;

      span.form-required {        
          display: none;
      }      

      #edit-bottles {
        color: $blue_light;
      }

      .discount-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .form-item-discount-input {
          flex: 0 0 200px;
          padding: 0;
          margin-right: 10px;

          input {
            padding: 8px 15px;
          }
        }

        #edit-discount-btn {
          border-radius: 0;
          padding: 5px 10px;
          @include x-rem('font-size', 16px);
          line-height: 28px;

          &:not(.form-disabled) {
            background-color: $blue_light;

            &:hover {
              background-color: $blue;
            }
          }
        }

        &.active {
          #edit-discount-btn {
            &:not(.form-disabled) {
              background-color: $grey_disabled;
              color: $grey;

              &:hover {
                background-color: $grey_disabled;
                color: $grey;
              }
            }
          }
        }
      }

      & > .form-item-delivery-flag {
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grey_disabled;

        #edit-delivery-flag {
          .form-item {
            margin: 0;
            padding: 0 0 5px 0;

            label {
              @include x-rem('font-size', 15px);
            }
          }
        }

        .description {
          color: $grey_transparent;
          background: none;
        }
      }
    }
  }

  #edit-sub-container {
    width: 40%;
    margin-left: auto;
    padding-top: 10px;

    .form-item-data-collect-email, .form-item-data-collect-email-business, .form-item-data-collect-sms, .form-item-data-collect-sms-business, .form-item-data-collect-phone, .form-item-data-collect-phone-business, .form-item-data-collect-post, .form-item-data-collect-post-business{
      padding-left: 30px;
      display: table-cell;
    }
  }

  .secure-img {
    text-align: right;
    margin-top: 20px;
  }

  .payment-methods {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    flex-wrap: wrap;

    img {
      margin: 0 0 15px 10px;
      height: 40px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  // Step 3
  #edensprings-rob-customer-form-step3 {
    #edit-payment-methods {
      margin-bottom: 15px;
      padding-bottom: 15px;

      .form-item-payment-methods {
        margin: 10px 0;
        padding: 10px 0;
        border-bottom: 1px solid $grey_light;
        align-items: center;
        display: none;

        &.validated {
          display: flex;
        }

        label {
          display: flex;
          border-bottom: 3px solid $white;
          cursor: pointer;
          border-radius: 3px;
          width: 100%;
          color: $blue;
          font-weight: 800;
          flex-wrap: wrap;

          .img-container {
            flex: 1;
            text-align: right;

            img {
              display: inline-block;
            }
          }

          &[for="edit-payment-methods-cc-card"] {

            .img-container {
              display: flex;
              justify-content: flex-end;

              img {
                margin: 0 5px;
              }
            }
          }

          .payment-disclaimer {
            margin-top: 10px;
            display: none;
            @include x-rem('font-size', 14px);
            line-height: 18px;
            color: $grey_transparent;
            font-weight: normal;
            flex-basis: 100%;
          }
        }

        &:last-child {
          border: none;
        }

      }
    }

    .rent-payment-methods {
      #edit-payment-methods {
        .form-item-payment-methods {
          display: flex;
        }
      }
    }
    .payment-description {
        @include x-rem('font-size', 16px);
        color: $blue_light;
        font-weight: bold;
    }
  }

  // Step IBAN
  #edensprings-rob-customer-form-stepiban {
     #edit-main-container {
      #edit-left {
        .direct-debit-description {
          @include x-rem('font-size', 16px);
          color: $blue_light;
          font-weight: bold;
          p {
            margin-top: 0;
          }
        }
      }
     }
  }

  // Step 4
  #edensprings-rob-customer-form-step4 {
    input[type="text"] {
      font-weight: 500;

      &:not(:placeholder-shown) {
        border-color: $grey_value;
      }
    }

    #edit-left {
      width: 75%;
    }

    .row {
      display: flex;
      align-items: center;
      margin: 15px 0 30px;
    }

    .SfcField {
      border-radius: unset;
      @include x-rem('padding', 12px 20px);
      border: 1px solid $grey_value;
      color: $grey_value;
      width: 100%;
      position: relative;
      margin-right: 15px;
      text-align: center;
    }

    #card-number {
      flex: 0 0 230px;
      text-align: left;
    }

    #card-expiry {
      flex: 0 0 130px;
    }

    #card-cvc {
      flex: 0 0 80px;
    }

    .form-item {
      padding: 0;
      &.form-item-keep-cc {
        padding: 10px 0;
      }
    }
  }

  // Step 5 & Step iban
  #edensprings-rob-customer-form-step5,
  #edensprings-rob-customer-form-stepiban {
    .payment-disclaimer {
      margin-top: 10px;
      @include x-rem('font-size', 14px);
      line-height: 18px;
      color: $grey_transparent;
      font-weight: normal;
      flex-basis: 100%;
    }
    label {
      display: flex;
    }

  }

  // Step 6
  #edensprings-rob-customer-form-step6 {
    iframe {
      margin: auto;
      min-width: 280px;
      max-width: 600px;
    }
  }

  // Step Signaturit
  #edensprings-rob-customer-form-stepagreement {
    #iframe-signaturit {
      border: none;
      height: 800px;
    }
    #edit-submit {
      display: none;
    }
  }

  .package-price {
    margin-top: 10px;

    .discount-header {
      text-align: right;
      margin-bottom: 2px;
      @include x-rem('font-size', 16px);
      font-weight: bold;
    }

    .discount-message {
      text-align: right;
      margin-bottom: 10px;
      color: $red;
    }

    .rental-price,
    .price-without-vat,
    .vat,
    .total-price {
      display: flex;
      padding-bottom: 10px;
      margin: 0 0 10px;
      justify-content: flex-end;
      border-bottom: 1px solid $grey_disabled;
      align-items: center;

      .label {
        font-weight: normal;
        color: $grey_label;
        margin-right: 10px;
      }

      .old-price {
        @include x-rem('font-size', 16px);
        line-height: 18px;
        text-decoration: line-through;
        width: 85px;
        text-align: center;
      }

      .price {
        @include x-rem('font-size', 20px);
        color: $blue;
        font-weight: bold;
        line-height: 20px;
        text-align: right;
        width: 150px;

        span {
          display: block;
        }
      }

      .price-suffix {
        font-weight: normal;
        @include x-rem('font-size', 16px);
      }

      .price-discount {
        margin-left: 10px;
        span {
          color: $red;
        }
      }
    }

    //.rental-price,
    .price-without-vat,
    .vat {
      border: 0;
      @include x-rem('font-size', 16px);
      padding: 0;
      margin-bottom: 10px;
      line-height: 16px;
    }


    .price-without-vat,
    .vat {
      margin: 0 0 5px;

      .old-price {
        @include x-rem('font-size', 16px);
        color: $grey_label;
      }

      .price {
        @include x-rem('font-size', 16px);
        color: $grey_label;
      }

    }
  }

  .package-option {
    text-align: right;
    line-height: 18px;
    margin-bottom: 10px;

    .label {
      font-weight: bold;
      color: $blue;
    }
  }

  .form-line {
    display: flex;
    margin-bottom: 15px;

    .form-item {
      flex: 0 0 calc(50% - 10px);
      padding: 0;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    &.three-item {
      .form-item {
        margin-right: 15px;
        flex: 0 0 calc(100% / 3 - 10px);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.full-width {
      .form-item {
        flex: 0 0 100%;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .disclaimer-field {
    color: $blue_light;
    @include x-rem('font-size', 14px);
    line-height: 16px;
  }

  .disclaimer-deposit {
    font-weight: bold;
    @include x-rem('font-size', 14px);
    line-height: 16px;
  }

  .disclaimer-charges {

  }

  .disclaimer-payment {
    margin: 20px 0 10px;
    @include x-rem('font-size', 18px);
    line-height: 20px;
  }

  // Will be used step 2 and thank you page.
  .rob-package {
    padding: 10px 15px;
    @include box-shadow-content();
    background: $white;
    margin-bottom: 10px;
    h2 {
      @include x-rem('font-size', 24px);
      font-weight: bold;
      margin: 0 0 15px;
    }

    .package-name {
      display: block;
      color: $blue;
      font-weight: bold;
      margin-bottom: 5px;
      @include x-rem('font-size', 18px);
    }

    .package-info {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      img {
        flex: 0 0 200px;
      }

      .package-description {
        flex: 0 0 calc(100% - 200px);
        margin-left: 10px;

        .desc {
          font-weight: 600;
          margin-bottom: 15px;
          line-height: 18px;
        }

        .label {
          font-weight: 600;
          color: $grey_label;
        }

        .value {
          color: $blue_light;
          font-weight: bold;
          @include x-rem('font-size', 18px);
        }

        .total {
          margin-top: 10px;
        }
      }
    }

    .package-disclaimer {
      @include x-rem('font-size', 14px);
      line-height: 14px;
      font-weight: 500;
    }
  }

  .form-item-bottles {
    & > label {
      display: flex;
    }
  }

  // TOOLTIP ELEMENTS
  .description {
    text-align: left;
    background: url(../images/help.png) no-repeat left center;
    padding-left: 20px;
    margin-left: 6px;
    color: #06A7E0;
  }

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */

    &:hover {
      .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
    }

    /* Tooltip text */
    .tooltiptext {
      visibility: hidden;
      min-width: 160px;
      max-width: 330px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 10px;
      border-radius: 6px;

      /* Position the tooltip text */
      position: absolute;
      z-index: 1;

      /* Fade in tooltip */
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltip-right {
      top: -5px;
      left: 125%;
    }
  }
  

  // CONFIRMATION PAGE.
  .confirmation-page,
  .payment-in-progress-page {
    padding: 30px 0 0;

    h1 {
      text-align: center;
    }

    .text {
      margin-bottom: 30px;
    }

    .information {
      display: flex;

      .info-summary {
        flex: 0 0 60%;
        // max-width: 60%;
        display: flex;

        h2 {
          margin-top: 0;
        }

        & > div {
          margin-right: 30px;
          // flex: 0 0 calc(50% - 30px);
          // max-width: calc(50% - 30px);
        }

        .item {
          margin-bottom: 5px;
        }

        .label {
          color: $blue_light;
          font-weight: 600;
          display: block;
          line-height: 18px;
        }
      }

      .package-container {
        flex: 1;

        .rob-package {
          width: 100%
        }
      }
    }

    .total-price {
      border: none;
      padding: 0;
      margin: 0;
    }
  }


  // GENERAL ELEMENTS.
  textarea {
    width: 100%;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey_value;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $grey_value;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $grey_value;
  }

  .submit-btn {
    display: flex;
    justify-content: flex-end;

    img {
      display: none;
      width: 45px;
      height: 45px;
      margin: 5px 0 5px 15px;
    }
  }

  input[type="submit"] {
    margin: 5px 0;
    @include x-rem('font-size', 18px);
    font-weight: 500;
  }

  input[type="submit"]:not(:disabled) {
    background: $orange;

    &:hover {
      background: $orange_cta;
    }
  }


  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  .one-option{
    [type="radio"]:checked + label:before {
      border: 0;
    }

    [type="radio"]:checked + label{
      padding-left: 25px;
    }
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $blue_light;
    position: absolute;
    left: 4px;
    top: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .one-option{
    [type="radio"]:checked + label:after{
      background: #fff;
    }
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .form-item {
    label {
      margin-bottom: 5px;

      p {
        margin: 0;
        line-height: normal;
      }
    }

    &.form-type-checkbox {
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
        flex: 0 0 14px;
      }

      label {
        margin-bottom: 0;
      }

      &.form-item-different-billing {
        margin-bottom: 20px;
      }

      &.form-item-different-postal {
        margin-bottom: 20px;
      }

      &.form-item-different-headquarter {
        margin-bottom: 20px;
      }
    }

    &.form-item-comment {
      padding: 0;
      margin-bottom: 15px;
    }
  }

  &.sticky {
    .page {
      padding-top: 0;
    }
  }

  .rob-breadcrumb {
    display: flex;
    justify-content: space-around;
    margin: 15px auto 30px;
    width: 100%;

    .rob-step {
      text-align: center;
      position: relative;
      flex: 0 25%;

      a.step-circle, span.step-circle {
        height: 40px;
        width: 40px;
        background: #fff;
        border: 2px solid #EC671D;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        display: inline-block;
        color: #EC671D;
        margin-bottom: 10px;
        font-weight: bold;
        @include x-rem('font-size', 18px);
      }

      span {
        display: block;
        color: #EC671D;
        font-weight: bold;
        @include x-rem('font-size', 16px);
        letter-spacing: 1px;
        line-height: 18px;
        padding: 0 5px;
      }

      &:first-child:before {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: -50%;
        height: 2px;
        background: #EC671D;
        width: 100%;
        z-index: -1;
      }

      &.completed {
        a.step-circle, span.step-circle {
          background: #EC671D;
          color: #fff;
        }
      }

      &.pending {
        a.step-circle, span.step-circle {
          border-color: $grey_value;
          color: $grey_value;
        }
        span {
          color: $grey_value;
        }
        &:before {
          background: $grey_value;
        }
      }

      &.current {
        span.step-circle {
          background: #fff;
          color: #EC671D;
        }
      }
    }

    &.small {
      .rob-step {
        flex: 0 33.33%;
      }
    }

    &.very-small {
      .rob-step {
        flex: 0 50%;
      }
    }
  }

  &.i18n-ru, &.i18n-hu {
    // Step 3
    #edensprings-rob-customer-form-step3 {
      #edit-payment-methods {
        .form-item-payment-methods {
          display: flex;
        }
      }
    }
  }
}
