.partners {

  .content {
    margin: 30px auto 0;
  }

  .field-name-field-wngv-partners-partner-ref {
    & > .field-items {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: center;

      & > .field-item {
        margin: 50px 0;
        flex: 0 0 33%;
      }
    }
  }

  .partner {
    position: relative;

    .partner-image {
      margin: 0 10px;

      img {
        display: block;
        margin: auto;
        cursor: pointer;
        filter: grayscale(1);
        opacity: 0.5;

        &:hover,
        &.active {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }

    .partner-content {
      opacity: 0;
      //display: flex;
      display: none;
      //position: absolute;
      position: relative;
      z-index: 0;
      //top: calc(100% + 30px);
      height: 350px;
      width: 100%;
      background: $white;
      @include box-shadow-content();
      padding: 20px 15px 15px;
      flex-direction: column;
      justify-content: center;
      transition: opacity 0.5s ease;
      margin-top: 30px;

      .partner-description {
        margin-bottom: 10px;

        .field-name-body {
          max-height: 280px;
          overflow: hidden;

          p {
            text-align: center;
            @include x-rem('font-size', 15px);
            letter-spacing: 1.5px;
            line-height: 20px;
            color: $blue;
          }
        }
      }

      .partner-link {
        text-align: center;

        div {
          display: inline;
        }

        i {
          color: $blue_light;
          @include x-rem('font-size', 18px);
          margin: 0 5px;
        }

        a {
          color: $blue_light;
          @include x-rem('font-size', 13px);
          text-decoration: underline;
          font-weight: bold;
        }
      }

      &.active {
        opacity: 1;
        z-index: 3;
        display: flex;
      }

      .arrow {
        background: $blue_light;
        border-radius: 50%;
        padding: 10px;
        @include x-rem('font-size', 16px);
        font-weight: bold;
        color: $white;
        box-shadow: -2px 2px 6px -3px rgba(0,0,0,0.75);
        position: absolute;
        top: -15px;
        left: calc(50% - 10px);
      }
    }
  }
}
