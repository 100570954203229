.page-digital-onboarding {
  footer {
    a:hover {
      text-decoration: underline;
    }

    #block-edensprings-digital-onboarding-do-footer-social {

      ul#social_list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0;

      }

      ul#social_list li {
        display: inline-block;
        padding: 0;
        margin: 0;

        a {
          display: block;
          text-indent: -9999px;
          width: 50px;
          height: 50px;
          transition: all 0.2s ease-in-out;
        }

        &.facebook {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -12px -72px transparent;
            &:hover {
              background-position: -12px -12px;
              transform: scale(1);
            }
          }
        }
        &.twitter {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -150px -72px transparent;
            &:hover {
              background-position: -150px -12px;
              transform: scale(1);
            }
          }
        }
        &.linkedin {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -81px -72px transparent;
            &:hover {
              background-position: -81px -12px;
              transform: scale(1);
            }
          }
        }
        &.youtube {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -218px -72px transparent;
            &:hover {
              background-position: -218px -12px;
              transform: scale(1);
            }
          }
        }
        &.googleplus {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -286px -72px transparent;
            &:hover {
              background-position: -286px -12px;
              transform: scale(1);
            }
          }
        }
        &.instagram {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -355px -72px transparent;
            &:hover {
              background-position: -355px -12px;
              transform: scale(1);
            }
          }
        }
        &.pinterest {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -424px -72px transparent;
            &:hover {
              background-position: -424px -12px;
              transform: scale(1);
            }
          }
        }
        &.vk {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -487px -72px transparent;
            &:hover {
              background-position: -487px -12px;
              transform: scale(1);
            }
          }
        }
        &.ok {
          & > a {
            background: url('../../edensprings/images/sprite_social.png') no-repeat -543px -72px transparent;
            &:hover {
              background-position: -543px -12px;
              transform: scale(1);
            }
          }
        }
      }
    }

    #block-edensprings-digital-onboarding-do-footer {
      padding: 25px 0;
      background-color: #5a5b5f;
      text-align: center;
      color: #fff;

      .mobile-grid-100 {
        display: inline-block;
        margin: 0;

        li {
          display: inline-block;

          a {
            color: #fff;
          }
        }
      }
    }

    #block-edensprings-digital-onboarding-do-footer-2 {
      padding: 10px 0 30px 0;
      text-align: center;

      a.office_address:before {
        display: inline-block;
        vertical-align: middle;
        background: url(../../edensprings/images/spritebtn.png) no-repeat -44px -83px transparent;
        width: 29px;
        height: 29px;
        content: '';
        margin-right: 10px;
      }

      a.office_address:hover:before {
        background-position: -5px -83px;
      }
    }
  }
}
