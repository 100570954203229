*{
  @include box-sizing(border-box);

  &::before,
  &::after {
    @include box-sizing(border-box);
  }
}

.container {
  max-width: 1200px;
 // padding: 0 10px;
  margin: 0 auto;
}

.full-container {
  background: $white;
}

.page {
  overflow: hidden;
  background: url('../images/background-small.png') no-repeat center 350px fixed;
  background-size: cover;
  @include x-rem('padding-bottom', 60px);

  section[role="main"] {
    .container-bs {
      background: $white;
      @include box-shadow-content();
      @include x-rem('padding', 20px);
    }
  }
}

.wng:not(.page-gaq):not(.node-type-wngv-career) .page,
.cng .page,
.page-pre-register .page,
body.i18n-il .page {
  background: none;
}
