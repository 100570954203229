@mixin box-shadow-content() {
  box-shadow: 0 2px 5px 0 rgba(69, 73, 91, 0.16);
}

@mixin font-style($family, $weight: normal, $family_2: helvetica) {
  font-family: $family, $family_2, helvetica, arial, sans-serif;
  font-weight: $weight;
}


@mixin x-rem($property, $values) {
  // Create a couple of empty lists as output buffers.
  $base-font-size: $base__height; // should be consistent with your html/body font-size
  $px-values: ();
  $rem-values: ();

  // Loop through the $values list
  @each $value in $values {

    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value == 0 or $value == 0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
      // px value given - calculate rem value from base-font-size

      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{ $value / $base-font-size }rem);

    } @else if $value == auto or $value == inherit {
      // auto or inherit - don't add px or rem
      $px-values: join($px-values, $value);
      $rem-values: join($rem-values, $value);
    }
    @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
}

@mixin box-sizing($box) {
  -webkit-box-sizing: $box;
  -moz-box-sizing: $box;
  box-sizing: $box;
}

/* EXTEND */
.spacing-reset {
  margin: 0;
  padding: 0;
}

@mixin font-face( $font-name, $font-path, // path + file name. Ex : path/to/font/filename
$font-weight: 400, $font-style: normal, $support-required: 'modern') {
  @font-face {
    font-family: $font-name;
    font-style : $font-style;
    font-weight: $font-weight;
    @if $support-required=='oldie' {
      src: url('#{$font-path}.eot');
      src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
      //url('#{$font-path}.woff2') format('woff2'),
      url('#{$font-path}.woff') format('woff'),
      url('#{$font-path}.ttf') format('truetype');
    }
    @if $support-required=='recent' {
      //src: url('#{$font-path}.woff2') format('woff2'),
      src: url('#{$font-path}.woff') format('woff'),
      url('#{$font-path}.ttf') format('truetype');
    }
    @if $support-required=='modern' {
      //src: url('#{$font-path}.woff2') format('woff2'),
      src: url('#{$font-path}.woff') format('woff');
    }
  }
  @if $support-required !='oldie' and $support-required !='recent' and $support-required !='modern' {
    @error 'Invalid support-required value. Must be "oldie"==>IE8 and above/ Android native browser 4+/ Safari 4.3 OR "recent"==>IE9+/ Android native browser 4.0+/ Safari 4.3+ OR "modern"==>IE9+/ Android native browser 4.4+/ Safari 5.1+';
  }
}