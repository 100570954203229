.main-banner-cro {
  position: relative;
  margin-bottom: 60px;

  .slide-item {
    overflow: hidden;

    & > .content {
      .container {
        display: flex;
      }
    }

    .field-name-field-image-background-unique {
      img {
        display: block;
        position: relative;
        left: 50%;
        margin-left: -960px;
        border-bottom-left-radius: 65% 20%;
        border-bottom-right-radius: 65% 20%;
        max-width: none;
        height: 500px;
      }
    }

    .content {
      position: absolute;
      width: 100%;
      top: 115px;
      z-index: 3;
      left: 0;
      right: 0;
      margin: auto;
      max-height: 75%;
      overflow: hidden;

      .banner-texts-center,
      .banner-texts-left {
        width: 50%;
        margin-right: 20px;

        .title {
          display: inline-block;
          font-weight: 800;
          color: $blue;
          @include x-rem('font-size', 41px);
          @include x-rem('letter-spacing', 2px);
          @include x-rem('line-height', 47px);
          padding-bottom: 15px;
        }

        .field-name-field-wngv-tvip-subtitle {
          width: 100%;
          color: $blue;
          @include x-rem('font-size', 18px);
          @include x-rem('line-height', 22px);
          font-weight: 400;
          margin: 0;
        }
      }

      .banner-texts-center {
        margin: 0 auto;
        text-align: center;
      }

      .package-infos {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .package-img {
          flex: 0 0 240px;
          margin-right: -30px;
          z-index: 3;
        }

        .package-content {
          //flex: 0 0 50%;

          .packages-desc {
            padding: 25px 25px 25px 45px;
            background-color: $white;
            text-align: center;

            .field-name-field-unique-textfield {
              color: $blue;
              @include x-rem('font-size', 18px);
              font-weight: 800;
              line-height: 18px;
              margin-bottom: 10px;
            }

            .field-name-field-max-three-textfields {
              @include x-rem('font-size', 16px);
              line-height: 15px;

              .field-item {
                padding: 2px 0;
              }

              .field-item:before {
                content: '●';
                margin-right: 4px;
              }
            }
          }

          .package-button {
            text-align: center;

            a {
              background: $orange;
              display: inline-block;
              padding: 10px 35px;
              color: white;
              font-weight: 600;
              border-radius: 25px;
              margin-top: -25px;
            }
          }
        }

      }
    }
  }

}

// ISRAEL
html[dir="rtl"] {
  .main-banner {
    .slide-item {
      img {
        left: auto;
        margin-left: 0;
        margin-right: -960px;
        right: 50%;
      }
    }
  }
}
