// DESKTOP < 1200
@media only screen and (max-width: $size-large-1) {
  .node-type-wngv-others {
    .career-form {
      width: 60%;
    }
  }
}

// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1) {
  .node-type-wngv-others {
    .career-form {
      width: 80%;
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1) {

  .job-offer {
    .content-short {
      h3 {
        display: block;
        margin: 0;
      }
    }

    .job-offer-body {
      .body-padding {
        .lists {
          display: block;
        }
      }
    }
  }

  // FORM ///
  .career-form {
    padding: 15px 10px;
    
    & > div,
    & > form {
      width: 100%;
    }
  }

  .node-type-wngv-others {
    .career-form {
      width: 100%;
      padding: 15px 10px;
    }
  }
}
