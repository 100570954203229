body.node-type-wngv-business-line-cro {
  .page {
    .breadcrumb {
      top: 25px;
    }

    .content {
      & > div:last-child {
        margin-bottom: 0;
      }
    }
  }

  ///--- TEXT BLOCK ---///
  article.node-text-block {
    margin: 0 auto;
    padding: 30px 0;

    div.node-text-block {
      max-width: 1200px;
      margin: 0 auto;

      .field-name-body {
        p {
          padding: 0;
        }
      }

      h1 {
        padding: 0;
      }
    }
  }

  .textblock-top {
    margin-bottom: 60px;
  }

  .field-name-field-two-tvi-ref {
    & > .field-items {
      & > .field-item:nth-child(odd) {
        .textvsimage {
          flex-direction: row-reverse;
        }
      }
    }
    h3 {
      color: $blue;
    }
  }

  .field-name-field-text-vs-image-va {
    & > .field-items {
      .textvsimage {
        box-shadow: none;
        padding: 0;
        justify-content: center;
        background: none;
      }
    }
    h3 {
      color: $blue;
    }
  }

  .waves {
    margin: 0 0 60px;

    .introduction {
      margin-bottom: 45px;
      text-align: center;
      min-height: 160px;

      .item {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }

    .icons {
      position: relative;
      display: flex;
      justify-content: space-around;

      .icon-item {
        margin: 0;
        flex: 0 0 20%;

        .icon {
          cursor: pointer;
          margin: auto;
          background: $white;
          @include box-shadow-content();
          border-radius: 50%;
          width: 120px;
          height: 120px;
          padding: 15px;
          text-align: center;

          svg {
            width: 60px;
            height: 60px;
            margin: 15px 0;
          }
        }

        .icon-label {
          text-align: center;
          width: 100%;
          @include x-rem('font-size', 16px);
          line-height: 20px;
          margin: 15px 0 0;
          font-weight: 500;
        }

        &:first-child,
        &:last-child {
          margin-top: -20px;
        }
      }

      &:after {
        position: absolute;
        z-index: -1;
        top: -30px;
        content: '';
        width: 100%;
        display: block;
        height: 100px;
        border: solid 3px $grey_light;
        border-color: transparent transparent $grey_light transparent;
        border-bottom-left-radius: 65% 100%;
        border-bottom-right-radius: 65% 100%;
      }
    }

    &.water {
      .icon:hover, .icon.active {
        //box-shadow: 0 2px 5px 0 $blue_light;
        box-shadow: 0 2px 5px 0 #2968FE;
      }

      .icon-label {
        color: $blue;
      }

      svg {
        //fill: url(#icon-gradient) $blue_light;
        fill: #2968FE !important;
      }
    }

    &.coffee {
      .icon:hover, .icon.active {
        box-shadow: 0 2px 5px 0 $brown;
      }

      .icon-label {
        color: $brown;
      }

      svg {
        fill: url(#icon-gradient-brown) $brown;
      }
    }
  }

  .packages {
    margin-bottom: 80px;
    h2 {
      text-align: center;
      margin-bottom: 0;
    }
    .packages-subtitle {
    display: block;
    margin: auto;
    @include x-rem('font-size', 25px);
    color: $blue;
    font-weight: bold;
    text-align: center;
    line-height: 45px;
    }
    .field-name-field-three-pack-ref {
      margin-top: 40px;
      & > .field-items {
        display: flex;
        justify-content: center;

        & > .field-item {
          margin: 0 10px;
          flex: 0 0 33%;
        }
      }
    }
    article.node-wngh-package {
      @include box-shadow-content();
      @include x-rem('padding', 20px 15px 60px);
      text-align: center;
      position: relative;

      .field-name-field-max-three-textfields {
        font-size: 22px;
        text-align: left;

        .field-item {
          margin-bottom: 10px;
        }
      }

      .top-price {
        margin-bottom: 15px;

        .field-name-field-unique-text-limit-fifty {
          @include x-rem('font-size', 28px);
          //color: $orange;
          color: #2968FE;
          font-weight: bold;
          letter-spacing: 2px;
          line-height: 28px;
        }

        .field-name-field-unique-textfield {
          @include x-rem('font-size', 20px);
          line-height: 24px;
          color: $blue;
        }
      }

      .image {
        margin-bottom: 10px;

        img {
          display: inline-block;
        }
      }

      .infos {
        h3 {
          color: $blue;
          font-size: 24px;
          margin: 0 0 15px;
        }
      }

      .package-button a {
        position: absolute;
        bottom: -22px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background: $orange;
        background: $orange;
        display: inline-block;
        padding: 10px 35px;
        color: white;
        font-weight: 600;
        border-radius: 25px;
        width: 60%;
      }
    }
  }

  // Flexbox component
  .flexbox-component {
    padding: 30px 40px;

    h2 {
      text-align: center;
    }

    .field-name-field-flexbox-items {
      & > .field-items {
        display: flex;
        justify-content: space-around;

        & > .field-item {
          flex: 0 0 22%;
        }
      }
    }

    .flexbox-item {
      text-align: center;
      font-size: 16px;
      color: #252D5C;
      
      svg {
        height: 120px;
        width: 120px;
        //fill: $blue_light;
        fill: #2968FE !important;
      }

      .item-title {
        font-weight: 800;
        font-size: 19px;
      }
    }
  }

  .textvsimage-block {
    margin: 0 0 60px;
  }

  .textvsimageva-block{
    padding: 80px 0;
    background: $white_medium;
    margin-bottom: 20px;
  }

  .web-products {
    margin: 0 0 60px;

    h3 {
      margin: 30px 0 30px;
      text-align: center;
      padding: 0 10px;
      color: $blue;
    }

    // List of products
    .field-name-field-wgnv-bl-web-products {
      .slick-slider {
        padding: 0 40px;

        .slick-track {
          display: flex;
          align-items: stretch;
          justify-content: center;
          padding: 10px 5px;

          .slick-slide {
            height: auto;
            margin-right: 20px;

            & > div {
              height: 100%;

              & > div.field-item {
                height: 100%;
              }
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        .slick-arrow {
          position: absolute;
          top: calc(50% - 42px);
          transform: translateY(-50%);
          color: $blue;
          font-weight: bold;
          cursor: pointer;
          z-index: 3;

          &.slick-arrow-left {
            left: 0;
          }
          &.slick-arrow-right {
            right: 0;
          }

          &:hover {
            color: $blue_light;
          }
        }
      }
    }
  }
}

