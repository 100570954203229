.view-faq-wng {
  display: flex;
  flex-wrap: wrap;

  .view-filters {
    @include box-shadow-content();
    margin-right: 50px;
    padding: 15px 20px;
    flex: 0 0 200px;
    text-align: center;

    .views-exposed-form {
      .views-exposed-widget {
        padding: 0;
        float: none;
      }

      #edit-category-term-wrapper {
        label {
          color: $blue;
          @include x-rem('font-size', 20px);
          font-weight: bold;
          margin-bottom: 15px;
        }

        .form-item {
          padding: 0;

          &.form-item-category-term {

            a {
              display: inline-block;
              margin: 5px 0;
              color: $grey_transparent;

              &:hover,
              &.active {
                color: $blue_light;
              }
            }

            #edit-category-term-all {
              a {
                padding: 0;
                width: auto;
                height: auto;
                line-height: normal;

                &.active,
                &:hover {
                  color: $blue_light;
                  font-weight: bold;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .view-content {
    flex: 1;
    @include box-shadow-content();
    padding: 20px;

    h3 {
      font-weight: 700;
      @include x-rem('font-size', 28px);
      color: $blue;
      margin: 0 0 20px;
      padding: 0 20px 15px;
      border-bottom: 1px solid $blue;
    }
    
    .views-row {
      border-bottom: 1px solid $grey_line;

      & > span {
        display: block;
        padding: 15px 40px 15px 30px;
        position: relative;
        font-weight: 500;
        color: $grey_label;
        cursor: pointer;

        span {
          font-family: "Helvetica",helvetica,arial,sans-serif;
          font-weight: 400;
          position: absolute;
          top: 12px;
          right: 5px;
          color: #06A7E0;
          line-height: 30px;
          @include x-rem('font-size', 25px);
          transition: 0.5s linear transform;
        }

        &.active span {
          transform: rotate(135deg);
        }
      }

      div.answer {
        padding: 15px 40px 15px 30px;
        display: none;
      }

      &.views-row-last {
        border: 0;
        margin-bottom: 50px;
      }
    }
  }
}
