*:focus {
  outline: none;
}

.form-item {
  padding: 10px 0;
  margin: 0;
  position: relative;

  input.error,
  textarea.error,
  select.error {
    border: 1px solid $red;
  }

  &.required {
    &::before {
      content: '*';
      position: absolute;
      top: 12px;
      left: 2px;
      color: $red;
    }
  }
}

.btn-actions {
  margin: 20px 0 0;
  text-align: center;
}

input[type="password"],
input[type="text"],
input[type="email"] {
  border-radius: unset;
  @include x-rem('padding', 12px 20px);
  border: 1px solid $grey_value;
  color: $grey_value;
  width: 100%;
  position: relative;

  &:not(:placeholder-shown) {
    border-color: $blue_light;
    color: $blue_light;
    font-weight: 400;
    &.error{
      border-color:#E61F2B;
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: $blue_light!important;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $grey_value;
  }
}

textarea {
  resize: none;
  @include x-rem('padding', 12px 20px);
  border: 1px solid $grey_value;
  color: $grey_value;
  width: 100%;
  position: relative;

  &:not(:placeholder-shown) {
    border-color: $blue_light;
    color: $blue_light;
    font-weight: 400;
    &.error{
      border-color:#E61F2B;
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: $blue_light!important;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $grey_value;
  }
}

form {
  select {
    width: 100%;
    background-color: $white;
    border: 1px solid $grey_value;
    color: $grey_value;
    @include x-rem('padding', 12px 16px);
    @include x-rem('font-size', 16px);
    background-position: right 5px center;
    &.error {
      border-color:#E61F2B;
    }
  }
}

.btn,
input[type="submit"] {
  border-radius: unset;
  display: inline-block;
  //background: $blue;
  background: #000E2E;
  @include x-rem('padding', 12px 50px);
  @include x-rem('border-radius', 30px);
  @include x-rem('margin', 5px 10px);
  line-height: 1.15;
  color: white;
  border: none;
  font-weight: 500;
  @include x-rem('font-size', 13px);
  cursor: pointer;
  transition: all 0.5s ease-out;

  &:hover {
    //background: $blue_light;
    background: #2968FE;
    color: #fff;
  }

  &:disabled {
    background: $grey_disabled;
    cursor: default;
    color: $grey;
    opacity: 0.5;
  }
}

.btn-close {
  background: $grey_label;

  &:hover {
    background: $grey_value;
  }
}

// Managed file.
.form-type-managed-file {
  border: 1px solid $grey_line;
  padding: 15px 20px;
  text-align: center;

  label {
    display: block!important;
    font-weight: 500;
    @include x-rem('font-size', 15px);
    margin-bottom: 10px;
  }

  span.file {
    display: block;
    margin-right: 10px;

    a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }

  input[type="submit"] {
    border-radius: 0;
    @include x-rem('padding', 6px 25px);
    &[name="picture_upload_button"] {
      margin: 17px 0; // hack to have the same height on the fancybox.
      background: $yellow;
    }

    &[name="picture_remove_button"] {
      background: $red;
    }
  }
}

.form-required {
  color: $grey;
}

// Remove file icon.
.file-icon {
  display: none;
}

// PASSWORD
.confirm-parent, .password-parent {
  width: 100%;
}

.password-strength {
  width: auto;
  float: none;
  margin: 0;
}

div.password-confirm {
  margin: 0;
  text-align: right;
}

.webform-confirmation {
  text-align: center;
}

.content-webform {
  margin-top: 30px;
  border-top: 2px solid #f1f1f1;

  .content-container-webform {
    padding: 30px;
    width: 50%;
    margin: 30px auto 0;
    @include box-shadow-content();

    .webform-datepicker {
      display: flex;
      align-items: center;

      & > div {
        margin-right: 15px;
      }

      input.webform-calendar {
        height: 21px;
      }
    }

    .form-actions {
      text-align: center;
    }

    .links {
      text-align: center;

      a {
        color: $blue_light;
        font-weight: 500;
        @include x-rem('font-size', 14px);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
