// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {

  .main-banner-cro {
    position: relative;
    margin-bottom: 60px;

    .slide-item {
      & > .content {
        .container {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
        }
      }

      .field-name-field-wngh-banner-cro-image {
        img {
          display: block;
          position: relative;
          left: 50%;
          margin-left: -960px;
          border-bottom-left-radius: 65% 20%;
          border-bottom-right-radius: 65% 20%;
          max-width: none;
          height: 500px;
        }
      }

      .content {
        top: 70px;
        max-height: none;
        height: calc(100% - 70px);

        .banner-texts-center,
        .banner-texts-left {
          width: 95%;
          margin: 0 auto;

          .title {
            @include x-rem('font-size', 30px);
            line-height: 28px;
          }
        }

        .package-infos {
          padding: 10px;
          margin-top: 10px;
          justify-content: center;

          .package-img {
            flex: 0 0 25%;
            margin-right: -15px;
            min-width: 85px;
          }

          .package-content {
            .packages-desc {
              padding: 15px 15px 15px 20px;
              background-color: $white;
              @include x-rem('font-size', 14px);

              .field-name-field-wngh-banner-cro-off-title {
                @include x-rem('font-size', 16px);
                line-height: 16px;
                margin-bottom: 5px;
              }

              .field-name-field-wngh-banner-cro-offer-desc {
                @include x-rem('font-size', 14px);
                line-height: 14px;

                .field-item {
                  padding: 0;
                }
              }
            }

            .package-button {
              text-align: center;

              a {
                @include x-rem('font-size', 14px);
                padding: 8px 25px;
              }
            }
          }

        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: 580px) {
  .main-banner-cro {
    .slide-item {
      .content {
        .package-infos {
          .package-img {
            flex: 0 0 35%;
          }
        }
      }
    }
  }
}

// MOBILE < 320
@media only screen and (max-width: 320px) {
  .main-banner-cro {
    .slide-item {
      .content {
        .package-infos {
          padding: 5px;
          margin-top: 5px;

          .package-img {
            display: none;
          }
          .package-content {
            .packages-desc {
              padding: 10px;
              @include x-rem('font-size', 12px);
            }
          }
        }
      }
    }
  }
}
