.node-type-wngv-offers {
  max-width: 100%;
  width: 100%;

  .field-name-body {
    margin: 30px auto;

    p {
      @include x-rem('font-size', 16px);
    }
  }

  .field-name-field-wngv-offers-offer-ref {
    & > .field-items {
      display: flex;
      flex-wrap: wrap;

      & > .field-item {
        @include x-rem('margin-top', 15px);
        flex-shrink: 1; // Separate flex properties for IE11
        flex-grow: 0;
        flex-basis: calc(33.33% - 10px);
        max-width: calc(33.33% - 10px); // HACK IE11

        // All 3 elements starting from 2.
        &:nth-child(3n+2) {
          @include x-rem('margin-left', 15px);
          @include x-rem('margin-right', 15px);
        }

        // First thee elements.
        &:nth-child(-n+3) {
          margin-top: 0;
        }

        .field-name-field-wngh-offer-image {
          img {
            display: block;
            margin: auto;
          }
        }

        h2 {
          margin: 0;
          line-height: normal;

          a {
            display: inline-block;
            color: $blue;
            @include x-rem('font-size', 18px);
            line-height: 20px;
            letter-spacing: 2px;
            margin: 15px 0 20px;
            padding-bottom: 15px;
          }
        }

        div.more-details {
          margin-top: auto;

          a {
            margin: 0;
          }
        }
      }
    }
  }
}

.offer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  @include box-shadow-content();
  text-align: center;
  @include x-rem('padding', 20px);
}
