.main-banner {
  position: relative;
  margin-bottom: 60px;

  .slide-item {
    overflow: hidden;

    img {
      display: block;
      position: relative;
      left: 50%;
      margin-left: -960px;
      border-bottom-left-radius: 65% 20%;
      border-bottom-right-radius: 65% 20%;
      max-width: none;
      height: 500px;
    }

    .content {
      position: absolute;
      width: 100%;
      //top: 140px;
      top: 100px;
      z-index: 3;
      left: 0;
      right: 0;
      margin: auto;
      max-height: 75%;
      overflow: hidden;

      .banner-texts-left {
        h1 {
          text-align: left;
        }
      }

      .banner-texts-center,
      .banner-texts-left {
        width: 50%;

        .title {
          display: inline-block;
          width: 80%;
          font-weight: 800;
          color: $blue;
          @include x-rem("font-size", 41px);
          @include x-rem("letter-spacing", 2px);
          @include x-rem("line-height", 47px);
          padding-bottom: 15px;
        }

        .field-name-field-wngh-banner-description {
          width: 100%;
          color: $blue;
          @include x-rem("font-size", 18px);
          @include x-rem("line-height", 22px);
          font-weight: 400;
          margin: 0;
        }

        .cta-links {
          display: flex;
          justify-content: left;
          .cta-link {
            margin: 30px 10px 10px 0;
            text-decoration: none;
            padding: 10px;
            background: #ffa401;
            max-width: 300px;
            border-radius: 9999px;
            text-align: center;
            color: $white;
            //font-weight: bold;
            @include x-rem("font-size", 22px);
          }
        }
        &:hover {
          color: $white;
        }
      }

      .banner-texts-center {
        margin: 0 auto;
        text-align: center;

        .cta-links {
          justify-content: center;
          .cta-link {
            margin: 30px 10px 10px 10px;
          }
        }
      }
    }
  }

  .icons-container {
    height: 120px;
  }

  .icons {
    position: absolute;
    top: 435px;
    left: 0;
    right: 0;
    margin: auto;

    .field-items {
      display: flex;
      justify-content: space-around;

      .field-item {
        margin: 0;
        flex: 0 0 20%;

        .fc-feature {
          text-align: center;
          max-width: 150px;
          margin: auto;

          .icon {
            margin: auto;
            background: $white;
            @include box-shadow-content();
            border-radius: 50%;
            width: 120px;
            height: 120px;
            padding: 15px;

            svg {
              //background: $blue_light;
              width: 60px;
              height: 60px;
              margin: 15px 0;
              //fill: url(#icon-gradient) $blue_light;
              fill: #2968FE;
            }
          }

          .icon-label {
            color: $blue;
            margin: 15px 0 0;
            font-weight: 500;
          }
        }

        &:first-child,
        &:last-child {
          margin-top: -20px;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: 450px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    padding: 0;

    li {
      cursor: pointer;
      display: inline-block;
      text-indent: -9999px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $blue_light;
      margin: 0 5px;
      vertical-align: middle;
      padding: 0;

      &.slick-active {
        width: 12px;
        height: 12px;
      }
    }
  }
}

// ISRAEL
html[dir="rtl"] {
  .main-banner {
    .slide-item {
      img {
        left: auto;
        margin-left: 0;
        margin-right: -960px;
        right: 50%;
      }
    }
  }
}
