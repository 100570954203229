.node-type-wngv-main-product-category {
  .field-name-field-unique-text-vs-image {
    margin-bottom: 80px;
  }

  .presentation-image {
    position: relative;
    margin-bottom: 80px;

    .field-name-field-image-unique {
      img {
        position: relative;
        left: 50%;
        margin-left: -960px;
        display: block;
        max-width: none;
      }
    }

    .content {
      text-align: center;
      // Center vertically and horizontally.
      position: absolute;
      top: 0;
      height: 450px;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      max-width: 600px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h2 {
        margin: 0 0 30px;
        color: $white;
        @include x-rem('font-size', 40px);
        line-height: 46px;
        letter-spacing: 2px;
      }
    }
  }

  .bottom {

    .intro {
      text-align: center;

      h2 {
        margin: 0 0 30px;
      }
    }

    .field-name-field-illimited-text-vs-image {
      & > .field-items {
        & > .field-item:nth-child(odd) {
          .textvsimage {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
}

// ISRAEL
html[dir="rtl"] {
  .node-type-wngv-main-product-category {
    .presentation-image {
      .field-name-field-image-unique {
        img {
          left: auto;
          margin-left: 0;
          margin-right: -960px;
          right: 50%;
        }
      }
    }
  }
}
