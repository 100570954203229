.field-name-field-text-vs-image-va{
  .textvsimage {
    .field-name-field-wngh-tvi-image {
      flex: 0 0 52%;

      img {
        display: block;
        margin: auto;
      }
    }
    .content {
      //padding: 10px 30px;
      //flex-grow: 1;
      flex: 0 0 45%;
      margin: 0 !important;
      background: $blue;
      color: $white;
      padding: 50px 30px !important;
      overflow: hidden;
      z-index: 3;

      .field-name-field-wngh-tvi-description {
        margin-bottom: 40px;

        p{
          color: $white;
        }
      }

      h3 {
        color: $white;
        a {
          color: $blue;
          @include x-rem('font-size', 28px);
          line-height: 36px;
          letter-spacing: 2px;
          margin: 0;
          margin-bottom: 10px;
        }
      }

      a.btn-vs {
        @include x-rem('font-size', 14px);
        line-height: 19px;
        font-weight: 500;
        border-radius: 30px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background: $blue;
        transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
        border: 1px solid $white;
        padding: 12px 15px;

        span {
          display: inline-block;
        }

        svg {
          margin-left: 5px;
          width: 35px;
          height: 35px;
          fill: $white;
          transition: fill 0.5s ease;
        }

        &:hover {
          //background: $blue_light;
          color: $blue;
          background: $white;
        }

      }
    }
  }
}
.textvsimage {
  display: flex;
  justify-content: normal;
  margin-bottom: 20px;
  @include box-shadow-content();
  padding: 20px;
  align-items: center;
  background: $white;

  .field-name-field-wngh-tvi-image {
    flex: 0 0 485px;

    img {
      display: block;
      margin: auto;
    }
  }

  .content {
    padding: 10px 30px;
    flex-grow: 1;

    .field-name-field-wngh-tvi-description {
      margin-bottom: 40px;
    }

    h2 {
      a {
        color: $blue;
        @include x-rem('font-size', 28px);
        line-height: 36px;
        letter-spacing: 2px;
        margin: 0;
        margin-bottom: 10px;
      }
    }
    
    a.btn-vs {
      @include x-rem('font-size', 14px);
      line-height: 19px;
      font-weight: 500;
      border-radius: 30px;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 30px;
      color: $white;
      //background: $blue;
      background: #000E2E;
      transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;

      span {
        display: inline-block;
      }

      svg {
        margin-left: 5px;
        width: 35px;
        height: 35px;
        fill: $white;
        transition: fill 0.5s ease;
      }

      &:hover {
        //background: $blue_light;
        background: #000E2E;
      }

    }
  }
}

// Text VS Image (Page)
.node-type-wngv-tvip {
  .introduction {
    text-align: center;

    .field-name-field-wngv-tvip-subtitle {
      font-weight: 700;
      @include x-rem('font-size', 30px);
      @include x-rem('letter-spacing', 1.5px);
      @include x-rem('line-height', 32px);
      color: $blue;
      margin-bottom: 15px;
    }

    .field-name-field-wngv-tvip-sub-description {
      margin-bottom: 30px;

      &:after {
        content: '';
        margin: 30px auto 0;
        width: 2px;
        background: $blue_light;
        height: 60px;
        display: block;
      }
    }
  }

  .field-name-field-illimited-text-vs-image {
    & > .field-items {
      & > .field-item:nth-child(odd) {
        .textvsimage {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

// ISRAEL
html[dir="rtl"] {
  .textvsimage {
    .content {
      a.btn-vs {
        float: left;
      }
    }
  }
}
