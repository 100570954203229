// TABLET < 1200
@media only screen and (max-width: $size-medium-large-1){
  .textvsimage {
    .field-name-field-wngh-tvi-image {
      flex: 0 0 350px;
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .textvsimage {
    display: block;
    padding: 0;
    box-shadow: none;

    .content {
      @include box-shadow-content();
      margin: 0 10px;
      padding: 20px;
      overflow: hidden;
    }
  }
}
