// MOBILE < 490
@media only screen and (max-width: $size-medium-1) {
  .content-webform {
    .content-container-webform {
      width: 100%;

      .webform-datepicker {
        display: block;

        & > div {
          display: block;
          margin: auto;
          max-width: 100%;
        }
      }
    }
  }
}
