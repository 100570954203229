
// MOBILE < 768
@media only screen and (max-width: $size-medium-1){
  .page-user {
    .ess-user-login {
      margin-top: 30px;
      display: flex;
      flex-direction: column-reverse;

      & > div {
        &#user-login-text-block {
          margin-right: 0;
        }

        &#login-form {
          margin-bottom: 15px;

          form {
            width: 100%;
          }
        }
      }
      &.multi-login {
        & > .form-container {
          ul.tab {
            width: 100%;
          }

          .tab-phone {
            margin-bottom: 20px;
          }

          #edensprings-self-service-user-login-otp {
            .phone-container {
              display: flex;
              .form-item-phone-prefix {
                flex: 1 1 60%;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }

    .ss-user-register,
    .ss-user-pass,
    .ss-pass-reset,
    .ess-user-reset-password {
      margin-top: 30px;

      form {
        width: 100%;
      }

      &.multi-registration {
        ul.tab {
          //margin: 0 0 25px;
        }
      }
    }
  }


  .pre-register {
    .choice {
      flex-direction: column;
      align-items: center;

      a {
        width: 230px;
        flex: 0 0 auto;
        margin: 0 0 30px;
      }
    }
  }

  .not-allowed {
    margin-top: 0;

    .container {
      form {
        width: 100%;
      }
    }
  }

}

// MOBILE < 490
@media only screen and (max-width: $size-small-1){
  .page-user {

    .ess-user-login {

      #edensprings-self-service-user-login-otp {
        .phone-container {
          .form-item-phone-prefix {
            flex: 1 1 100%;
          }
        }
      }

      &.multi-login {
        & > .form-container {
          #edensprings-self-service-user-login-otp {
            .phone-container {
              .form-item-phone-prefix {
                flex: 1 1 100%;
              }
            }
          }
        }
      }
    }


    .ss-user-register {
      &.multi-registration {
        ul.tab {
          width: 100%;
        }
      }
    }
  }
}
