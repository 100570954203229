.page-digital-onboarding {
  @include x-rem('font-size', 14px);

  &.sticky {
    .page {
      padding-top: 0;
    }
  }

  .page {
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url("../images/digital-onboarding/BG-IMG.png");
  }

  &.page-digital-onboarding-1 {
    .page {
      background-image: url("../images/digital-onboarding/BG-IMG1.png");
    }
  }

  &.page-digital-onboarding-2 {
    .page {
      background-image: url("../images/digital-onboarding/BG-IMG2.png");
    }
  }

  &.page-digital-onboarding-3,
  &.page-digital-onboarding-4,
  &.page-digital-onboarding-5,
  &.page-digital-onboarding-6 {
    .page {
      background-image: url("../images/background-small.png");
    }
  }
  
  .ss-messages:not(.error-forms-custom) .messages.error {
    display: none;
  }


  #main-content {
    padding: 20px 0 0 0;

    > .container {
      position: relative;
    }

    h1#page-title {
      display: none;
    }

    .home-intro, .page-intro {
      @include x-rem('width', 480px);
      margin: 0 auto;
      text-align: center;

      h1 {
        line-height: 2.5rem;
        font-size: 2rem;

        + p {
          font-weight: 500;
        }
      }
    }

    .page-intro {
      @include x-rem('width', 550px);

      h1 {
        line-height: 2.2rem;
        @include x-rem('font-size', 19px);
      }

      p {
        display: none;
      }
    }

    .breadcrumb {
      ul {
        display: flex;
        justify-content: center;
        margin: 15px 0 15px 0;
        padding: 0;
        text-align: center;

        > li {
          position: relative;
          margin: 0 5px 15px 5px;

          &:nth-child(1), &:nth-child(3), &:nth-child(5) {
            @include x-rem('width', 110px);

            i {
              color: $grey_disabled;
            }

            h2 {
              margin: 0;
              line-height: 1.2;
              @include x-rem('font-size',15px);
              font-weight: 700;
              color: $grey_disabled;
            }

            p {
              display: none;
              margin-bottom: 0;
            }

            &.default {
              @include x-rem('width', 180px);
              @include x-rem('padding', 15px);
              box-shadow: 0 2px 5px #d7d3d3;
              background-color: #ffffff;

              i {
                color: $blue_light;
              }

              h2 {
                @include x-rem('font-size',18px);
                color: $blue_light;
                line-height: 1.2;
              }

              p {
                display: block;
                @include x-rem('font-size',13.5px);
              }
            }

            &.active {
              padding: 0;

              i {
                color: $blue_light;
              }

              h2 {
                color: $blue_light;
              }
            }
          }

          &:nth-child(2), &:nth-child(4) {
            @include x-rem('width', 100px);

            span {
              display: block;
              position: absolute;
              top: 30%;
              left: 0;
              right: 0;
              text-indent: -9999px;
              line-height: 0;
              border-bottom: 2px solid $grey_disabled;
            }

            &.default {
              span {
                top: 49%;
                border-bottom: 1px solid $blue_light;
              }
            }

            &.active {
              span {
                border-bottom: 2px solid $blue_light;
              }
            }
          }
        }
      }
    }

    .error-forms-custom {
      @include x-rem('width', 520px);
      margin: 0 auto;
    }

    .messages.error {
      text-align: left;
    }

    #form-step {
      form {
        margin: 20px auto 80px auto;
        text-align: center;

        label .form-required {
          display: none;
        }

        h2 {
          width: 45%;
          margin: 0 auto 20px auto;
          @include x-rem('font-size', 33px);
          line-height: 2.5rem;
          color: $blue;

          + p {
            display: block;
            width: 50%;
            margin: 0 auto;
            font-weight: 500;

            + img {
              margin: 50px auto 20px auto;

              + p {
                margin: 0 auto;
                @include x-rem('width', 300px);
                @include x-rem('font-size', 20px);
                font-weight: 700;
                color: $blue;
              }
            }

            + span.icon {
              display: block;
              margin: 0 auto 10px auto;
              @include x-rem('width', 50px);
              @include x-rem('height', 50px);
              padding: 17px 0;
              border-radius: 50%;
              border: 2px solid #06A7E0;
              background-color: #ffffff;
              font-size: 0.5rem;
              color: #06A7E0;
              font-weight: 700;
              text-align: center;

              img {
                @include x-rem('width', 30px);
                @include x-rem('height', 30px);
                margin: -10px 0 0 0;
              }
            }
          }
        }

        .form-item {
          padding: 5px 0;

          &.form-item-buying-zip-code {
            padding: 5px 0 25px 0;
          }
        }

        input {
          padding: .6rem 1.25rem;
        }

        input[type="password"]:not(:placeholder-shown),
        input[type="text"]:not(:placeholder-shown) {
          font-weight: 500;
        }

        select {
          padding: .6rem 1.25rem;
          border: 1px solid $blue_light;
          background-color: #ffffff;
          background-position: right 10px center;
          color: $blue_light;
          @include x-rem('font-size', 13px);
          width: 100%;
          position: relative;
          line-height: 15px;
        }

        &#edensprings-digital-onboarding-form-step4-0,
        &#edensprings-digital-onboarding-form-step4-1,
        &#edensprings-digital-onboarding-form-step5-0 {
          h3 {
            text-align: center;
            font-size: 1.3rem;
            color: $blue;
          }
        }

        span {
          display: block;
          margin: 15px 0;
          font-size: 0.8rem;
          font-weight: 500;

          > a {
            color: $blue_light;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .terms-conditions {
          position: relative;
          @include x-rem('width', 380px);
          @include x-rem('height', 100px);
          margin: 10px auto 0 auto;
          padding: 15px 10px;
          overflow-y: scroll;
          background-color: #eee;
          text-align: left;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #ffffff;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin: 0 auto;
          }

          p {
            @include x-rem('font-size', 9px);
            font-weight: 500;
          }

          a {
            color: $blue_light;

            &:hover {
              text-decoration: underline;
            }
          }

          + .form-item-terms-conditions {
            position: relative;
            @include x-rem('width',380px);
            margin: 10px auto 0 auto;
            text-align: left;

            input {
              float: left;
            }

            .form-required {
              display: none;
            }

            label {
              display: block;
              padding-left: 18px;
              line-height: 12px;
              float: none;
              @include x-rem('font-size', 9px);
            }
          }
        }

        .wrapper-col {
          display: flex;
          justify-content: center;
          margin: 30px 0 20px 0;
          padding: 0;
          text-align: center;

          .col {
            display: inline-block;
            width: 50%;
            padding: 0 6%;

            + .col {
              display: inline-block;
            }
          }
        }

        &.invoice {
          h2 {
            width: 45%;
          }

          .link-address {
            display: none;
          }
        }

        .form-item-buying-nb-employees {
          padding: 12px 0;

          label {
            display: inline-block;
            margin: 0 18% 0 0;
            font-weight: normal;
            font-size: 0.9rem;

            span:not(.form-required) {
              display: inline;
            }
          }

          .form-radios {
            display: inline-block;
            margin: 0;

            div {
              display: inline-block;
              padding: 0;
            }

            input[type="radio"] {
              display: inline-block;
              margin: -3px 0 0 0;

              + label {
                display: inline-block;
                width: auto;
                height: auto;
                margin: 0;
                padding: 0;
                border: none;
                box-shadow: none;
                background: none;

                &:hover {
                  -webkit-transform: scale(1);
                  transform: scale(1);
                }
              }

              &:checked,
              &:not(:checked) {
                position: absolute;
                left: -9999px;

                + label {
                  position: relative;
                  margin: 5px 0;
                  padding: 0 30px 0 18px;
                  cursor: pointer;
                  line-height: 20px;
                  display: inline-block;
                  -webkit-transform: scale(1);
                  transform: scale(1);
                  color: #252D5C;
                  border: none;

                  &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 4px;
                    @include x-rem('width', 13px);
                    @include x-rem('height', 13px);
                    border: 1px solid #252D5C;
                    border-radius: 100%;
                  }

                  &:after {
                    display: block;
                    content: '';
                    @include x-rem('width', 9px);
                    @include x-rem('height', 9px);
                    background: $blue_light;
                    position: absolute;
                    top: 6px;
                    left: 2px;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                  }
                }
              }

              &:not(:checked) + label:after {
                display: block;
                opacity: 0;
                -webkit-transform: scale(1);
                transform: scale(1);
              }
              &:checked + label:after {
                display: block;
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }
          }
        }

        &.invoice {
          width: auto;

          .form-item-buying-city,
          .form-item-buying-zip-code {
            display: inline-block;
            width: 50%;
            float: left;
          }

          .form-item-buying-city {
            padding: 5px 5px 5px 0;

            + .form-item-buying-zip-code {
              padding: 5px 0 5px 5px;

              + div {
                clear: both;
              }
            }
          }
        }

        &#edensprings-digital-onboarding-form-step5-0 {
          .wrapper-col {
            display: flex;
            justify-content: center;
            margin: 30px 0 20px 0;
            padding: 0;
            text-align: center;

            .col {
              display: block;
              width: 100%;
              padding: 0;

              + .col {
                display: none;
              }
            }
          }

          &.invoice {
            .col {
              display: inline-block;
              width: 50%;
              padding: 0 6%;

              + .col {
                display: inline-block;
                width: 50%;
                padding: 0 6%;
              }
            }
          }
        }

        &#edensprings-digital-onboarding-form-step5-1 {
          .wrapper-col {
            + .wrapper-col {
              display: none;
            }
          }

          &.invoice {
            .wrapper-col {
              display: flex;
            }
          }

          h3 {
            text-align: left;
            font-size: 1.3rem;
            color: $blue;
          }
        }

        .form-type-textfield {
          label {
            margin: 10px 0 10px 0;
            font-size: 0.85rem;
          }
        }

        .form-item {
          &.form-item-kind-of-water,
          &.form-item-plan,
          &.form-item-buying {
            display: none;
          }
        }

        .wrapper-list {
          > ul {
            display: flex;
            justify-content: center;
            margin: 20px 0 0 0;
            padding: 0;
            text-align: center;

            > li {
              display: inline-block;
              position: relative;
              @include x-rem('width', 230px);
              min-height: 150px;
              text-align: center;
              margin: 0 15px 25px 15px;
              padding: 15px 25px;
              cursor: pointer;
              background-color: #ffffff;
              box-shadow: 0 2px 5px #d7d3d3;
              font-size: 0.9rem;
              color: $grey_value;
              border: 3px solid #ffffff;
              transition: all 0.2s ease-in-out;

              span {
                display: block;
                margin-bottom: 10px;
                font-size: 1.2rem;
                font-weight: 100;
                color: $blue;

                &.title-pack {
                  margin-top: 0;
                  font-weight: 500;
                  border-top: 2px solid $blue;
                  border-bottom: 2px solid $blue;
                }

                + span {
                  font-size: 0.9rem;
                  font-weight: 500;
                  color: $grey_label;

                  + span {
                    display: inline-block;
                    font-size: 0.9rem;
                    font-weight: 500;
                    color: $grey_value;
                  }
                }
              }

              &.edit-kind-of-water-0,
              &.edit-kind-of-water-1,
              &.edit-buying-0,
              &.edit-buying-1 {
                display: flex;
                padding: 50px 30px;
                flex: 0 0 230px;
                align-items: center;
                text-align: center;
                justify-content: center;
                min-height: 230px;

                img {
                  position: absolute;
                  bottom: 0;
                  right: -30px;
                  transition: all 0.2s ease-in-out;

                  &.default {
                    position: static;
                    display: block;
                    @include x-rem('width', 80px);
                    @include x-rem('height', 80px);
                    margin: 0 auto 10px auto;

                    + img {
                      display: none;
                    }
                  }

                  + span {
                    display: block;
                    cursor: pointer;
                    @include x-rem('font-size', 15px);
                    color: $grey_value;
                    font-weight: 500;
                  }
                }

                &:hover, &.active {
                  img {

                    &:not(.default) {
                      transform: scale(1.1);
                    }

                    &.default {
                      display: none;

                      + img {
                        display: block;
                      }
                    }

                    + span {
                      color: $blue_light;
                      font-weight: 700;
                    }
                  }
                }
              }

              &.edit-plan-0,
              &.edit-plan-1,
              &.edit-plan-2 {
                &:hover, &.active {
                  transform: scale(1.1);
                  transform-origin: top center;
                }
              }

              &.edit-kind-of-water-0 {
                img {
                  position: absolute;
                  bottom: 0;
                  left: -30px;
                }
              }

              > ul {
                display: block;
                padding: 5% 18%;
                text-align: left;

                > li {
                  display: block;
                  font-size: 0.8rem;

                  i {
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 0.6rem;
                    color: $green;
                  }
                }
              }

              &:hover, &.active {
                span {
                  font-weight: 500;
                }
              }

              &.active {
                border: 3px solid $blue_light;
              }
            }
          }
        }

        .form-radios, .wrapper-list {
          display: flex;
          justify-content: center;
          margin: 30px 0 0;
          padding: 0;

          div {
            display: block;
            margin: 0;
            padding: 0;
          }
        }

        input.form-submit {
          margin: 20px 0;
          padding: 15px 55px;
          font-size: .8rem;
        }

        &#edensprings-digital-onboarding-form-homepage {
          input.form-submit {
            background: $orange_cta;
          }
        }

        &#edensprings-digital-onboarding-form-step4-0,
        &#edensprings-digital-onboarding-form-step4-1,
        &#edensprings-digital-onboarding-form-step5-0,
        &#edensprings-digital-onboarding-form-step6 {

          &:not(.invoice) {

            &:not(.container-bs) {
              @include x-rem('width', 370px);
            }

            h2, p {
              width: 100%;
            }

            &.container-bs {
              max-width: 100%;
              @include x-rem('width', 700px);
              padding: 8% 5%;

              h2 {
                margin: 0 auto 60px auto;
                @include x-rem('font-size', 30px);
              }
            }
          }
        }
      }
    }

    #edensprings-digital-onboarding-form-return {
      #edit-submit {
        padding: 0;
        font-size: 0.9rem;
        background: none;
        border-radius: 0;
        color: $grey_value;
      }
    }

    #block-selection {
      display: none;
      position: absolute;
      @include x-rem('width', 180px);
      bottom: 110px;
      right: 0;
      z-index: 10;
      text-align: center;
      padding: 10px 20px;
      background-color: $blue_medium;
      color: $blue_light;

      &.show {
        display: block;
      }

      > span {
        font-weight: 500;
        @include x-rem('font-size', 17px);
        color: $blue;
      }

      ul {
        padding: 0;

        li {
          display: none;
          margin: 10px 0;
          font-size: 0.8rem;

          .icon {
            display: block;
            margin: 0 auto 5px auto;
            @include x-rem('width', 50px);
            @include x-rem('height', 50px);
            padding: 17px 0;
            border-radius: 50%;
            border: 2px solid $blue_light;
            font-size: 0.5rem;
            color: $blue_light;
            font-weight: 700;
            text-align: center;
          }

          img {
            @include x-rem('width', 30px);
            @include x-rem('height', 30px);
            margin: -10px 0 0 0;
          }

          &.active {
            display: block;
          }

          &:after {
            content: "";
            display: block;
            @include x-rem('width', 50px);
            height: 1px;
            margin: 5px auto 0 auto;
            background-color: $blue_light;
          }
        }
      }
    }
  }
}
