// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  body.node-type-wngv-homepage {
    ///--- TEXT BLOCK ---///
    article.node-text-block {
      div.node-text-block {
        padding:0 20px;
      }
    }
    ///-- END TEXT BLOCK ---//

    .square-blocks {
      .field-name-field-wngv-home-square-blocks {
        & > .field-items {
          & > .field-item {
            .square-block {
              .inner {
                padding: 10px;

                h2 {
                  @include x-rem('font-size', 30px);
                  line-height: 30px;
                }

                a.btn {
                  padding: 10px 20px;
                }
              }
            }
          }
        }
      }
    }

    .about {
      .circle-cards {
        .field-name-field-wngh-home-about-us {
          & > .field-items {
            & > .field-item {
              a.card-link {
                .circle-card {
                  max-width: 215px;

                  .content {
                    width: 215px;
                    height: 215px;

                    .field-name-field-wngh-cta-desc {
                      @include x-rem('font-size', 14px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  body.node-type-wngv-homepage {

    .content-title {
      h1 {
        @include x-rem('font-size', 30px);
        line-height: 28px;
      }
    }
    .square-blocks {
      padding: 30px 0;

      .field-name-field-wngv-home-square-blocks {
        & > .field-items {
          display: block;

          & > .field-item {
            .square-block {
              margin: 0 auto 30px;

              .inner {
                a.btn {
                  padding: 10px 20px;
                }
              }
            }
          }
        }
      }
    }

  // Flexbox component
  .flexbox-component {   

    .field-name-field-flexbox-items {
      .field-items {
        display: flex;        
        flex-direction: column;
      }
    }
  }

    .packages {
      .field-name-field-three-pack-ref {
        & > .field-items {
          display: block;

          & > .field-item {
            margin-bottom: 60px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .textvsimage-block {
      border-radius: 0;
      padding: 40px 0;

      .textvsimage {
        display: block;

        .content {
          padding: 30px 15px;
          margin: 0;
        }

        .field-name-field-wngh-tvi-image {
          img {
            margin: auto;
          }
        }
      }

    }

    .about {
      .circle-cards {
        .field-name-field-wngh-home-about-us {
          & > .field-items {
            display: block;

            & > .field-item {
              a.card-link {
                .circle-card {
                  margin-bottom: 50px;
                  max-width: 250px;

                  .content {
                    width: 250px;
                    height: 250px;

                    .field-name-field-wngh-cta-desc {
                      @include x-rem('font-size', 16px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .page {
    padding-bottom: 30px;
  }
}

