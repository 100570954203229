// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .springs-map {
    .field-name-field-wngv-springs-spring-ref {
      & > .field-items {
        display: block;

        & > .field-item {
          flex: none;
          width: auto;
          margin: 0 0 15px;
        }
      }
    }
  }
}
