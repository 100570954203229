.page-digital-onboarding {

  .region-header-top {
    position: relative;
    border: none;
    box-shadow: 0 5px 5px -5px rgb(215, 211, 211);
  }

  #block-edensprings-digital-onboarding-do-logo {
    @include x-rem('width', 400px);
    margin: 0 auto;
    padding: 15px 0 20px;
    text-align: center;

    h1 {
      margin: 0;
      padding: 0;

      a, img {
        display: block;
      }
    }
  }
}
