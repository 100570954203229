div.form-item div.password-suggestions {
  width: auto;
}

.page-user,
.page-help {

  .ess-user-login {
    display: flex;
    justify-content: space-between;
    margin: 60px 0 0;

    & > div {
      //flex: 1 1 0;
      flex: 0 1 50%;
      margin: 0 auto;
      @include box-shadow-content();
      background: $white;
      text-align: center;

      h2 {
        margin: 5px 0 20px;
      }

      &#user-login-text-block {
        margin-right: 20px;

        .ss_login_intro {
          p {
            font-weight: 500;
          }
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            position: relative;
            font-weight: bold;
            margin-bottom: 30px;

            span {
              display: block;
              height: 45px;
              line-height: 68px;
              font-weight: bold;

              &::before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 16.5px;
                margin: auto;
                border: 2px solid $blue_light;
                width: 35px;
                height: 35px;
                border-top-left-radius: 0;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                border-bottom-left-radius: 50%;
                transform: rotate(45deg);
              }
            }
          }
        }
      }

      &#login-form,
      &#login-otp-form {
        form {
          width: 75%;
          margin: auto;

          .form-actions {
            margin: 0;
          }
        }

        ul.quick-links {
          li {
            margin: 0;

            &.first {
              a {
                @include x-rem('font-size', 12px);
                font-style: italic;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            &.last {
              border-top: 1px solid #f1f1f1;
              margin-top: 15px;
              padding-top: 10px;

              a {
                display: inline-block;
                margin-top: 15px;
                padding: 8px 25px;
                border-radius: 25px;
                @include x-rem('font-size', 14px);
                text-transform: none;
                background-color: $blue_light;
                color: $white;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    #edensprings-self-service-user-login-otp {
      .phone-container {
        display: flex;
        .form-item-phone-prefix {
          flex: 1 1 60%;
          margin-right: 15px;
        }
      }
    }
    &.multi-login {
      & > .form-container {
        ul.tab {
          //margin: 0 130px 30px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          margin: 25px auto;
          width: 50%;

          li {
            flex: 0 0 49%;
            margin: 0;
            padding: 10px 5px 12px;
            display: inline-block;
            position: relative;
            text-align: center;
            cursor: pointer;
            border-radius: 30px;
            background-color: rgba(37, 45, 92, 0.25);
            color: $white;
            font-weight: 500;
            letter-spacing: 1.3px;
            transition: all 0.5s ease-out;

            i, span {
              vertical-align: middle;
            }

            span {
              margin-left: 5px;
            }

            &.active {
              background-color: $blue;
              cursor: auto;

              &:after {
                content: '';
                width: 0;
                height: 0;
                bottom: -8px;
                position: absolute;
                left: calc(50% - 5px);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid $blue;
              }
            }

            &:hover {
              background-color: $blue;
            }
            &:first-child {
              margin-gulp: 2%;
            }
          }
        }

        form {
          display: none;
          width: 75%;
          margin: auto;
          .form-actions {
            margin: 0;
          }

          &.active {
            display: block;
          }
        }
        ul.quick-links {
          li {
            margin: 0;

            &.first {
              a {
                @include x-rem('font-size', 12px);
                font-style: italic;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            &.last {
              border-top: 1px solid #f1f1f1;
              margin-top: 15px;
              padding-top: 10px;

              a {
                display: inline-block;
                margin-top: 15px;
                padding: 8px 25px;
                border-radius: 25px;
                @include x-rem('font-size', 14px);
                text-transform: none;
                background-color: $blue_light;
                color: $white;
                text-decoration: none;
              }
            }
          }
        }

        #edensprings-self-service-user-login-otp {
          .phone-container {
            display: flex;
            .form-item-phone-prefix {
              flex: 1 1 60%;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  .ss-user-register {
    margin-top: 60px;

    h2 {
      text-align: center;
      margin: 10px 0 20px;
    }

    form {
      text-align: center;
      width: 50%;
      margin: auto;
      padding-bottom: 30px;

      .form-item {
        padding: 3px 0;

        label {
          display: none;
        }

        div.description {
          text-align: left;
          background: url(../images/help.png) no-repeat left center;
          padding-left: 20px;
          margin: 3px 0 0;
          color: #06A7E0;

          &.password-suggestions {
            width: auto;
            padding: 5px 10px 5px 20px;
            border: 0;
            @include x-rem('font-size', 14px);
          }
        }

        .password-strength {
          text-align: right;

          .password-strength-title {
            margin-right: 5px;
          }
        }

        .password-confirm {
          width: 100%;
        }
      }

      .term_cond {
        text-align: center;
        margin: 25px 0 15px;
        font-weight: 500;
      }

      .term-cond-container {
        position: relative;
        padding: 30px 10px;
        background: $blue_medium;
        margin-bottom: 30px;

        .terms_checkbox_descr {
          height: 90px;
          overflow: auto;
          text-align: left;

          p {
            @include x-rem('font-size', 12px);
          }
        }

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid $white;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      .captcha {
        margin-bottom: 30px;

        .text-xs-center {
          text-align: center;
        }

        .g-recaptcha {
          display: inline-block;
        }
      }

      input[type="submit"] {
        @include x-rem('font-size', 14px);
        padding: 14px 50px;
        font-weight: 500;
      }
    }
    &.multi-registration {
      ul.tab {
        //margin: 0 130px 30px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin: 25px auto;
        width: 50%;

        li {
          flex: 0 0 49%;
          margin: 0;
          padding: 10px 5px 12px;
          display: inline-block;
          position: relative;
          text-align: center;
          cursor: pointer;
          border-radius: 30px;
          background-color: rgba(37, 45, 92, 0.25);
          color: $white;
          font-weight: 500;
          letter-spacing: 1.3px;
          transition: all 0.5s ease-out;

          i, span {
            vertical-align: middle;
          }

          span {
            margin-left: 5px;
          }

          &.active {
            background-color: $blue;
            cursor: auto;

            &:after {
              content: '';
              width: 0;
              height: 0;
              bottom: -8px;
              position: absolute;
              left: calc(50% - 5px);
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid $blue;
            }
          }

          &:hover {
            background-color: $blue;
          }
          &:first-child {
            margin-right: 2%;
          }
        }
      }
      form {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }

  .ss-user-pass {
    margin-top: 60px;

    form {
      width: 50%;
      margin: auto;
      text-align: center;

      label {
        margin-bottom: 5px;
      }
    }

    .up-message {
      @include x-rem('font-size', 14px);
    }
  }

  .ss-pass-reset {
    margin-top: 60px;

    form {
      width: 50%;
      margin: auto;
      text-align: center;

      .password-strength {
        text-align: right;

        .password-strength-title {
          margin-right: 5px;
        }
      }

      .password-confirm {
        width: 100%;
      }

      div.description {
        @include x-rem('font-size', 14px);
        color: #06A7E0;
      }
    }

    fieldset {
      border: 0;
      padding: 0;
      margin: 0;

      .fieldset-legend {
        @include x-rem('font-size', 18px);
        text-transform: uppercase;
        font-weight: 500;
        color: $blue;
      }
    }

    .term_cond {
      text-align: center;
      margin: 25px 0 15px;
      font-weight: 500;
    }

    .term-cond-container {
      position: relative;
      padding: 30px 10px;
      background: $blue_medium;
      margin-bottom: 30px;

      .terms_checkbox_descr {
        height: 90px;
        overflow: auto;
        text-align: left;

        p {
          @include x-rem('font-size', 12px);
        }
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .ess-user-reset-password {
    margin-top: 60px;

    form {
      width: 50%;
      margin: auto;
      text-align: center;

      .password-strength {
        text-align: right;

        .password-strength-title {
          margin-right: 5px;
        }
      }

      .password-confirm {
        width: 100%;
      }

      div.description {
        @include x-rem('font-size', 14px);
        color: #06A7E0;
      }
    }
  }
}

.pre-register {
  margin-top: 60px;

  h1 {
    text-align: center;
  }

  p {
    @include x-rem('font-size', 18px);
  }

  .choice {
    margin: 30px 0;
    display: flex;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 30px;
      padding: 50px 30px;
      flex: 0 0 230px;
      min-height: 230px;
      @include box-shadow-content();
      text-align: center;
      border: 3px solid #fff;

       i {
         display: block;
         margin: auto;
       }

      .text {
        font-weight: 500;
      }

      &:hover {
        border: 3px solid $blue_light;
      }
    }
  }
}

.not-allowed {
  margin-top: 60px;

  .container {
    padding-top: 30px;
    padding-bottom: 30px;

    h1 {
      text-align: center;
      margin-top: 30px;
    }

    p {
      @include x-rem('font-size', 18px);
    }

    .call-center-phone {
      color: $blue;
      vertical-align: middle;
      font-weight: bold;
      text-align: center;
      margin-top: 30px;

      i {
        margin-right: 15px;
      }

      div.phone-number {
        display: inline-block;
        vertical-align: middle;

        a {
          color: $blue;
          font-weight: bold;
          @include x-rem('font-size', 24px);
          line-height: 24px;
          letter-spacing: 2px;

          &:hover {
            color: $blue_light;
          }
        }
      }
    }

    form {
      width: 40%;
      margin: auto;

      .form-item {
        padding: 3px 0;
      }

      // Files (CV)
      .webform-component-file {
        margin: 3px 0;
        padding: 15px;
        text-align: center;
        background: $white;

        .form-managed-file {
          margin: 15px 0;

          span.file {
            margin-right: 10px;
            display: inline-block;
          }

          input[type="submit"] {
            background: $red;
            padding: 6px 25px;
          }
        }
      }

      .webform-component--rgpd {
        height: 78px;
        overflow: hidden;
        padding-bottom: 5px;
        margin: 10px 0;

        a {
          color: $blue;
          font-weight: 500;
        }
      }

      .webform-component-checkboxes {
        padding-top: 5px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        .form-item {
          padding: 0;
          margin: 15px 0 0;
        }

        input[type="checkbox"] {
          position: absolute;
          left: -9999px;

          & + label {
            cursor: pointer;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 16px;
              height: 16px;
              border: 2px solid $blue;
            }
          }

          &:checked {
            & + label {
              &:before {
                border: 2px solid $blue;
              }

              &:after {
                content: '';
                position: absolute;
                top: 4px;
                left: 4px;
                display: block;
                width: 8px;
                height: 8px;
                background: $blue;
              }
            }
          }
        }

        label {
          padding-left: 30px;
          display: inline-block;
          @include x-rem('font-size', 12px);
          line-height: 14px;
          font-weight: 500;
          color: $grey;
          vertical-align: top;
        }
      }

      .form-actions {
        text-align: center;
      }

      .simplebar-track.horizontal {
        display: none;
      }

      .simplebar-track.vertical {
        background: rgba(0, 0, 0, 0.2);

        .simplebar-scrollbar {
          &:before {
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }

      .simplebar-content {
        .simplebar-container {
          p {
            color: $grey;
          }
        }
      }
    }

    .links {
      text-align: center;

      a {
        color: $blue_light;
        font-weight: 500;
        @include x-rem('font-size', 14px);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// User login for no MyEden pages
.login-container {
  max-width: 1200px;
  margin: auto;
  @include box-shadow-content();
  background: $white;

  #user-login {
    padding: 30px;
    width: 40%;
    margin: 50px auto 0;
    text-align: center;

    .login-forgot {
      a {
        @include x-rem('font-size', 12px);
        font-style: italic;
      }
    }
  }
}

