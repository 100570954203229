p {
  a {
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cke_editable {
  padding: 0 30px;
}

.cke_editable,
.field {
  ul {
    padding: 0;
    display: inline-block;

    li {
      list-style: none;
      position: relative;
      padding-left: 20px;

      &:before {
        content: '○';
        margin-left: 5px;
        color: $blue_light;
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }

  ol {
    padding-left: 20px;

    li {
      list-style: decimal;
      color: $blue_light;

      &:first-line {
        color: $grey;
      }
    }
  }

  blockquote {
    border-left: 3px solid $blue;
    margin: 0;
    margin-left: 15px;
    padding: 0 0 0 15px;
    font-style: italic;
  }

  a {
    &.btn {
      text-decoration: none;
      margin: 0;

      &.btn-orange {
        background: $orange;

        &:hover {
          background: $yellow;
        }
      }
    }
  }
}

.others-blog-below-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);
  -webkit-box-shadow: 0 2px 5px 0 rgba(69,73,91,0.16);
  box-shadow: 0 2px 5px 0 rgba(69,73,91,0.16);
  padding: 30px 20px 50px;
  position: relative;
  margin: 40px auto;
  max-width: 1200px;
}

.others-career-below-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);
  -webkit-box-shadow: 0 2px 5px 0 rgba(69,73,91,0.16);
  box-shadow: 0 2px 5px 0 rgba(69,73,91,0.16);
  padding: 30px 20px 50px;
  position: relative;
  margin: 40px auto;
  width: 70%;
}
