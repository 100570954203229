@media only screen and (max-width: $size-medium-1){
  #block-edensprings-website-ng-blocks-website-ng-v3-cta {
    // position: static;
    position: fixed;
    animation: none;
    top: initial;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    /* background-color: RED; */

    .content {
      width: 100%;
    .floating-buttons {
      display: flex;
      justify-content: center;
      flex-direction: row;
      box-shadow: 0 8px 20px -6px rgba(0,0,0,0.56);

      & > div {
        flex: 0 0 50%;

        a {
          @include x-rem('font-size', 14px);
          line-height: 14px;
          border-radius: 0;
          min-width: 100%;
          height: 100%;
          float: none;
          text-transform: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 5px;

          br {
            display: none;
          }
          
          &:hover {
            width: auto;
          }
        }
        
        &:first-child {
          a {
            margin: 0;
          }
        }
      }
      &.onlyone {
        & > .btn-offer {
          flex: 1 1 85%;
        }
      }
      .contact-icon {
        flex: 1 0 auto;
        background-color: $orange_cta;
        a {
          color: $white;
          padding: 5px 15px;
          margin: 0;
          i.material-icons {
            padding: 2px;
            display: block;
            &.no-show {
              display: none;
            }
          }
        }
      }
      .contact-elts {
        display: none;
        position: fixed;
        right: 0;
        bottom: 44px;
        background-color: $orange_cta;
        .contact-list {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;
          // SPECIAL CASE FRANCE
          &.domain-fr {
            display: inline-block;
            border: 1px solid #dddddd; //grey;
            vertical-align: top;
            font-family: "Arial Bold", "Arial", serif;
            background-color: #78b41e; //$purple_phone;
            position: relative;
            margin: 0 auto;

            .first-contact-elt {
              padding: 0;

              span {
                &.digit {
                  background-color: $white;
                  display: block;
                  //box-shadow: inset 0 2px 10px -4px rgba(0, 0, 0, 0.75);

                  a.phone-link {
                    color: #78b41e; //$purple_phone;
                    padding: 2px 5px;
                    @include x-rem("font-size", 16px);
                    text-align: center;
                    font-weight: bold;
                    line-height: 25px;
                    height: 25px;
                    text-decoration: none;
                  }
                }

                &.phone-price {
                  vertical-align: middle;
                  @include x-rem("font-size", 12px);
                  display: block;
                  height: 11px;
                  line-height: 11px;
                  color: $white;
                  font-weight: bold;
                  padding-left: 15px;
                  text-transform: initial;
                  text-align: left;
                  margin: 3px 0 0;

                  &.border {
                    //box-shadow: inset 0 -7px 20px -8px rgba(0, 0, 0, 0.75);
                    margin: 0;
                    height: 14px;
                    line-height: 14px;
                    padding-bottom: 3px;
                  }
                }

                &.triangle {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 5px 5px 0 5px;
                  border-color: $white transparent transparent transparent;
                  position: absolute;
                  top: 25px;
                  left: 2px;
                }
              }
            }
          }
          .first-contact-elt,
          .second-contact-elt {
            text-align: left;
            padding: 10px 12px 12px 12px;
            .contact-elt {
              color: $white;
              font-weight: 100;
              text-align: left;
              @include x-rem('font-size', 16px);
              a {
                background-color: transparent;
                padding: 0;
                justify-content: flex-start;
                @include x-rem('font-size', 16px);
              }
            }
            .contact-title {
              border-bottom: 0;
            }
          }
          .first-contact-elt {
            border-bottom: 1px solid $white;
          }
        }
      }
    }

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      //transition: transform 500ms linear;
      //transform: translateY(-100%);

      //&.active {
      //  transform: translateY(0%);
      //}
    }
    }

  }
}
