@media only screen and (max-width: $size-medium-large) {

  .page-digital-onboarding {
    #main-content {
      padding: 0;

      #block-selection {
        position: static;
        width: 100%;
        text-align: left;
        background-color: $blue_medium;

        ul li .icon {
          display: none;
        }

        ul.items {
          margin: 0;

          li {
            &:after {
              display: none;
            }

            &.active {
              display: inline-block;

              &:not(:first-child) {
                &:before {
                  content: ' / ';
                  display: inline-block;
                }
              }
            }
          }
        }

        span {
          color: $blue;
          font-weight: 700;
        }
      }
    }
  }
}


// TABLET < 768
@media only screen and (max-width: $size-medium-1) {
  .page-digital-onboarding {

    #block-edensprings-digital-onboarding-do-logo {
      max-width: 100%;
      padding: 13px;
    }

    #main-content{

      .home-intro, .page-intro {
        max-width: 100%;
        min-width: 100%;
        padding: 15px 0 0 0;
      }
    }
  }
}


@media only screen and (max-width: $size-small-1 ) {

  .page-digital-onboarding {

    i {
      text-rendering: optimizeLegibility;
    }

    #main-content {
      .homepage {

        .home-intro {

          h2 {
            font-size: 1.5rem;
          }
        }

        .breadcrumb {
          padding: 0 15px;

          ul {
            display: block;

            li:nth-child(1), li:nth-child(3), li:nth-child(5) {
              max-width: 50%;
              min-width: 50%;
              margin: 13px auto 13px auto;
            }

            li:nth-child(2), li:nth-child(4) {
              max-width: 50%;
              min-width: 50%;
              margin: 0 auto;
              height: 30px;

              span {
                top: 0;
                bottom: 0;
                right: auto;
                left: 50%;
                border: none;
                border-left: 1px solid $blue_light;
              }
            }
          }
        }
      }
    }

    #main-content {
      .page-step {

        .page-intro {

          h2 {
            line-height: 1.5rem;
            font-size: 1rem;
          }
        }

        .breadcrumb {
          ul {
            margin: 15px 0;

            li {
              h3 {
                font-size: 0.8rem;
                line-height: 1rem;
              }
            }
            li:nth-child(1), li:nth-child(3), li:nth-child(5) {
              @include x-rem('width', 165px);
              margin: 0;

              i {
                font-size: 2.5rem;
              }
            }
            li:nth-child(2), li:nth-child(4), li:nth-child(6) {
              @include x-rem('width', 70px);

              span {
                top: 70%;
              }
            }
          }
        }

        .error-forms-custom {
          width: 100%;
        }

        #edensprings-digital-onboarding-form-return #edit-submit {
          margin: 0;
          padding: .75rem 1.5rem;
          color: $blue;
        }

        #form-step form {

          width: 100% !important;
          margin: 0;
          padding: 0 20px;

          &#edensprings-digital-onboarding-form-step5-1,
          &#edensprings-digital-onboarding-form-step6-0,
          &#edensprings-digital-onboarding-form-step6-1 {
            h3 {
              text-align: center;
            }
          }

          .form-item.form-type-textfield {
            width: 100% !important;
            float: none !important;
            padding: 5px 0 !important;
          }

          .terms-conditions {
            width: 100%;

            + .form-item-terms-conditions {
              width: auto;

              label {
                width: auto;
              }
            }
          }

          &#edensprings-digital-onboarding-form-step5-1,
          &#edensprings-digital-onboarding-form-step6-0,
          &#edensprings-digital-onboarding-form-step6-1 {
            .wrapper-col {
              display: block;
              margin: 40px 0 20px 0;
              padding: 0;

              .col{
                display: block;
                width: 100%;
                padding: 0;
              }
            }

            .terms-conditions {
              width: 100%;
            }
          }

          h2 {
            width: 100%;
            margin: 0 0 10px 0;
            font-size: 1.5rem;
            line-height: 2rem;

            + p {
              width: 100%;
              font-size: 1rem;
            }
          }

          .form-radios,
          .wrapper-list {
            // display: block;
          }

          &#edensprings-digital-onboarding-form-step5-1,
          &#edensprings-digital-onboarding-form-step6-0,
          &#edensprings-digital-onboarding-form-step6-1 {
            .form-item-buying-nb-employees label {
              margin: 0;
            }
          }

          .form-radios {
            input[type="radio"] {
              + label {
                // @include x-rem('width',140px);
                // @include x-rem('height',140px);
                margin: 0 10px 25px 10px;
                padding: 0;
                font-size: 0.75rem;

                &:before {
                  @include x-rem('width',80px);
                  @include x-rem('height',80px);
                  margin: 0 auto 5px auto;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: 50px 50px;
                }

                &:hover {
                  transform: scale(1);
                }
              }

              &:checked + label {
                transform: scale(1);
              }

              &:checked,
              &:not(:checked) {
                + label {
                  // margin: 0 !important;
                  // padding: 0 0 0 18px !important;
                }
              }
            }
          }

          .wrapper-list {
            > ul {
              display: block;

              > li {
                display: block;

                &.edit-plan-0,
                &.edit-plan-1,
                &.edit-plan-2 {
                  padding: 15px 5px;

                  > .icon {
                    display: flex;
                  }
                }

                > .icon {
                  display: block;

                  > span {
                    order: 1;
                    margin: 0 5px 0 10px;
                    font-size: 0.9rem;
                    font-weight: 500;
                    border: none;

                    + img {
                      order: 0;
                      position: relative;
                      top: 20px;

                      + span {
                        order: 2;
                      }
                    }
                  }
                }

                ul {
                  margin-top: -60px;
                  padding: 5% 5% 0 70px;
                }

                &:hover, &.active {
                  transform: scale(1);
                }
              }
            }
          }
        }
      }
    }
  }
}