// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .well-being {
    .characteristic-block {
      padding: 0;

      .container {
        display: block;

        .characteristic {
          display: block;
          text-align: center;
          padding: 30px 0;

          .characteristic-image {
            margin: 0 0 15px;
          }

          .characteristic-body {
            padding: 0 15px;
          }

          &:nth-child(even) {
            background: $white_medium;
          }
        }
      }


      &:nth-child(even) {
        background: $white;
      }
    }
  }
}
