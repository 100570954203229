.wng-messages {
  margin: 0;

  .messages {
    border-width: 0 0 3px 0;
    background-image: none;
    @include x-rem('padding', 10px 50px);
    margin: 0;
    @include x-rem('font-size', 18px);
    font-weight: 400;

    ul {
      max-width: 1200px;
      margin: auto;
      list-style: circle;

      li {
        margin-left: 20px;
      }
    }
  }
}
