.node-type-wngv-repertory {
  .breadcrumb {
    pointer-events: none;

    a {
      pointer-events: all;
    }
  }
  .main-banner-title {
    pointer-events: none;
    position: absolute;
    max-width: 1200px;
    top: 280px;
    z-index: 3;
    left: 0;
    right: 0;
    margin: auto;
    max-height: 75%;
    overflow: hidden;

    .left {
      width: 33%;

      h1 {
        display: inline-block;
        width: 80%;
        font-weight: 800;
        color: $blue;
        @include x-rem('font-size', 55px);
        @include x-rem('letter-spacing', 2px);
        @include x-rem('line-height', 55px);
        padding-bottom: 15px;
      }

      .field-name-field-description {
        width: 100%;
        color: $blue;
        @include x-rem('font-size', 18px);
        @include x-rem('line-height', 22px);
        font-weight: 400;
        margin: 0;
      }
    }
  }

  .locations {
    h2 {
      text-align: center;
      margin: 0 0 30px;
    }
    .field-name-field-location {
      & > .field-items {
        display: flex;
        flex-wrap: wrap;

        & > .field-item {
          margin-right: 30px;
          flex: 0 0 calc(33% - 20px);
          max-width: calc(33% - 20px);
          @include box-shadow-content();
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 30px;

          .location {
            height: 100%;
            border: 2px solid $white;
            padding: 20px 15px;

            .location-container {
              display: flex;
              align-items: center;

              .image {
                flex: 0 0 120px;
                margin-right: 30px;

                img {
                  filter: grayscale(1);
                  opacity: 0.5;
                }
              }

              .infos {
                letter-spacing: 2px;

                h3 {
                  margin: 0 0 5px;
                  @include x-rem('font-size', 20px);
                  color: $blue;
                  line-height: 22px;
                }

                .field-name-field-location-location {
                  @include x-rem('font-size', 15px);
                  line-height: 17px;
                  color: #9296AD;
                  font-weight: bold;
                  margin-bottom: 10px;
                }

                .field-name-field-address {
                  @include x-rem('font-size', 13px);
                  line-height: 15px;
                  margin-bottom: 10px;
                }

                .phone-number {
                  text-align: left;

                  a {
                    color: $grey;
                    @include x-rem('font-size', 13px);
                    line-height: 15px;

                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }

                .market-website {
                  a {
                    color: $blue_light;
                    @include x-rem('font-size', 13px);
                    line-height: 15px;

                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  i {
                    color: $blue_light;
                  }
                }
              }
            }

            .links{
              width:100%;

              h4 {
                line-height: 1.5;
                @include x-rem('font-size', 15px);
                color: $grey;
                font-weight: normal;
                margin: 10px 0 0;
              }

              p {
                margin: 0 0 10px;
              }
            }

            &:hover,
            &.active {
              border: 2px solid $blue_light;

              .image {
                img {
                  filter: grayscale(0);
                  opacity: 1;
                }
              }
            }
          }

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
