// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  footer {
    @include x-rem('padding', 20px 0 10px);

    #block-edensprings-self-service-blocks-ss-footer {
      @include x-rem('padding', 0 10px);

      & > .content {
        display: block;
        text-align: center;

        & > div {
          margin: 10px 0;

          &.copyright-container {
            text-align: center;
          }
        }
      }
    }

    #block-edensprings-website-ng-blocks-website-ng-v3-footer {
      @include x-rem('padding', 0 10px);
      
      .footer-top {
        display: block;
        text-align: center;

        ul.footer-certifications {
          padding: 0;
          margin-bottom: 10px;
        }

        .separator {
          display: none;
        }

        ul.footer-social {
          padding: 0;
          margin-left: 0;

          &:before {
            display: none;
          }
        }
      }

      .footer-bottom {
        display: block;
        text-align: center;

        ul {
          display: block;
        }
      }

      .feedbacks {
        &.reviews iframe {
          height:320px;
        }
      }
    }
  }

  // COOKIE BANNER.
  #sliding-popup {
    max-height: 100%;
    overflow-y: scroll;
    pointer-events: auto;
    position: sticky;

    .eu-cookie-compliance-banner--default {
      padding: 0 10px;
    }
    
    .eu-cookie-compliance-banner--categories {
      .popup-content {
        padding: 15px;

        .eu-cookie-compliance-category {
          margin-bottom: 10px;
          & > div {
            label {
              @include x-rem('font-size', 14px);
              line-height: 16px;
            }
          }

          .eu-cookie-compliance-category-description {
            @include x-rem('font-size', 12px);
            line-height: 14px;
          }
        }
      }

      .eu-cookie-compliance-categories-buttons {
        text-align: center;
      }
    }
    
    .popup-content {
      flex-direction: column;
      p {
        @include x-rem('font-size', 14px);
        line-height: 16px;
      }
      #popup-buttons {
        flex-direction: column;
      }
    }
  }
}
