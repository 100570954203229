.view-wng-blogs {
  display: flex;
  flex-wrap: wrap;

  .view-filters {
    @include box-shadow-content();
    margin-right: 50px;
    padding: 15px 20px;
    flex: 0 0 200px;

    .views-exposed-form {
      .views-exposed-widget {
        padding: 0;
        float: none;
      }

      .views-widget-filter-blog_categ {
        label {
          text-align: center;
          color: $blue;
          @include x-rem('font-size', 20px);
          font-weight: bold;
          margin-bottom: 15px;
        }

        .form-item {
          padding: 0;

          &.form-item-blog-categ {
            a {
              margin: 5px 0;
              padding: 5px;
              line-height: 15px;
              display: inline-block;
              &.active {
                text-decoration:underline;
              }
              &.sublevel {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .view-content {
    flex: 1;

    .views-row {
      @include box-shadow-content();
      margin-bottom: 20px;
      padding: 20px;

      &:last-child {
        margin: 0;
      }

      .blog {
        display: flex;
        align-items: center;

        .blog-image {
          flex: 0 0 285px;
          margin-right: 30px;
        }

        .blog-infos {
          flex: 1;
          overflow: hidden;

          h2 {
            @include x-rem('font-size', 20px);
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 2px;
            margin: 0 0 5px;
          }

          .blog-long-title {
            margin-bottom: 15px;
          }

          .more-details {
            float: right;
            margin-top:20px;
            a.btn {
              margin: 0;
              padding: 12px 20px;
            }
          }
        }
      }
    }
  }

  .item-list {
    width: 100%;
    text-align: center;

    .pager {
      margin: 15px 0;
      @include box-shadow-content();
      padding: 20px 10px;

      li {
        padding: 0;
        margin: 0;
        display: inline-block;
      }

      li.pager-current,
      li a {
        display: inline-block;
        padding: 2px 8px;
        margin: 0 5px;
        background: $white_medium;
      }

      li.pager-current,
      li a:hover {
        color: $white;
        //background: $blue_light;
        background: #2968FE;
      }

      li.pager-previous {
        margin-bottom: 15px;
      }

      li.pager-next {
        margin-top: 15px;
      }
    }
  }
}

.node-type-blog {
  .content.container {
    @include box-shadow-content();
    padding: 30px 20px;
    background: $white;

    .field-name-field-blog-long-title {
      @include x-rem('font-size', 18px);
      color: $blue;
      line-height: 20px;
      margin-bottom: 30px;
    }

    .tags {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .field-name-field-blog-category {
        flex: 1;
        padding-right: 20px;

        .field-item {
          display: inline-block;
          border: 1px solid $blue;
          padding: 2px 8px;
          color: $blue;
          @include x-rem('font-size', 14px);
          border-radius: 30px;
          margin: 0 10px 5px 0;
        }
      }

      a {
        display: block;

        img {
          display: block;
          margin: auto;
        }
      }
    }

    .body {
      display: flex;

      .field-name-body {
        flex: 1;

        p {
          margin: 0 0 15px;
        }
      }
      .blog-image {
        flex: 0 0 440px;
        padding: 0 0 0 20px;
      }
    }
  }



  .related-post {
    @include x-rem('padding', 60px 0 30px);

    h3 {
      text-align: center;
      color: $blue;
      font-weight: bold;
      @include x-rem('font-size', 35px);
      @include x-rem('margin-bottom', 50px);
      margin-top: 0;
    }

    .field-name-field-blog-related-post {
      & > .field-items {
        display: flex;
        justify-content: center;

        & > .field-item {
          flex: 0 0 calc(25% - 10px);
          max-width: calc(25% - 10px); // HACK IE11
          @include x-rem('margin-right', 15px);

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}


// PRODUCT PAGE TEASER
article.node-blog.node-teaser {
  height: 100%;
  @include x-rem('padding', 20px 10px);
  @include box-shadow-content();
  background: $white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .field-type-image {
    .field-item {
      a {
        display: inline-block;
        overflow: hidden;

        img {
          display: block;
          opacity: 0.7;
          transition: transform .5s, opacity .5s; /* Animation */

          &:hover {
            transform: scale(1.1);
            opacity: 1;
          }
        }
      }
    }
  }

  h4 {
    @include x-rem('font-size', 19px);
    @include x-rem('letter-spacing', 2px);
    @include x-rem('line-height', 23px);
    @include x-rem('margin', 5px 0 20px);
    color: $blue;

    a {
      color: $blue;

      &:hover {
        color: $blue_light;
      }
    }
  }

  div.more-details {
    margin-top: auto;

    a {
      margin: 0;
    }
  }
}
// END OF PRODUCT TEASER
