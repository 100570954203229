article.node-wngh-clients {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 60px;

  h2 {
    margin: 0 0 30px;
  }

  .field-name-field-client-logos .field-items/*:not(.slick-slider)*/ {
    display: flex;
    align-items: center;
    justify-content: center;

    .slick-list {
      width: 100%;
    }

    .field-item {
      margin: 0 10px;

      img {
        margin: auto;
      }
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    i {
      color: $blue;
      cursor: pointer;
    }
  }
}
