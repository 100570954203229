.node-type-wngv-web-product {
  .top-product {
    box-shadow: 0 4px 4px -4px $grey_disabled;

    .sse-product-img-variant-addr {
      display: flex;
      justify-content: normal;
      @include x-rem('padding-bottom', 30px);
      @include x-rem('margin-bottom', 30px);
      box-shadow: 0 4px 4px -4px $grey_disabled;
      overflow: hidden;

      .sse-product-img {
        overflow: hidden;
        flex: 0 0 400px;
        max-width: 400px; // HACK IE11
        @include x-rem('margin-right', 60px);

        .slick-slide {
          text-align: center;

          img {
            display: inline-block;
          }
        }

        .slider-nav-thumbnails {
          display: none;
          margin: 15px 0 0;
        }
        
        .slick-dots {
          display: flex!important;
          justify-content: center;
          @include x-rem('margin', 15px 0);
          padding: 0;
          text-align: center;

          li {
            @include x-rem('margin-right', 5px);
            display: inline-block;
            opacity: 0.5;
            border: 2px solid $white;

            &:last-child {
              margin: 0;
            }

            a, img {
              display: block;
              cursor: pointer;
            }

            &.slick-active {
              opacity: 1;
              border: 2px solid $blue_light;
            }
          }
        }
      }

      .introduction {
        h1 {
          padding-bottom: 10px;
          float: left;
        }
        .print-icon {
          float:right;
          padding-top: 10px;
          a {
            color: #ccc;
          }
        }

        // Body.
        .field-name-body {
          clear: both;
          p {
            @include x-rem('font-size', 16px);
            color: #444444;
            font-weight: 500;
            line-height: 22px;
          }
        }

        .cta-links {
          display: flex;
          justify-content: left;
          .cta-link {
            margin: 30px 10px 10px 0;
            text-decoration: none;
            padding: 10px;
            background: #ffa401;
            max-width: 300px;
            border-radius: 10px;
            text-align: center;
            color: $white;
            font-weight: bold;
            @include x-rem('font-size', 22px);
          }
        }
      }
    }

    .sse-product-texts {
      overflow: hidden;

      .product-features {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
        //width: 70%;
        margin: 0 auto 50px;

        & > div.product-feature {
          //max-width: 100px;
          margin: 0 15px;

          svg {
            display: block;
            margin: 0 auto;
            fill: $blue;
          }
          span {
            @include x-rem('font-size', 14px);
            color: $blue;
            line-height: 16px;
          }
        }
      }
      .video-container {
        width: 70%;
        margin: auto;
        max-height: 400px;
        iframe {
          max-height: 400px;
        }
      }
      .field-collection-container {
        margin: 0;
        border: none;

        .field-name-field-pp-characteristic-list {
          & > .field-items {
            & > .field-item {
              @include x-rem('margin-bottom', 30px);

              .field-collection-view {
                margin: 0;
                padding: 0;
                border: none;

                .field-name-field-ppcl-title {
                  color: $blue;
                  @include x-rem('font-size', 20px);
                  font-weight: 600;
                  @include x-rem('padding-bottom', 8px);
                  @include x-rem('margin-bottom', 15px);
                  border-bottom: 1px solid $grey_line;
                }

                .field-name-field-ppcl-introduction {
                  @include x-rem('margin-bottom', 15px);
                    .field-item {
                      @include x-rem('font-size', 16px);
                      color: #444;
                      font-weight: 500;
                    }
                }

                .field-name-field-ppcl-list-item {
                  @include x-rem('margin-left', 20px);

                  .field-item {
                    @include x-rem('font-size', 16px);
                    color: #444;
                    font-weight: 500;

                    &:before {
                      content: '';
                      display: inline-block;
                      width: 6px;
                      height: 6px;
                      border: 2px solid $blue_light;
                      background: $white;
                      border-radius: 50%;
                      @include x-rem('font-size', 14px);
                      line-height: 14px;
                      @include x-rem('margin', 0 8px 2px 8px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .main-tabs-container {
      .tabs-container {
        padding-top: 25px;
        ul.tabs-header {
          position: relative;
          display: flex;
          padding: 0;
          flex-direction: row;
          li.tab-title {
            flex: 1 1 25%;
            position: relative;
            text-align: center;
            padding-top: 10px;
            margin: 0 5px;
            border-top-color: $blue_light;
            border-top-width: 4px;
            border-top-style: solid;
            @include x-rem('font-size', 18px);
            color: $blue_light;
            cursor: pointer;
              &:before {
                content:'';
                position:absolute;
                width: 100%;
                border-color: $blue_light;
                border-top-width: 4px;
                border-top-style: solid;
                left: 0;
                top: -8px;
                opacity: 0;
                transition: all ease 0.1s;
              }
            &:hover {
              // border-top-width: 8px;
              // margin-top: -4px;
              &:before {
                opacity: 1;
              }
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            &.active {
              &:after {
                border-color: $blue_light transparent transparent;
                border-style: solid;
                border-width: 8px 8px 0;
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -8px;
              }
            }
            &.one-tab {
              border-top: 0;
              text-align: left;
              &:hover {
                &:before {
                  opacity: 0;
                }
              }
              &.active {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        div.tabs {
          position: relative;
          padding-top: 20px;
          div.tab {
            &.show {
              display: block;
            }
            &.no-show {
              display: none;
            }
            .tab-content {
              // position: absolute;
              width: 100%;
              left: 0;
              text-align: left;
              .tab-pane {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .title-mobile {
                  flex: 1 0 100%;
                  @include x-rem('font-size', 16px);
                  color: $blue_light;
                }
                .col-left_text {
                  flex: 1 1 75%;

                  .col-left_text-intro {
                    display: block;
                  }

                  .col-left_text-list {
                    padding-left: 20px;

                    .col-left_text-list-item {
                      list-style-type: none;
                      margin: 2px 0;
                      position: relative;
                      padding-left: 20px;

                      &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 10px;
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border: 2px solid $blue_light;
                        background: $white;
                        border-radius: 50%;
                        @include x-rem('font-size', 14px);
                        line-height: 14px;
                        @include x-rem('margin', 0 8px 2px 8px);
                      }
                    }
                  }
                }

                .col-right_image {
                  flex: 1 1 25%;
                  padding: 15px 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .sse-product-related {
    @include x-rem('padding', 60px 0 30px);

    h3 {
      text-align: center;
      color: $blue;
      font-weight: bold;
      @include x-rem('font-size', 35px);
      @include x-rem('margin-bottom', 50px);
      margin-top: 0;
    }

    .field-name-field-web-related-product {
      & > .field-items {
        display: flex;
        justify-content: center;

        & > .field-item {
          flex: 0 0 calc(25% - 10px);
          max-width: calc(25% - 10px); // HACK IE11
          @include x-rem('margin-right', 15px);

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

// PRODUCT PAGE TEASER
article.node-wngv-web-product {
  height: 100%;
  @include x-rem('padding', 20px 10px);
  @include box-shadow-content();
  background: $white;

  &.node-teaser {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .field-type-image {
      .field-item {
        a {
          display: inline-block;
          overflow: hidden;

          img {
            display: block;
            opacity: 0.7;
            transition: transform .5s, opacity .5s; /* Animation */

            &:hover {
              transform: scale(1.1);
              opacity: 1;
            }
          }
        }
      }
    }

    h4 {
      @include x-rem('font-size', 19px);
      @include x-rem('letter-spacing', 2px);
      @include x-rem('line-height', 23px);
      @include x-rem('margin', 5px 0 20px);
      color: $blue;

      a {
        color: $blue;

        &:hover {
          color: $blue_light;
        }
      }
    }

    div.more-details {
      margin-top: auto;

      a {
        margin: 0;
      }
    }
  }
}
// END OF PRODUCT TEASER

// ISRAEL
html[dir="rtl"] {
  .node-type-wngv-web-product {
    .top-product {
      .sse-product-img-variant-addr {
        .sse-product-img {
          @include x-rem('margin-right', 0);
          @include x-rem('margin-left', 60px);
        }
      }
    }
  }
}
