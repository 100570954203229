.tabs-admin {
  ul {
    @include x-rem('margin', 10px 10px 30px);
    border-color: $blue;

    li {
      display: inline-block;
      margin-bottom: -1px;

      a {
        padding: 8px 12px;
        border-radius: 10px 10px 0 0;
        display: inline-block;
        font-weight: 500;
        background: $white;
        color: $blue;
        border-color: $blue;

        &.active,
        &:hover {
          background: $blue;
          color: $white;
          border-color: $blue;
        }
      }
    }
  }
}
