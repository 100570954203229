// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  #block-edensprings-website-ng-welcome-popin-eden-welcome-popin {
    #eden-welcome-popin {
      top: 50%;
      background-color: #fff;
      position: absolute;
      left: 5%;
      transform: translateX(-2.5%) translateY(-50%);

      .background-content {
        .close-popin {
          right: 0;
        }
      }
    }
  }
}
