.springs-map {

  .content > h2 {
    text-align: center;
  }

  .content > .field-name-body {
    margin-bottom: 80px;
  }

  .field-name-field-wngv-springs-spring-ref {
    margin: 50px 0;

    & > .field-items {
      display: flex;
      flex-wrap: wrap;

      & > .field-item {
        flex: 0 0 calc(50% - 30px);
        width: calc(50% - 30px);
        margin: 0 15px 30px;
        @include box-shadow-content();

        .spring {
          padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100%;

          h2 {
            margin: 0;
            @include x-rem('font-size', 22px);
          }

          .field-name-field-wngh-spring-place {
            color: $grey_transparent;
            letter-spacing: 2px;
          }

          .spring-file {
            margin-top: auto;
            display: flex;
            align-items: center;

            i, a {
              color: $blue_light;
              vertical-align: middle;
            }

            i {
              margin-right: 5px;
            }

            a {
              @include x-rem('font-size', 14px);
              line-height: 16px;
              font-weight: bold;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &.active {
            border: 2px solid $blue_light;
          }
        }
      }
    }
  }

  #map {
    .gmap-popup {
      padding: 15px 25px;
      text-align: center;

      span.title {
        @include x-rem('font-size', 16px);
        color: $blue;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
      }

      span.place {
        display: block;
        color: $grey;
        @include x-rem('font-size', 14px);
        font-weight: 500;
        margin-bottom: 15px;
      }

      span.gmap-btn {
        cursor: pointer;
        background: $blue;
        padding: 10px 15px;
        border-radius: 30px;
        color: $white;
        @include x-rem('font-size', 14px);
        font-weight: 500;
        display: block;
        transition: background 0.5s ease;

        &:hover {
          background: $blue_light;
        }
      }
    }
  }
}
