// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .partners {
    .content {
      margin: 30px auto;
    }

    .field-name-field-wngv-partners-partner-ref {
      & > .field-items {
        display: block;

        & > .field-item {
          //margin: 100px 0;
        }
      }
    }

    .partner {
      .partner-content {
        //display: none;
        //opacity: 1;
        background: $blue_light;
        position: relative;
        opacity: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        transition: none;

        .partner-description {
          .field-name-body {
            p {
              color: $white;
            }
          }
        }

        .partner-link {
          i, a {
            color: $blue;
          }
        }

        .arrow {
          background: $blue;
          position: absolute;
          top: -15px;
          left: calc(50% - 10px);
        }

        &.active {
          //display: flex;
          margin-top: 50px;
          padding: 20px 15px 15px;
          overflow: initial;
          opacity: 1;
          height: auto;
          transition: opacity 1s ease-out;
        }
      }
    }
  }
}
