// Screen < 1200px
@media only screen and (max-width: $size-large-1){
  body.node-type-template-landing-ng {
    &.webform-inside {

      ///--- TITLE ---///
      .page section[role='main'] .title-container {
        .webform-container {
          h1 {
            width: 66%;
          }
        }
      }

      ///--- WEBFORM ---///
      #block-edensprings-website-ng-commonform-website-ng-commonform {
        .webform-wrapper {
          &.sticky {
            left: calc(66% + 20px);
            top: initial;
            width: 30%;
            padding: 10px 10px 5px 10px;
          }
        }
      }

      ///--- PRESENTATION ---///
      article.node-presentation {
        .component-container {
          .pres-container {
            width: 66%;
            flex-direction: column;

            .media {
              flex: 1;
              margin: 0 auto;
            }

            .content {
              flex: 1;
              padding-left: 20px;
            }
          }
        }
      }

      ///--- SLIDER ---///
      article.node-slider {
        .not-tablet {
          display: none !important;
        }
        article.node-slide {
          width: 66%;
          flex-direction: column;

          .slide-left {
            flex: 1;
            margin: 0 auto;
          }

          .slide-right {
            flex: 1;
            margin: 0 auto;
            padding-left: 20px;
          }
        }
      }

      ///--- CALL TO ACTION ---///
      article.node-call-to-action{
        div.node-call-to-action {
          .field {
            width:66%;
            padding-left:25px;
            padding-right:25px;
          }
        }
      }

      ///--- CARDS ---///
      article.node-cards {
        & > .field {
          & > .field-items {
            width: 66%;
            display:flex;
            flex-direction: column;
            & > .field-item {
              margin:0 auto 15px;
              flex: 0 0 490px;
              max-width: 490px;
            }
          }
        }
      }

      ///--- ICONS INFO ---///
      article.node-icons-info {
        div.node-icons-info .field-name-field-icon-info > .field-items > .field-item {
          flex: 0 0 25%;
        }
      }

      ///--- TEXT BLOCK ---///
      article.node-text-block {
        & > div.node-text-block {
          & > .field {
            width:66%;
          }
        }
      }

      ///--- TECHNICAL SHEET ---///
      article.node-technical-sheet {
        & > .container-centered {
          & > .ts-container {
            width: 66%;
            .webform-column-container {
              max-width:450px;
              .top-container .image {
                display:none
              }
            }
          }
        }
      }

      ///--- TABS ---///
      article.node-tabs {
        .tabs-btn-container {
          .centered-container {
            ul.tabs-btn {
              width: 66%;
            }
          }
        }
        div.tabs-list {
          .field-name-field-tabs {
            width: 66%;
            padding-left:20px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: $size-medium-large-1){

  ///--- BIG BANNER ---///
  body.node-type-template-landing-ng {
    &.webform-inside {
      article.node-big-banner {
        .container {
          .content {
            width: 66%;
          }
        }
      }
    }
  }

  ///--- TABS ---///
  article.node-tabs {
    div.tabs-list {
      .field-name-field-tabs {
        .field-name-field-image-unique {
          flex:0 0 160px;
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){

  body.node-type-template-landing-ng {

    &.webform-inside {

      ///--- TITLE ---///
      .page section[role='main'] .title-container {
        .webform-container {
          h1 {
            width: 100%;
          }
        }
      }

      ///--- WEBFORM ---///
      #block-edensprings-website-ng-commonform-website-ng-commonform {
        #gaq-btn{
          display:block;
        }
        .webform-wrapper {
          &.sticky {
            left: 0;
            top: -100%;
            width:100%;
            padding:10px 15px 5px 25px;
          }
          .close{
            display:block;
          }
        }
      }

      ///--- PRESENTATION ---///
      article.node-presentation {
        .component-container {
          width: 100%;
          .pres-container {
            width: 100%;
            .media{
              img {
                margin:0 auto;
              }
            }
            .content {
              padding-left: 10px;
            }
          }
        }
      }

      ///--- SLIDER ---///
      article.node-slider {
        .slick-dots{
          width:100%;
        }
        article.node-slide {
          width: 100%;
          margin: 0 auto;

          .slide-left {
            img {
              margin: 0 auto;
            }
          }

          .slide-right {
            padding: 15px;
          }
        }
      }

      ///--- CALL TO ACTION ---///
      article.node-call-to-action{
        div.node-call-to-action {
          .field {
            width:100%;
          }
        }
      }

      ///--- BIG BANNER ---///
      article.node-big-banner {
        .container {
          .content {
            width: calc(100% - 40px);
            margin:0 auto;
          }
        }
      }

      ///--- CARDS ---///
      article.node-cards {
        & > .field {
          & > .field-items {
            width: 100%;
          }
        }
      }

      ///--- TEXT BLOCK ---///
      article.node-text-block {
        & > div.node-text-block {
          & > .field {
            width:100%;
          }
        }
      }

      ///--- ICONS INFO ---///
      article.node-icons-info {
        & > div.node-icons-info {
          & > div.field {
            width:100%;
          }
        }
      }

      ///--- TECHNICAL SHEET ---///
      article.node-technical-sheet {
        & > .container-centered {
          & > .ts-container {
            width: 100%;
          }
        }
      }

      ///--- TABS ---///
      article.node-tabs {
        .tabs-btn-container {
          .centered-container {
            ul.tabs-btn {
              width: 100%;
            }
          }
        }
        div.tabs-list {
          .field-name-field-tabs {
            width: 100%;
            padding-left:0;
          }
        }
      }
    }
  }
}
