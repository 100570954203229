// < 1024
@media only screen and (max-width: $size-medium-large-1) {
  body.page-residential-onboarding,
  body.page-business-onboarding {
    h1 {
      @include x-rem('font-size', 30px);
      line-height: 28px;
    }

    // Step 2.
    #edit-main-container {
      display: block;

      #edit-right {
        margin: 30px 0 0;
      }

      &.reverse-order {
        #edit-right {
          margin: 0 0 0;
        }

        #edit-left {
          margin-top: 30px;
          border-bottom: 1px solid $grey_disabled;
        }
      }
    }
  }
}

// < 768
@media only screen and (max-width: $size-medium-1) {
  body.page-residential-onboarding,
  body.page-business-onboarding {

    #edit-main-container {
      display: flex;
      flex-direction: column;

      &.reverse-order {
        flex-direction: column-reverse;
      }
    }

    #edit-sub-container {
      width: 100%;
      padding-top: 10px;

      .form-item-data-collect-email, .form-item-data-collect-email-business, .form-item-data-collect-sms, .form-item-data-collect-sms-business, .form-item-data-collect-phone, .form-item-data-collect-phone-business, .form-item-data-collect-post, .form-item-data-collect-post-business{
        padding-left: 30px;
        display: table-cell;
      }
    }

    // Step 1.
    #edensprings-rob-customer-form-step1 {
      width: 100%;

      input#edit-city {
        width: 100%;
      }
    }

    #block-edensprings-rob-rob-logo {
      max-width: 100%;
      padding: 13px 10px;
    }

    .rob-package {
      text-align: center;

      .package-info {
        display: block;

        .package-description {
          margin-left: 0;
        }
      }
    }
    form#edensprings-rob-customer-form-step2 {
      h1 {
        padding-bottom: 0;
      }
    }
    .tooltip {
      position: static;
      .tooltip-right {
        left: 0;
        right: 0;
        top: unset;
        bottom: 25px;
        margin: auto;
      }
    }

    .form-item-bottles {
      & > label {
        position: relative;
      }
    }


    #edensprings-rob-customer-form-step3 {
      #edit-payment-methods {
        .form-item-payment-methods {
          label {
            display: block;

            .img-container {
              margin-top: 10px;
            }
          }
        }
      }
    }

    // Step 4
    #edensprings-rob-customer-form-step4 {
      #edit-left {
        width: 100%;
      }

      .row {
        display: block;
        text-align: center;
      }
      
      .SfcField {
        margin-bottom: 15px;
      }
    }

    // Step 5 & Step iban
    #edensprings-rob-customer-form-step5,
    #edensprings-rob-customer-form-stepiban {
      #iframe-signaturit {
        height: 400px;
      }
    }


    // Step Signaturit
    #edensprings-rob-customer-form-stepagreement {
      #iframe-signaturit {
        height: 400px;
      }
    }


    // Step Signaturit
    #edensprings-rob-customer-form-stepagreement {
      #iframe-signaturit {
        height: 400px;
      }
    }

    // CONFIRMATION PAGE.
    .confirmation-page,
    .payment-in-progress-page {
      .information {
        display: block;

        .info-summary {
          display: block;

          h2 {
            margin-bottom: 10px;
          }

          & > div {
            margin-bottom: 30px;
          }
        }
      }
    }


    .form-line {
      display: block;

      &.three-item {
        .form-item {
          margin: 0 0 15px;
        }
      }

      .form-item:first-child {
        margin: 0 0 15px;
      }
    }


    .submit-btn {
      text-align: left;
    }


    .secure-img {
      text-align: left;

      img {
        width: 250px;
      }
    }

    .cards-img {
      justify-content: flex-start;

      img {
        width: 75px;
      }
    }
  }
}
