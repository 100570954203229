.node-type-wngv-career {
  .field-name-field-illimited-text-vs-image {
    margin-bottom: 50px;

    & > .field-items {
      & > .field-item:nth-child(odd) {
        .textvsimage {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

.careers {
  margin: 0 0 60px;
  border-top: 3px solid $grey_line;
  padding-top: 40px;

  & > h2 {
    text-align: center;
    margin: 0 0 15px;
  }

  & > span.description {
    text-align: center;
    display: block;
  }

  .pager {
    text-align: center;
    padding: 0;

    a {
      border-radius: unset;
      display: inline-block;
      background: $blue;
      @include x-rem('padding', 12px 50px);
      @include x-rem('border-radius', 30px);
      @include x-rem('margin', 5px 10px);
      line-height: 1.15;
      color: white;
      border: none;
      font-weight: 500;
      @include x-rem('font-size', 13px);
      cursor: pointer;
      transition: all 0.5s ease-out;

      &:hover {
        background: $blue_light;
        color: #fff;
      }

      &:disabled {
        background: $grey_disabled;
        cursor: default;
        color: $grey;
        opacity: 0.5;
      }
    }
  }
}

.view-join-us {
  margin-top: 60px;
  position: relative;

  &:before {
    content: '';
    height: 50px;
    width: 2px;
    background: $blue_light;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.job-offer {
  margin-bottom: 30px;
  @include box-shadow-content();
  background: $white;

  .content-short {
    padding: 40px 20px 20px;

    h3 {
      color: $blue;
      display: inline-block;
      @include x-rem('font-size', 18px);
      letter-spacing: 2px;
      margin: 0;
      margin-right: 15px;
      line-height: 20px;
    }

    .field-name-field-wngv-job-offer-area {
      color: $grey_transparent;
      letter-spacing: 2px;
    }
  }

  .job-offer-body {
    display: none;

    .body-padding {
      padding: 0 20px;

      .lists {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 30px;

        & > div {
          flex: 0 0 45%;

          .subtitle {
            color: $blue;
            font-weight: 500;
          }
        }
      }
    }
  }

  .btn-group {
    overflow: hidden;
    padding: 10px 20px 20px;

    .toggle-btn {
      display: none;
      text-align: center;
      @include x-rem('letter-spacing', 2px);
      @include x-rem('font-size', 15px);
      font-weight: 700;
      color: $blue;

      &.show-more {
        display: block;
        border-top: 1px solid $white_medium;
        padding-top: 15px;
      }

      &:hover {
        color: $blue_light;
      }
    }
  }

  &.active {
    .content-short {
      padding-bottom: 0;
    }

    .job-offer-description-summary {
      display: none;
    }

    .job-offer-body {
      display: block;
    }

    .btn-group {
      background: $white_medium;

      .toggle-btn {
        &.show-less {
          display: block;
        }

        &.show-more {
          display: none;
        }
      }
    }
  }
}

.spontaneous-app {
  text-align: center;
  background: url('../images/career.png') top center;
  height: 260px;
  max-width: 1200px;
  @include box-shadow-content();
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    @include x-rem('font-size', 28px);
    line-height: 30px;
    color: $blue;
    font-weight: bold;
    margin: 0 0 10px;
  }

  .field-name-field-wngv-spontaneous-subtitle {
    margin-bottom: 15px;
    max-height: 100px;
    overflow: hidden;

    p {
      margin: 0 0 5px;
    }
  }

  .spontaneous-form {
    display: none;
  }
}

// FORM ///
.node-type-wngv-career {
  .career-form {
    display: none;
    background: $white_medium;
    padding: 50px 0;

    & > div,
    & > form {
      width: 40%;
      margin: auto;

      .form-item {
        padding: 3px 0;

        &.webform-component > label {
          display: none;
        }
      }

      // Files (CV)
      .webform-component-file {
        margin: 3px 0;
        padding: 15px;
        text-align: center;
        background: $white;

        .form-managed-file {
          margin: 15px 0;

          span.file {
            margin-right: 10px;
            display: inline-block;
          }

          input[type="submit"] {
            padding: 6px 25px;
            margin-left: 10px;
          }

          input[type="submit"][name*="remove"] {
            background: $red;
          }
        }
      }

      .webform-component--rgpd {
        height: 78px;
        overflow: hidden;
        padding-bottom: 5px;
        margin: 10px 0;

        a {
          color: $blue;
          font-weight: 500;
        }
      }

      .webform-component-checkboxes {
        padding-top: 5px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        .form-item {
          padding: 0;
          margin: 15px 0 0;
        }

        input[type="checkbox"] {
          position: absolute;
          left: -9999px;

          & + label {
            cursor: pointer;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 16px;
              height: 16px;
              border: 2px solid $blue;
            }
          }

          &:checked {
            & + label {
              &:before {
                border: 2px solid $blue;
              }

              &:after {
                content: '';
                position: absolute;
                top: 4px;
                left: 4px;
                display: block;
                width: 8px;
                height: 8px;
                background: $blue;
              }
            }
          }
        }

        label {
          padding-left: 30px;
          display: inline-block;
          @include x-rem('font-size', 12px);
          line-height: 14px;
          font-weight: 500;
          color: $grey;
          vertical-align: top;
        }
      }

      .form-actions {
        text-align: center;
      }

      .simplebar-track.horizontal {
        display: none;
      }

      .simplebar-track.vertical {
        background: rgba(0, 0, 0, 0.2);

        .simplebar-scrollbar {
          &:before {
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }

      .simplebar-content {
        .simplebar-container {
          p {
            color: $grey;
          }
        }
      }
    }

    .links {
      display: none;
    }
  }
}

.node-type-wngv-others {

  h1 {
    // margin: 0 0 30px;
    margin: 0;
    text-align: center;
    padding: 0;
  }
  .webform-subtitle {
    display: block;
    text-align: center;
    margin: 20px auto 0;
    @include x-rem('font-size', 16px);
    font-weight: bold;
    color: $blue;
  }

  .webform-wrapper {
    margin: 30px auto 0 !important;
  }

  .career-form {
    width: 40%;
    margin: auto;
    @include box-shadow-content();
    background: $white;
    padding: 30px 50px;

    .messages {
      background-image: none;
      background-color: $orange;
      border: none;
      padding: 6px 10px;
      margin: 5px 0;
      color: $white;
      @include x-rem('font-size', 14px);
      border-left: 4px solid $yellow;

      ul {
        li {
          list-style: circle;
        }
      }
      button {
        display: none;
      }
    }
    form {
      overflow: hidden;

      .form-item {
        &.webform-component--rgpd {
          height: 78px;
          overflow: hidden;
          padding-bottom: 5px;
          margin: 10px 0;

          a {
            color: $blue;
            font-weight: 500;
          }
        }

        &.webform-component-file {
          margin: 3px 0;
          padding: 15px;
          text-align: center;
          background: $white;

          .form-managed-file {
            margin: 15px 0;

            span.file {
              margin-right: 10px;
              display: inline-block;
            }

            input[type="submit"] {
              padding: 6px 25px;
              margin-left: 10px;
            }

            input[type="submit"][name*="remove"] {
              background: $red;
            }
          }
        }

        &.webform-component-checkboxes {
          padding-top: 5px;
          border-top: 1px solid rgba(0, 0, 0, 0.2);

          .form-item {
            padding: 0;
            margin: 15px 0 0;
          }

          input[type="checkbox"] {
            position: absolute;
            left: -9999px;

            & + label {
              cursor: pointer;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 16px;
                height: 16px;
                border: 2px solid $blue;
              }
            }

            &:checked {
              & + label {
                &:before {
                  border: 2px solid $blue;
                }

                &:after {
                  content: '';
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  display: block;
                  width: 8px;
                  height: 8px;
                  background: $blue;
                }
              }
            }
          }

          label {
            padding-left: 30px;
            display: inline-block;
            @include x-rem('font-size', 12px);
            line-height: 14px;
            font-weight: 500;
            color: $grey;
            vertical-align: top;
          }
        }
      }

      .form-actions {
        margin: 30px 0 10px;
        text-align: center;

        input[type="submit"] {
          padding: 20px 60px;
          @include x-rem('font-size', 16px);
          font-weight: 500;
        }
      }
    }

    .simplebar-track.vertical {
      background: rgba(0, 0, 0, 0.2);

      .simplebar-scrollbar {
        &:before {
          background: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .simplebar-content {
      .simplebar-container {

        p {
          color: $grey;
        }
      }
    }

    .links {
      display: none;
    }
  }
}
