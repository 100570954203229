// TABLET < 1200
@media only screen and (max-width: $size-medium-large-1){
  .node-type-wngv-offers {
    .field-name-field-wngv-offers-offer-ref {
      & > .field-items {
        & > .field-item {
          flex: 0 0 calc(50% - 7.5px);
          max-width: calc(50% - 7.5px);

          // All 3 elements starting from 2.
          &:nth-child(3n+2) {
            margin-left: 0;
            margin-right: 0;
          }

          // Each 2 elements starting from one.
          &:nth-child(2n+1) {
            @include x-rem('margin-right', 15px);
          }

          // 3 first elements.
          &:nth-child(-n+3) {
            margin-top: 15px;
          }

          // 2 first elements.
          &:nth-child(-n+2) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .node-type-wngv-offers {
    .field-name-field-wngv-offers-offer-ref {
      & > .field-items {
        display: block;

        & > .field-item {
          max-width: none;
          margin: 0!important;
          margin-bottom: 15px!important;

          h2 {
            @include x-rem('font-size', 14px);
            line-height: 16px;
          }

          div.more-details {
            .btn {
              @include x-rem('padding', 12px 20px);
              @include x-rem('font-size', 11px);
            }
          }
        }
      }
    }
  }
}
