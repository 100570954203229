section#main-content {
  position: relative;
}

.tabs {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;

  &.tabs-admin {
    ul {
      margin: 0;
      border: 0;
      padding: 0;

      li {
        a {
          border-radius: 0 0 10px 10px;
          border-top: none;
          border-bottom: 1px solid $blue;
        }
      }
    }
  }
}

body.toolbar {
  #header-content.sticky {
    top: 64px!important;
  }

  &.node-type-wngv-others,
  &.page-gaq,
  &.node-type-wngv-web-product {
    div.region-content {
      //padding-top: 30px;
    }
  }

  &.no-main-banner {
    .page {
      .breadcrumb {
        padding-top: 40px;
      }
    }
  }

  &.page-catalog {
    .page {
      .breadcrumb {
        padding-top: 10px;
      }
    }
  }
}


