@media only screen and (max-width: $size-small-1){
  body{
    &::before{
      content:'phone' !important;
      display: none;
    }
  }
  .not-mobile{
    display:none !important;
  }
}

@media only screen and (min-width: $size-small) and (max-width: $size-medium-1){
  body{
    &::before{
      content:'tablet' !important;
      display: none;
    }
  }
  .not-mobile{
    display:none !important;
  }
}

@media only screen and (min-width: $size-medium) and (max-width: $size-medium-large-1){
  body{
    &::before{
      content:'desktop' !important;
      display: none;
    }
  }
  .not-tablet {
    display: none !important;
  }

  .only-mobile{
    display:none !important;
  }
}
@media only screen and (min-width: $size-medium-large-1) {
  body{
    &::before{
      content:'large' !important;
      display: none;
    }
  }
  .only-mobile{
    display:none !important;
  }
}

@media only screen and (min-width: $size-medium) and (max-width: $size-large) {
  .container {
    @include x-rem('padding', 0 10px);
  }
}

@media only screen and (max-width: $size-large) {
  .container {
    &.padding {
      @include x-rem('padding', 0 10px);
    }
  }

  .page {
    section[role="main"] {
      .container-bs {
        @include x-rem('padding', 20px 10px);
      }
    }
  }
}
