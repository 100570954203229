// DESKTOP < 1200
@media only screen and (max-width: $size-large-1){
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      &.default {
        width: 60%;
      }
    }
  }
}

// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .page-node-169372{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      .webform-wrapper {
        &.default {
          width: 80%;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      &.default {
        width: 80%;
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1){

  // To be able to see the Copyright when we have the commonform bottom.
  body:not(.wng) {
    //padding-bottom: 15px; @todo voir si ça sert.
  }

  .page-node-169372{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn {
        top: auto;
        left: 0;
        text-align: center;
        transform: rotate(0);
        padding: 10px 15px;
        position:initial;
        border-radius:initial;
        box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

        span {
          max-width: initial;
          width:100%;

          i {
            display: inline-block;
          }

          br {
            display: none;
          }
        }

      }

      .commonform-side-text {
        display: block;
        width: 100%;

        .webform-wrapper {
          display: block;
        }
      }

      .webform-wrapper {
        &.default {
          padding: 15px 10px;
          width: 100%;
        }

        &.sticky {
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: -100%;
          right: auto;
          background: $blue_form;
          overflow: scroll;

          .rtecenter{
            margin: 0;
          }
          fieldset {
            margin-bottom: 5px;
            .fieldset-legend{
              font-weight: 600;
            }
            .fieldset-description{
              font-size: 12px;
              font-weight: 600;
            }
            .form-item{
              &.webform-component-checkboxes{
                border-top: 0 !important;
                label{
                  font-size: .875rem !important;
                  line-height: 14px !important;
                }
              }
              select{
                height: 100%;
                &:after {
                  display: none !important;
                }
                option{
                  font-weight: 600;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-node-170590{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn {
        top: auto;
        left: 0;
        text-align: center;
        transform: rotate(0);
        padding: 10px 15px;
        position:initial;
        border-radius:initial;
        box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

        span {
          max-width: initial;
          width:100%;

          i {
            display: inline-block;
          }

          br {
            display: none;
          }
        }

      }

      .commonform-side-text {
        display: block;
        width: 100%;

        .webform-wrapper {
          display: block;
        }
      }

      .webform-wrapper {
        &.default {
          padding: 15px 10px;
          width: 100%;
        }

        &.sticky {
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: -100%;
          right: auto;
          background: $blue_form;
          overflow: scroll;

          .rtecenter{
            margin: 0;
          }
          fieldset {
            margin-bottom: 5px;
            .fieldset-legend{
              font-weight: 600;
            }
            .fieldset-description{
              font-size: 12px;
              font-weight: 600;
            }
            .form-item{
              &.webform-component-checkboxes{
                border-top: 0 !important;
                label{
                  font-size: .875rem !important;
                  line-height: 14px !important;
                }
              }
              select{
                height: 100%;
                &:after {
                  display: none !important;
                }
                option{
                  font-weight: 600;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-node-170632{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn {
        top: auto;
        left: 0;
        text-align: center;
        transform: rotate(0);
        padding: 10px 15px;
        position:initial;
        border-radius:initial;
        box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

        span {
          max-width: initial;
          width:100%;

          i {
            display: inline-block;
          }

          br {
            display: none;
          }
        }

      }

      .commonform-side-text {
        display: block;
        width: 100%;

        .webform-wrapper {
          display: block;
        }
      }

      .webform-wrapper {
        &.default {
          padding: 15px 10px;
          width: 100%;
        }

        &.sticky {
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: -100%;
          right: auto;
          background: $blue_form;
          overflow: scroll;

          .rtecenter{
            margin: 0;
          }
          fieldset {
            margin-bottom: 5px;
            .fieldset-legend{
              font-weight: 600;
            }
            .fieldset-description{
              font-size: 12px;
              font-weight: 600;
            }
            .form-item{
              &.webform-component-checkboxes{
                border-top: 0 !important;
                label{
                  font-size: .875rem !important;
                  line-height: 14px !important;
                }
              }
              select{
                height: 100%;
                &:after {
                  display: none !important;
                }
                option{
                  font-weight: 600;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-node-170653{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn {
        top: auto;
        left: 0;
        text-align: center;
        transform: rotate(0);
        padding: 10px 15px;
        position:initial;
        border-radius:initial;
        box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

        span {
          max-width: initial;
          width:100%;

          i {
            display: inline-block;
          }

          br {
            display: none;
          }
        }

      }

      .commonform-side-text {
        display: block;
        width: 100%;

        .webform-wrapper {
          display: block;
        }
      }

      .webform-wrapper {
        &.default {
          padding: 15px 10px;
          width: 100%;
        }

        &.sticky {
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: -100%;
          right: auto;
          background: $blue_form;
          overflow: scroll;

          .rtecenter{
            margin: 0;
          }
          fieldset {
            margin-bottom: 5px;
            .fieldset-legend{
              font-weight: 600;
            }
            .fieldset-description{
              font-size: 12px;
              font-weight: 600;
            }
            .form-item{
              &.webform-component-checkboxes{
                border-top: 0 !important;
                label{
                  font-size: .875rem !important;
                  line-height: 14px !important;
                }
              }
              select{
                height: 100%;
                &:after {
                  display: none !important;
                }
                option{
                  font-weight: 600;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-node-170569{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn {
        top: auto;
        left: 0;
        text-align: center;
        transform: rotate(0);
        padding: 10px 15px;
        position:initial;
        border-radius:initial;
        box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

        span {
          max-width: initial;
          width:100%;

          i {
            display: inline-block;
          }

          br {
            display: none;
          }
        }

      }

      .commonform-side-text {
        display: block;
        width: 100%;

        .webform-wrapper {
          display: block;
        }
      }

      .webform-wrapper {
        &.default {
          padding: 15px 10px;
          width: 100%;
        }

        &.sticky {
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: -100%;
          right: auto;
          background: $blue_form;
          overflow: scroll;

          .rtecenter{
            margin: 0;
          }
          fieldset {
            margin-bottom: 5px;
            .fieldset-legend{
              font-weight: 600;
            }
            .fieldset-description{
              font-size: 12px;
              font-weight: 600;
            }
            .form-item{
              &.webform-component-checkboxes{
                border-top: 0 !important;
                label{
                  font-size: .875rem !important;
                  line-height: 14px !important;
                }
              }
              select{
                height: 100%;
                &:after {
                  display: none !important;
                }
                option{
                  font-weight: 600;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-node-170611{
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn {
        top: auto;
        left: 0;
        text-align: center;
        transform: rotate(0);
        padding: 10px 15px;
        position:initial;
        border-radius:initial;
        box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

        span {
          max-width: initial;
          width:100%;

          i {
            display: inline-block;
          }

          br {
            display: none;
          }
        }

      }

      .commonform-side-text {
        display: block;
        width: 100%;

        .webform-wrapper {
          display: block;
        }
      }

      .webform-wrapper {
        &.default {
          padding: 15px 10px;
          width: 100%;
        }

        &.sticky {
          position: fixed;
          z-index: 999;
          width: 100%;
          height: 100%;
          top: -100%;
          right: auto;
          background: $blue_form;
          overflow: scroll;

          .rtecenter{
            margin: 0;
          }
          fieldset {
            margin-bottom: 5px;
            .fieldset-legend{
              font-weight: 600;
            }
            .fieldset-description{
              font-size: 12px;
              font-weight: 600;
            }
            .form-item{
              &.webform-component-checkboxes{
                border-top: 0 !important;
                label{
                  font-size: .875rem !important;
                  line-height: 14px !important;
                }
              }
              select{
                height: 100%;
                &:after {
                  display: none !important;
                }
                option{
                  font-weight: 600;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  #block-edensprings-website-ng-commonform-website-ng-commonform {
    #gaq-btn {
      top: auto;
      left: 0;
      text-align: center;
      transform: rotate(0);
      padding: 10px 15px;
      position:initial;
      border-radius:initial;
      box-shadow: 0px 8px 20px -6px rgba(0,0,0,0.56);

      span {
        max-width: initial;
        width:100%;

        i {
          display: inline-block;
        }

        br {
          display: none;
        }
      }

    }

    .commonform-side-text {
      display: block;
      width: 100%;

      .webform-wrapper {
        display: block;
      }
    }

    .webform-wrapper {
      &.default {
        padding: 15px 10px;
        width: 100%;
      }

      &.sticky {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: -100%;
        right: auto;
        background: $blue_form;
        overflow: scroll;
      }
    }
  }
}
