// Desktop -1200
@media only screen and (max-width: $size-large-1) {
  .pager-landing {
    display: none;
  }

  body.node-type-template-landing-ng {
    /// BIG BANNER
    article.node-big-banner {
      .media {
        .node-video-html5 {
          height: auto;
        }
      }
    }
    ///--- TEXT BLOCK ---///
    article.node-text-block {
      div.node-text-block {
        padding:0 20px;
      }
    }
    ///-- END TEXT BLOCK ---//
  }
}

// Tablet < 1023
@media only screen and (max-width: $size-medium-large-1) {
  body.node-type-template-landing-ng {
    ///--- CARDS ---///
    .node-cards {
      padding: 30px 0;

      .field-name-field-card {
        & > .field-items {
          //flex-wrap: wrap;
          & > .field-item {
            flex: 0 0 50%;
            margin-bottom: 20px;
            //margin: 0 auto 20px;
            max-width: 350px;

            .card-bottom {
              padding: 15px 20px;

              .field-name-field-link-ng {
                margin-top: 15px;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

        }
      }
    }
    ///--- END CARDS ---///

    /// BIG BANNER
    article.node-big-banner {
      .container {
        .content {
          width: calc(100% - 40px);
          margin: 0 20px;

          h1 {
            line-height: 25px;
            @include x-rem('font-size', 25px);
          }

          .field-name-field-description {
            p {
              @include x-rem('font-size', 12px);
            }
          }
        }
      }
    }

    /// TABS
    article.node-tabs {
      div.tabs-list {
        padding: 0 15px;
      }
    }

    ///--- ICONS INFO ---///
    div.node-icons-info {
      .field-name-field-icon-info {
        & > .field-items {
          & > .field-item {
            flex:0 0 28%;
          }
        }
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1) {
  .page {
    section[role="main"] {
      .title-container {
        padding: 0 10px;

        h1 {
          @include x-rem('font-size', 24px);
        }
      }
    }
  }

  body.node-type-template-landing-ng {
    ///--- CARDS ---///
    .node-cards {
      padding: 30px 0;

      .field-name-field-card {

        .slick-arrow {
          @include x-rem('font-size', 32px);
        }

        & > .field-items {
          //display: block;
          padding: 0 10px;

          .slick-track {
            width: 100%!important;
            transform: none;
            flex-direction: column;

            .slick-slide {
              width: auto!important;
              margin-right: 0;

              & > div > div.field-item {
                margin-bottom: 25px;
              }
            }
          }


          & > .field-item {
            margin: 0 auto 20px;
            width: auto;
            max-width: 490px;

            .field-name-field-image-unique {
              img {
                width: 250px;
              }
            }
          }
        }
      }
    }

    /// CTA
    div.node-call-to-action {
      padding: 30px 0;
      margin: 0 10px;
    }

    /// ICONS-INFO
    div.node-icons-info {
      padding: 30px 0;

      .field-name-field-icon-info {
        & > .field-items {
          display: block;

          & > .field-item {
            margin: 0 10px 30px;
            width: calc(100% - 20px);

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    /// TABS
    article.node-tabs {
      .tabs-btn-container {
        position: relative;

        .centered-container {
          ul.tabs-btn {
            li.tab {
              flex: 0 0 100%;
              display: none;
              padding: 20px 40px;

              &.active {
                display: block;
              }
            }
          }

          .arrows {
            .arrow {
              display: inline-block;
              padding: 3px;
              position: absolute;
              top: 0;
              bottom: 0;
              border: 0;
              margin: auto;
              color: $blue;
              height: 45px;

              &.arrow-left {
                left: 0;
              }

              &.arrow-right {
                right: 0;
              }
            }
          }
        }
      }

      div.tabs-list {
        .field-name-field-tabs {
          & > .field-items {
            & > .field-item {
              article.node-tab {
                margin: 30px 10px 0;

                div.content {
                  display: block;

                  div.field-name-field-image-unique {
                    margin-top: 0;

                    img {
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    /// SLIDER
    article.node-slider {
      padding: 30px 0;

      .node-slide {
        display: block;
        margin: 0 10px;

        .slide-left {
          margin: 0;
        }

        .slide-right {
          padding-top: 20px;

          h1 {
            text-align: center;
          }
        }
      }

      .slick-dots {
        margin: 0;
      }
    }

    /// PRESENTATION
    article.node-presentation {
      padding: 30px 0;

      .pres-container {
        display: block;
        margin: 0 10px;

        .content {
          border-left: 1px solid transparent;
          padding:0 10px;
          margin-left: 10px;
          h1 {
            padding: 15px 0 10px;
          }
          .field-name-field-description {
            border-bottom: none;
            padding: 0 30px 20px 0;
          }
        }
      }
    }

    /// TECHNICAL SHEET
    article.node-technical-sheet {
      padding: 30px 0;

      .ts-container {
        .top-container {
          .content {
            h1 {
              text-align: center;
            }

            .field-name-field-technical-features {
              & > .field-items {
                display: block;
                text-align: center;
              }
            }
          }
        }

        .bottom-container {
          .field-name-field-technical-icons {
            .field-items {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;

              .field-item {
                text-align: center;
                flex: 0 0 33.33%;
                margin: 5px 0;
              }
            }
          }
        }
      }
    }

    // Flexbox component
    .flexbox-component {   

      .field-name-field-flexbox-items {
        .field-items {
          display: flex;        
          flex-direction: column;
        }
      }
    }

    article.node-big-banner {

      .media {
        img {
          border-radius: 0;
          left: 50%;
          margin-left: -384px;
        }
      }
    }
  }
}

