footer {
  @include x-rem('padding', 20px 0 10px);
  background: $grey_footer;

  #block-edensprings-self-service-blocks-ss-footer {
    & > .content {
      display: flex;
      justify-content: space-between;
      @include x-rem('font-size', 15px);
      font-weight: 500;

      & > div {
        align-self: center;

        &.copyright-container {
          color: $grey;
        }
      }
    }
  }

  #block-edensprings-website-ng-blocks-website-ng-v3-footer {
    .footer-top {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      ul.footer-certifications {
        margin: 0;
        padding: 0;

        li {
          margin: 0 10px;
          padding: 0;
          display: inline-block;
        }
      }

      .separator {
        height: 20px;
        width: 1px;
        background: $blue;
        margin: 0 30px;
      }

      ul.footer-social {
        margin: 0;
        padding: 0;
        position: relative;

        li {
          margin: 0 3px;
          padding: 0;
          display: inline-block;

          a, span.js-link {
            text-indent: -9999px;
            width: 23px;
            height: 23px;
            display: inline-block;
            background: url('../images/social-sprite-v3.png') no-repeat transparent;
            opacity: 0.8;
            transition: opacity 0.5s ease;

            &:hover {
              opacity: 1;
            }
          }

          &.twitter {
            a, span.js-link {
              background-position: 0 -69px;
            }
          }

          &.linkedin {
            a, span.js-link {
              background-position: 0 -23px;
            }
          }

          &.youtube {
            a, span.js-link {
              background-position: 0 -46px;
            }
          }

          &.instagram {
            a, span.js-link {
              background-position: 0 -115px;
            }
          }

          &.vk {
            a, span.js-link {
              background-position: 0 -92px;
            }
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      @include x-rem('font-size', 12px);
      flex-direction: column;
      text-align: center;
      
      .footer-copyright {
        letter-spacing: 1.3px;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 0 10px;
          display: inline-block;
          border-right: 1px solid $grey;
          line-height: 14px;

          a,
          span.js-link {
            letter-spacing: 1.3px;

            &.active {
              color: inherit;
            }

            &:hover {
              color: $blue_light;
            }
          }

          &:last-child {
            border: none;
            padding-right: 0;
          }
        }
      }
      // Menu footer (multi level)
      .menu-container {
        flex-wrap: wrap;
        justify-content: center;
        display: flex;

        .group-links {
          margin: 10px 20px;

          .group-title {
            color: $grey;
            font-weight: bold;
            text-align: center;
            display: block;
            @include x-rem('font-size', 14px);
            padding-bottom: 5px;
          }
          ul {
            li {
              display: block;
              text-align: center;
              padding: 5px 0;
              border: none;
              a {
                color: $grey;
                &:hover {
                  color: $blue_light;
                }
              }
            }
          }
        }
      }
    }

    .feedbacks {
      margin-bottom: 10px;
      text-align: center;

      &.trustpilot {
        width: 150px;
        margin: auto;
      }

      a {
        display: block;
      }

      &.reviews iframe {
        height:150px;
        width:75%;
      }
    }
  }
}

// COOKIE BANNER.
#sliding-popup {
  width: 100%;
  background: rgba(0,0,0,0.7);

  .eu-cookie-compliance-banner--default {
    .popup-content {
      max-width: 1200px;
      margin: 10px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        color: $white;
        @include x-rem('font-size', 16px);
        line-height: 18px;
        margin: 0 0 5px;
      }

      p {
        color: $white;
        margin: 0;
      }

      #popup-text {
        float: none;
        max-width: none;

        a {
          color: $white;
        }
      }

      #popup-buttons {
        max-width: none;
        float: none;
        margin-left: 10px;

        button {
          background: none;
          color: $white;
          border: none;
          transform: scaleX(1.3);
        }
      }
    }
  }

  .eu-cookie-compliance-banner--categories {
    a {
      color: $white;
      text-decoration: underline;
    }

    .popup-content {
      padding: 15px;
      color: $white;
      display: flex;

      #popup-text {
        float: none;
        display: flex;
        flex: 80%;

        button {
          display: none;
        }
      }

      #popup-buttons {        
        float: none;
        max-width: none;
        padding: 10px;
        display: flex;
        flex: 20%;
        justify-content: flex-end;        
        align-items: center;

        button {
          background: transparent;
          border: none;
          color: $white;
          padding: 10px 25px;

          &.eu-cookie-compliance-default-button {
            background-color: $orange_new;
            border-radius: 30px;
            padding: 10px 25px;
          }
        }
      }

      .eu-cookie-compliance-categories {
        display: none;
      }

      .eu-cookie-compliance-category > div {
        display: flex;
        align-items: center;

        label {
          @include x-rem('font-size', 18px);
          margin-left: 10px;

        }
      }

      .eu-cookie-compliance-categories-buttons {
        margin-top: 15px;

        button {
          border: none;
          color: $white;
          padding: 10px 25px;

          background: transparent;

          &.eu-cookie-compliance-save-preferences-button {
            background-color: $orange_new;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

body.cookie-no-interaction {
  #sliding-popup {
    .eu-cookie-compliance-banner--categories {
      bottom: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: rgba(29,33,36,.3);

      a {
        color: $blue_light;
        text-decoration: underline;
      }

      .popup-content {
        padding: 15px 40px;
        color: $blue;
        display: flex;
        background: $white;
        width: 100%;
        position: fixed;
        bottom: 0;
        max-width: none;

        #popup-text {
          float: none;
          display: flex;
          flex: 80%;

          button {
            display: none;
          }
        }

        #popup-buttons {
          float: none;
          max-width: none;
          padding: 10px;
          display: flex;
          flex: 20%;
          justify-content: flex-end;
          align-items: center;

          button {
            background: transparent;
            border: none;
            color: $blue;
            padding: 10px 25px;

            &.eu-cookie-compliance-default-button {
              background-color: $orange_new;
              border-radius: 30px;
              padding: 10px 25px;
              color: $white;
            }
          }
        }

        .eu-cookie-compliance-categories {
          display: none;
        }

        .eu-cookie-compliance-category > div {
          display: flex;
          align-items: center;

          label {
            @include x-rem('font-size', 18px);
            margin-left: 10px;

          }
        }

        .eu-cookie-compliance-categories-buttons {
          margin-top: 15px;

          button {
            border: none;
            color: $blue;
            padding: 10px 25px;
            background: transparent;

            &.eu-cookie-compliance-save-preferences-button {
              background-color: $orange_new;
              border-radius: 30px;
              color: $white;
            }
          }
        }
      }
    }
  }
}
