.page-residential-onboarding,
.page-business-onboarding {
  .region-header-top {
    position: relative;
    border: none;
    box-shadow: 0 5px 5px -5px rgb(215, 211, 211);
  }

  #block-edensprings-rob-rob-logo {
    @include x-rem('width', 400px);
    margin: 0 auto;
    padding: 13px 0;
    text-align: center;

    h1 {
      margin: 0;
      padding: 0;

      a, img {
        display: block;
        margin: auto;

        + span {
          display: none;
        }
      }
    }
  }
}
