// TABLET < 1024
@media only screen and (max-width: $size-medium-large-1){
  .node-type-blog {
    .related-post {
      @include x-rem('padding-left', 10px);
      @include x-rem('padding-right', 10px);

      .field-name-field-blog-related-post {
        & > .field-items {
          flex-wrap: wrap;
          justify-content: flex-start;

          & > .field-item {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px); // HACK IE11
            @include x-rem('margin-right', 10px);
            @include x-rem('margin-bottom', 10px);

            &:last-child {
              @include x-rem('margin-right', 10px);
              @include x-rem('margin-bottom', 10px);
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .view-wng-blogs {
    display: block;

    .view-filters {
      display: none;
    }

    .view-content {
      .views-row {
        .blog {
          display: block;
          text-align: center;

          .blog-image {
            margin-right: 0;
          }

          .blog-infos {
            .more-details {
              float: none;
            }
          }
        }
      }
    }

    .item-list {
      .pager {
        li.pager-first,
        li.pager-last {
          display: none;
        }

        li.pager-previous,
        li.pager-next {
          display: block;
        }
      }
    }
  }

  .node-type-blog {
    .content.container {
      box-shadow: none;
      padding: 0 10px;

      .tags {
        display: block;
        text-align: center;

        .field-name-field-blog-category {
          padding: 0;
          margin-bottom: 15px;

          .field-item:last-child {
            margin-right: 0;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column-reverse;

        .field-name-body {
          p {
            margin: 0 0 15px;
          }
        }
        .blog-image {
          flex: 1;
          padding: 0;
        }
      }
    }
  }

  article.node-blog.node-teaser {
    &.node-teaser {
      h1 {
        @include x-rem('font-size', 14px);
        line-height: 16px;
      }

      div.more-details {
        .btn {
          @include x-rem('padding', 12px 20px);
          @include x-rem('font-size', 11px);
        }
      }
    }
  }
}
