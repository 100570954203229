.page-node-169372{
  .node-big-banner{
    .container{
      .content{
        h2{
          font-size: 26px !important;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      .title-container{
        span{
          span{
            display: block;
          }
        }
      }
      &.sticky {
        position: absolute;
        width: 310px;
        z-index: 400;

        .form-item {
          input {
            color: #252D5C !important;
            &.email{
              color: #252D5C !important;
            }
          }
          &.webform-submit{
            color: #fff !important;
          }
          &.webform-component--receive-a-quote{
            border-top: 0 !important;
            margin-bottom: 10px;
            label{
              color: #252D5C !important;
              font-size: 14px !important;
              font-weight: 600 !important;
              line-height: 18px !important;
            }
          }
          &.webform-component-checkboxes {

            label {
              font-size: 12px !important;
              color: #252D5C !important;
              line-height: 18px !important;
            }
          }
        }
        .rtecenter{
          margin: 0;
        }
        fieldset {
          margin-bottom: 5px;
          .fieldset-legend{
            font-weight: 600;
          }
          .fieldset-description{
            font-size: 12px;
            font-weight: 600;
          }
          .form-item{
            &.webform-component-checkboxes{
              border-top: 0 !important;
              label{
                font-size: .875rem !important;
                line-height: 14px !important;
                color: #252D5C !important;
                font-weight: 600 !important;
              }
            }
            select{
              height: 100%;
              &:after {
                display: none !important;
              }
              option{
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.page-node-170590{
  .node-big-banner{
    .container{
      .content{
        h2{
          font-size: 26px !important;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      .title-container{
        span{
          span{
            display: block;
          }
        }
      }
      &.sticky {
        position: absolute;
        width: 310px;
        z-index: 400;

        .form-item {
          input {
            color: #252D5C !important;
            &.email{
              color: #252D5C !important;
            }
          }
          &.webform-submit{
            color: #fff !important;
          }
          &.webform-component--receive-a-quote{
            border-top: 0 !important;
            margin-bottom: 10px;
            label{
              color: #252D5C !important;
              font-size: 14px !important;
              font-weight: 600 !important;
              line-height: 18px !important;
            }
          }
          &.webform-component-checkboxes {

            label {
              font-size: 12px !important;
              color: #252D5C !important;
              line-height: 18px !important;
            }
          }
        }
        .rtecenter{
          margin: 0;
        }
        fieldset {
          margin-bottom: 5px;
          .fieldset-legend{
            font-weight: 600;
          }
          .fieldset-description{
            font-size: 12px;
            font-weight: 600;
          }
          .form-item{
            &.webform-component-checkboxes{
              border-top: 0 !important;
              label{
                font-size: .875rem !important;
                line-height: 14px !important;
                color: #252D5C !important;
                font-weight: 600 !important;
              }
            }
            select{
              height: 100%;
              &:after {
                display: none !important;
              }
              option{
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.page-node-170632{
  .node-big-banner{
    .container{
      .content{
        h2{
          font-size: 26px !important;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      .title-container{
        span{
          span{
            display: block;
          }
        }
      }
      &.sticky {
        position: absolute;
        width: 310px;
        z-index: 400;

        .form-item {
          input {
            color: #252D5C !important;
            &.email{
              color: #252D5C !important;
            }
          }
          &.webform-submit{
            color: #fff !important;
          }
          &.webform-component--receive-a-quote{
            border-top: 0 !important;
            margin-bottom: 10px;
            label{
              color: #252D5C !important;
              font-size: 14px !important;
              font-weight: 600 !important;
              line-height: 18px !important;
            }
          }
          &.webform-component-checkboxes {

            label {
              font-size: 12px !important;
              color: #252D5C !important;
              line-height: 18px !important;
            }
          }
        }
        .rtecenter{
          margin: 0;
        }
        fieldset {
          margin-bottom: 5px;
          .fieldset-legend{
            font-weight: 600;
          }
          .fieldset-description{
            font-size: 12px;
            font-weight: 600;
          }
          .form-item{
            &.webform-component-checkboxes{
              border-top: 0 !important;
              label{
                font-size: .875rem !important;
                line-height: 14px !important;
                color: #252D5C !important;
                font-weight: 600 !important;
              }
            }
            select{
              height: 100%;
              &:after {
                display: none !important;
              }
              option{
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.page-node-170653{
  .node-big-banner{
    .container{
      .content{
        h2{
          font-size: 26px !important;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      .title-container{
        span{
          span{
            display: block;
          }
        }
      }
      &.sticky {
        position: absolute;
        width: 310px;
        z-index: 400;

        .form-item {
          input {
            color: #252D5C !important;
            &.email{
              color: #252D5C !important;
            }
          }
          &.webform-submit{
            color: #fff !important;
          }
          &.webform-component--receive-a-quote{
            border-top: 0 !important;
            margin-bottom: 10px;
            label{
              color: #252D5C !important;
              font-size: 14px !important;
              font-weight: 600 !important;
              line-height: 18px !important;
            }
          }
          &.webform-component-checkboxes {

            label {
              font-size: 12px !important;
              color: #252D5C !important;
              line-height: 18px !important;
            }
          }
        }
        .rtecenter{
          margin: 0;
        }
        fieldset {
          margin-bottom: 5px;
          .fieldset-legend{
            font-weight: 600;
          }
          .fieldset-description{
            font-size: 12px;
            font-weight: 600;
          }
          .form-item{
            &.webform-component-checkboxes{
              border-top: 0 !important;
              label{
                font-size: .875rem !important;
                line-height: 14px !important;
                color: #252D5C !important;
                font-weight: 600 !important;
              }
            }
            select{
              height: 100%;
              &:after {
                display: none !important;
              }
              option{
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.page-node-170569{
  .node-big-banner{
    .container{
      .content{
        h2{
          font-size: 26px !important;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      .title-container{
        span{
          span{
            display: block;
          }
        }
      }
      &.sticky {
        position: absolute;
        width: 310px;
        z-index: 400;

        .form-item {
          input {
            color: #252D5C !important;
            &.email{
              color: #252D5C !important;
            }
          }
          &.webform-submit{
            color: #fff !important;
          }
          &.webform-component--receive-a-quote{
            border-top: 0 !important;
            margin-bottom: 10px;
            label{
              color: #252D5C !important;
              font-size: 14px !important;
              font-weight: 600 !important;
              line-height: 18px !important;
            }
          }
          &.webform-component-checkboxes {

            label {
              font-size: 12px !important;
              color: #252D5C !important;
              line-height: 18px !important;
            }
          }
        }
        .rtecenter{
          margin: 0;
        }
        fieldset {
          margin-bottom: 5px;
          .fieldset-legend{
            font-weight: 600;
          }
          .fieldset-description{
            font-size: 12px;
            font-weight: 600;
          }
          .form-item{
            &.webform-component-checkboxes{
              border-top: 0 !important;
              label{
                font-size: .875rem !important;
                line-height: 14px !important;
                color: #252D5C !important;
                font-weight: 600 !important;
              }
            }
            select{
              height: 100%;
              &:after {
                display: none !important;
              }
              option{
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.page-node-170611{
  .node-big-banner{
    .container{
      .content{
        h2{
          font-size: 26px !important;
        }
      }
    }
  }
  #block-edensprings-website-ng-commonform-website-ng-commonform {
    .webform-wrapper {
      .title-container{
        span{
          span{
            display: block;
          }
        }
      }
      &.sticky {
        position: absolute;
        width: 310px;
        z-index: 400;

        .form-item {
          input {
            color: #252D5C !important;
            &.email{
              color: #252D5C !important;
            }
          }
          &.webform-submit{
            color: #fff !important;
          }
          &.webform-component--receive-a-quote{
            border-top: 0 !important;
            margin-bottom: 10px;
            label{
              color: #252D5C !important;
              font-size: 14px !important;
              font-weight: 600 !important;
              line-height: 18px !important;
            }
          }
          &.webform-component-checkboxes {

            label {
              font-size: 12px !important;
              color: #252D5C !important;
              line-height: 18px !important;
            }
          }
        }
        .rtecenter{
          margin: 0;
        }
        fieldset {
          margin-bottom: 5px;
          .fieldset-legend{
            font-weight: 600;
          }
          .fieldset-description{
            font-size: 12px;
            font-weight: 600;
          }
          .form-item{
            &.webform-component-checkboxes{
              border-top: 0 !important;
              label{
                font-size: .875rem !important;
                line-height: 14px !important;
                color: #252D5C !important;
                font-weight: 600 !important;
              }
            }
            select{
              height: 100%;
              &:after {
                display: none !important;
              }
              option{
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}
/*
 * SIMPLEBAR
 */

.simplebar-track.horizontal {
  display: none;
}

.simplebar-track.vertical {
  background: rgba(255, 255, 255, 0.2);
  width: 5px;
  border-radius: 30px;

  .simplebar-scrollbar {
    right: 0;
    width: 5px;

    &:before {
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      bottom: 0;
    }
  }
}

.simplebar-content {
  .simplebar-container {
    padding-right: 10px;

    p {
      @include x-rem("font-size", 12px);
      line-height: 14px;
      color: $white;
      margin: 0 0 5px;
    }
  }
}

#block-edensprings-website-ng-commonform-website-ng-commonform {
  #gaq-btn {
    display: block;
    position: fixed;
    z-index: 998;
    right: -1px;
    top: 30%;
    cursor: pointer;
    background-color: $blue_form;
    color: $blue;
    transform-origin: 100% 100%;
    padding: 25px 20px;
    vertical-align: middle;
    border-radius: 10px 0 0 10px;

    img {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      @include x-rem("font-size", 18px);
      text-align: center;
      font-weight: 700;
      line-height: 22px;
      max-width: 235px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      i {
        display: none;
      }
    }

    &.on {
      display: none;
    }
  }

  .webform-wrapper {
    &.default {
      width: 40%;
      margin: auto;
      @include box-shadow-content();
      background: $white;
      padding: 30px 50px;

      ul.tab {
        margin: 0 0 30px;
        padding: 0;
        display: flex;
        justify-content: space-between;

        li {
          flex: 0 0 49%;
          margin: 0;
          padding: 10px 5px 12px;
          display: inline-block;
          position: relative;
          text-align: center;
          cursor: pointer;
          border-radius: 30px;
          background-color: rgba(37, 45, 92, 0.25);
          color: $white;
          font-weight: 500;
          letter-spacing: 1.3px;
          transition: all 0.5s ease-out;

          i,
          span {
            vertical-align: middle;
          }

          span {
            margin-left: 5px;
          }

          &.active {
            background-color: $blue;
            cursor: auto;

            &:after {
              content: "";
              width: 0;
              height: 0;
              bottom: -8px;
              position: absolute;
              left: calc(50% - 5px);
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid $blue;
            }
          }

          &:hover {
            background-color: $blue;
          }
          &:first-child {
            margin-right: 2%;
          }
        }
      }

      ul.webform-list {
        margin: 0;
        padding: 0;

        & > li {
          display: none;

          &.active {
            display: block;
          }

          .messages {
            background-image: none;
            background-color: $orange;
            border: none;
            padding: 6px 10px;
            margin: 5px 0;
            color: $white;
            @include x-rem("font-size", 14px);
            border-left: 4px solid $yellow;

            ul {
              li {
                list-style: circle;
              }
            }
            button {
              display: none;
            }
          }

          form {
            overflow: hidden;

            .form-item {
              padding: 4px 0;
              margin: 0;

              input {
                &::-webkit-input-placeholder {
                  color: $grey_label;
                }
                &::-moz-placeholder {
                  color: $grey_label;
                }
                &:-ms-input-placeholder {
                  color: $grey_label;
                }
                &:-moz-placeholder {
                  color: $grey_label;
                }
              }

              &.webform-component > label {
                display: none;
              }

              input {
                padding: 15px 30px;
              }

              select {
                background-color: $white;
                color: $grey_value;
                width: 100%;
                background-position: 98% center;
                border: 1px solid $grey_value;
                padding: 15px 25px;

                &.blue {
                  color: $blue_light;
                }
                &.error {
                  border-color:#E61F2B !important;
                }
              }

              &.webform-component--rgpd {
                height: 78px;
                overflow: hidden;
                padding-bottom: 5px;
                margin: 10px 0;

                a {
                  color: $blue;
                  font-weight: 500;
                }
              }

              &.webform-component-checkboxes {
                padding-top: 5px;
                border-top: 1px solid rgba(0, 0, 0, 0.2);

                .form-item {
                  padding: 0;
                  margin: 15px 0 0;
                }
                input[type="checkbox"] {
                  position: absolute;
                  left: -9999px;

                  & + label {
                    cursor: pointer;

                    &:before {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 16px;
                      height: 16px;
                      border: 2px solid $blue;
                    }
                  }

                  &:checked {
                    & + label {
                      &:before {
                        border: 2px solid $blue;
                      }

                      &:after {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        display: block;
                        width: 8px;
                        height: 8px;
                        background: $blue;
                      }
                    }
                  }
                }

                label {
                  padding-left: 30px;
                  display: inline-block;
                  @include x-rem("font-size", 12px);
                  line-height: 14px;
                  font-weight: 500;
                  color: $grey;
                  vertical-align: top;
                }
              }

              &.webform-component-radios {
                input[type="radio"] {
                  padding: 4px 5px 4px 0;
                  margin: 0;
                  position: absolute;
                  top: 4px;
                  left: 0;
                  height: auto;
                }

                label {
                  padding-left: 20px;
                  display: inline-block;
                  @include x-rem("font-size", 12px);
                  line-height: 16px;
                  color: $grey;
                  vertical-align: top;
                }
              }
            }

            #postal_code_autocomplete {
              position: relative;
              ul {
                display: none;
                position: absolute;
                background: #fff;
                padding: 5px 10px 5px 30px;
                border: 1px solid $blue_light;
                width: 100%;
                z-index: 5;
                margin-top: -5px;
                li {
                  cursor: pointer;
                  padding: 2px 0px;
                  &:hover {
                    color: $blue_light;
                  }
                }
              }
            }

            .form-actions {
              margin: 30px 0 10px;
              text-align: center;

              input[type="submit"] {
                padding: 20px 60px;
                @include x-rem("font-size", 16px);
                font-weight: 500;
              }
            }
          }
        }

        .webform-confirmation {
          padding: 10px 0;
          margin: 20px 0;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);

          p {
            margin: 0;
            color: $grey;
          }
        }

        .links {
          text-align: center;

          a {
            color: $blue_light;
            font-weight: 500;
            @include x-rem("font-size", 14px);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .simplebar-track.vertical {
        background: rgba(0, 0, 0, 0.2);

        .simplebar-scrollbar {
          &:before {
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }

      .simplebar-content {
        .simplebar-container {
          p {
            color: $grey;
          }
        }
      }
    }

    // Sticky form for Template Landing.
    &.sticky {
      display: none;
      position: fixed;
      z-index: 999;
      width: 285px;
      top: 30%;
      right: -330px;
      padding: 10px 15px 5px 25px;
      background: $blue_form;

      span.close {
        position: absolute;
        left: 5px;
        top: 6px;
        width: 14px;
        height: 14px;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        &:before,
        &:after {
          position: absolute;
          left: 6px;
          content: " ";
          height: 15px;
          width: 2px;
          background-color: $white;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      div.title-container {
        margin: 0 0 5px;
        text-align: center;
        color: $blue;
        @include x-rem("font-size", 18px);
        font-weight: 700;

        img,
        span {
          vertical-align: middle;
        }
        span {
          margin-left: 2px;
          br {
            display: none;
          }
        }
      }

      ul.tab {
        margin: 0 0 10px;
        padding: 0;
        display: flex;
        justify-content: space-between;

        li {
          flex: 0 0 49%;
          margin: 0;
          padding: 3px 5px 4px;
          display: inline-block;
          position: relative;
          text-align: center;
          cursor: pointer;
          border-radius: 30px;
          background-color: rgba(37, 45, 92, 0.25);
          color: $white;
          @include x-rem("font-size", 14px);
          transition: all 0.5s ease-out;

          i,
          span {
            vertical-align: middle;
          }

          span {
            margin-left: 5px;
          }

          &.active {
            background-color: $blue;
            cursor: auto;

            &:after {
              content: "";
              width: 0;
              height: 0;
              bottom: -5px;
              position: absolute;
              left: 50%;
              right: 50%;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid $blue;
            }
          }

          &:hover {
            background-color: $blue;
          }

          &:first-child {
            margin-right: 2%;
          }
        }
      }

      ul.webform-list {
        margin: 0;
        padding: 0;

        & > li {
          display: none;

          //&:first-child,
          &.active {
            display: block;
          }

          .messages {
            background-image: none;
            background-color: $orange;
            border: none;
            padding: 3px 10px;
            margin: 5px 0;
            color: $white;
            @include x-rem("font-size", 14px);
            border-left: 4px solid $yellow;

            ul {
              li {
                list-style: circle;
              }
            }
            button {
              display: none;
            }
          }

          form {
            overflow: hidden;

            .form-item {
              padding: 2px 0;
              margin: 0;

              input {
                &::-webkit-input-placeholder {
                  color: $grey_value;
                }
                &::-moz-placeholder {
                  color: $grey_value;
                }
                &:-ms-input-placeholder {
                  color: $grey_value;
                }
                &:-moz-placeholder {
                  color: $grey_value;
                }
              }

              input,
              select {
                padding: 4px 6px;
                border: 0;
                @include x-rem("font-size", 14px);
                height: 24px;
              }

              select {
                background-color: $white;
                color: $grey_value;
                width: 100%;
                background-image: none;

                &.blue {
                  color: $blue_light;
                }
                &.error {
                  border-color:#E61F2B !important;
                }
              }

              &.webform-component-select {
                &:after {
                  content: "▾";
                  transform: scaleX(1.4);
                  color: $blue_light;
                  position: absolute;
                  right: 5px;
                  top: 3px;
                  display: block;
                  pointer-events: none;
                }
              }

              &.webform-component--rgpd {
                height: 48px;
                overflow: hidden;
                padding-bottom: 5px;
                margin: 5px 0 10px;

                a {
                  color: $blue;
                  font-weight: 500;
                }
              }

              &.webform-component-checkboxes {
                padding-top: 5px;
                border-top: 1px solid rgba(255, 255, 255, 0.5);

                input[type="checkbox"] {
                  padding: 4px 5px 4px 0;
                  margin: 0;
                  position: absolute;
                  top: 2px;
                  left: 0;
                  height: auto;
                }

                label {
                  padding-left: 20px;
                  display: inline-block;
                  @include x-rem("font-size", 10px);
                  line-height: 12px;
                  color: $white;
                  vertical-align: top;
                }
              }

              &.webform-component-radios {
                input[type="radio"] {
                  padding: 4px 5px 4px 0;
                  margin: 0;
                  position: absolute;
                  top: 4px;
                  left: 0;
                  height: auto;
                }

                label {
                  padding-left: 20px;
                  display: inline-block;
                  @include x-rem("font-size", 12px);
                  line-height: 16px;
                  color: $white;
                  vertical-align: top;
                }
              }
            }

            .form-actions {
              margin: 0 0 10px;
              text-align: center;

              input[type="submit"] {
                padding: 5px 10px 7px;
                width: 80%;
                @include x-rem("font-size", 16px);
                font-weight: 500;
              }
            }
          }
        }

        .webform-confirmation {
          padding: 10px 0;
          margin: 20px 0 10px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);

          p {
            margin: 0;
            color: $white;
          }
        }

        .links {
          text-align: center;
          margin-bottom: 20px;

          a {
            color: $white;
            font-weight: 500;
            @include x-rem("font-size", 14px);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .commonform-side-text {
    display: table;
    width: 80%;
    margin: auto;
    .webform-wrapper {
      display: table-cell;
    }
  }
  .webform-wrapper.commonform-above-text {
    width: 80%;
    margin-bottom: 40px;
  }
  .webform-wrapper.commonform-below-text {
    width: 80%;
    margin-top: 40px;
  }
}

.container-phone {
  text-align: center;
}

.phone-number {
  text-align: center;

  span.label {
    font-weight: 500;
    display: block;
  }

  a.phone-link {
    @include x-rem("font-size", 28px);
    color: $blue;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0px !important;
  }

  // SPECIAL CASE FRANCE
  &.domain-fr {
    display: inline-block;
    border: 1px solid #dddddd; //grey;
    vertical-align: top;
    font-family: "Arial Bold", "Arial", serif;
    background-color: #78b41e; //$purple_phone;
    position: relative;
    margin: 0 auto;

    span {
      &.digit {
        background-color: $white;
        color: #78b41e; //$purple_phone;
        padding: 2px 5px;
        display: block;
        @include x-rem("font-size", 16px);
        text-align: center;
        font-weight: bold;
        line-height: 25px;
        height: 25px;
        //box-shadow: inset 0 2px 10px -4px rgba(0, 0, 0, 0.75);
      }
      &.phone-price {
        vertical-align: middle;
        @include x-rem("font-size", 12px);
        display: block;
        height: 11px;
        line-height: 11px;
        color: $white;
        font-weight: bold;
        padding-left: 15px;
        text-transform: initial;
        text-align: left;
        margin: 3px 0 0;

        &.border {
          //box-shadow: inset 0 -7px 20px -8px rgba(0, 0, 0, 0.75);
          margin: 0;
          height: 14px;
          line-height: 14px;
          padding-bottom: 3px;
        }
      }
      &.triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: $white transparent transparent transparent;
        position: absolute;
        top: 25px;
        left: 2px;
      }
    }
  }
}

.page-gaq {
  h1 {
    margin: 0 0 30px;
    text-align: center;
  }
}

fieldset.captcha {
  padding: 0;
  border: 0;
  margin: 10px 0;

  legend,
  .fieldset-description {
    display: none;
  }

  div.g-recaptcha {
    & > div {
      margin: auto;
    }
  }
}
