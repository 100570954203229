.node-type-wngv-business-line {

  .introduction {
    text-align: center;

    .field-name-field-wngv-tvip-subtitle {
      font-weight: 700;
      @include x-rem('font-size', 30px);
      @include x-rem('letter-spacing', 1.5px);
      @include x-rem('line-height', 32px);
      color: $blue;
      margin-bottom: 15px;
    }

    .field-name-field-wngv-tvip-sub-description {
      margin-bottom: 30px;

      &:after {
        content: '';
        margin: 30px auto 0;
        width: 2px;
        background: $blue_light;
        height: 60px;
        display: block;
      }
    }
  }

  .field-name-field-illimited-text-vs-image {
    & > .field-items {
      & > .field-item:nth-child(odd) {
        .textvsimage {
          flex-direction: row-reverse;
        }
      }
    }
  }

  .bl-textvsimage {
    box-shadow: 0 4px 4px -4px #E4E3E3;
    padding-bottom: 50px;
  }

  .web-products {
    padding: 60px 0 30px;

    h3 {
      margin: 0 0 30px;
      text-align: center;
      padding: 0 10px;
      color: $blue;
    }

    // List of products
    .field-name-field-wgnv-bl-web-products {
      .slick-slider {
        padding: 0 40px;

        .slick-track {
          display: flex;
          align-items: stretch;
          justify-content: center;
          padding: 10px 5px;

          .slick-slide {
            height: auto;
            margin-right: 20px;

            & > div {
              height: 100%;

              & > div.field-item {
                height: 100%;
              }
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        .slick-arrow {
          position: absolute;
          top: calc(50% - 42px);
          transform: translateY(-50%);
          color: $blue;
          font-weight: bold;
          cursor: pointer;
          z-index: 3;

          &.slick-arrow-left {
            left: 0;
          }
          &.slick-arrow-right {
            right: 0;
          }

          &:hover {
            color: $blue_light;
          }
        }
      }
    }
  }
}
