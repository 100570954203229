// TABLET < 1024
@media only screen and (max-width: $size-large){
  .main-banner {
    .slide-item {
      .content {
        .banner-texts-center,
        .banner-texts-left {
          margin: 0 60px;
          width: auto;
        }
      }
    }
  }
}

// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .main-banner {
    margin-bottom: 30px;

    .slide-item {
      img {
        border-radius: 0;
        left: 50%;
        margin-left: -384px;
        height: 415px;
      }

      .content {
        top: 60px;
        max-height: 100%;

        .banner-texts-center,
        .banner-texts-left {
          width: 90%;
          margin: auto;

          .title {
            @include x-rem('font-size', 30px);
            line-height: 28px;
          }

          .cta-links {
            display: block;
            margin-top: 20px;

            .cta-link {
              @include x-rem('font-size', 20px);
              margin: 10px auto;
            }
          }
        }
      }
    }

    .icons-container {
      display: none;
    }

    .slick-dots {
      top: 370px;
    }
  }
}
