body.node-type-template-landing-ng {

  &.webform-inside {

    ///--- TITLE ---///
    .page section[role='main'] .title-container {
      .webform-container {
        max-width: 1200px;
        margin: 0 auto;
        h1 {
          margin: 0;
          width: calc(100% - 325px);
        }
      }
    }

    ///--- WEBFORM ---///
    #block-edensprings-website-ng-commonform-website-ng-commonform {
      #gaq-btn{
        display:none;
      }
      .webform-wrapper {
        &.sticky {
          left: calc(50% + 315px);
          top: auto;
          display:block;
          padding:10px 15px 5px 15px;
        }
        .close{
          display:none;
        }
      }
    }

    ///--- PRESENTATION ---///
    article.node-presentation {
      .component-container {
        max-width: 1200px;
        margin: 0 auto;
        .pres-container {
          margin: 0;
          width:calc(100% - 325px);
          .media{
            flex: 0 0 470px;
          }
        }
      }
    }

    ///--- SLIDER ---///
    article.node-slider {
      .slick-dots{
        width: calc(100% - 325px);
      }
      article.node-slide {
        width: calc(100% - 325px);

        .slide-left {
          flex: 0 0 450px;
          margin-right: 60px;
        }
      }
    }

    ///--- CALL TO ACTION ---///
    article.node-call-to-action{
      div.node-call-to-action {
        .field {
          width:calc(100% - 325px);
        }
      }
    }

    ///--- CARDS ---///
    article.node-cards {
      & > .field {
        max-width: 1200px;
        margin: 0 auto;
        & > .field-items {
          width: calc(100% - 325px);
          margin: 0;
          //max-width:initial;
          & > .field-item {
            flex: 0 0 420px;
          }
        }
      }
    }

    ///--- ICONS INFO ---///
    article.node-icons-info {
      & > div.node-icons-info {
        & > div.field {
          width: calc(100% - 325px);
        }
      }
    }

    ///--- TEXT BLOCK ---///
    article.node-text-block {
      & > div.node-text-block {
        & > .field,
        & > h1 {
          width: calc(100% - 325px);
        }
      }
    }

    ///--- TECHNICAL SHEET ---///
    article.node-technical-sheet {
      & > .container-centered {
        max-width:1200px;
        margin:0 auto;
        & > .ts-container {
          width: calc(100% - 325px);
          margin: 0;
          //max-width: initial;
          overflow: hidden;
          background: transparent;
          & > .webform-column-container {
            margin:0 auto;
            max-width:1000px;
            overflow:hidden;
            background:#fff;
          }
        }
      }
    }

    ///--- TABS ---///
    article.node-tabs {
      .tabs-btn-container {
        .centered-container {
          ul.tabs-btn {
            width: calc(100% - 325px);
            margin: 0;
          }
        }
      }
      div.tabs-list {
        .field-name-field-tabs {
          width:calc(100% - 325px);
        }
      }
    }
  }
}
