// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .page-help {
    .content-help {
      width: 100%;

      .top-blocks,
      .bottom-blocks {
        display: block;

        & > div {
          margin: 0 0 60px;

          &:first-child {
            margin-right: 0;
          }
        }
      }
    }

    .bottom-open {
      #customer {
        form {
          width: 100%;
        }
      }

      #manage {
        .manage-content {
          width: 100%;
        }
      }
    }
  }
}
