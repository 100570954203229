body.node-type-template-landing-ng {

  ///--- GENERAL SETTINGS ---///
  .page {
    background: none;
    padding: 0;

    section[role="main"] {
      .title-container {
        background: $grey_transparent;

        h1 {
          max-width: 1200px;
          margin: 0 auto;
          padding: 30px 0;
          text-align: center;
          color: $white;
        }
      }
    }
  }

  ///--- CARDS ---///
  .node-cards {
    padding: 30px 0;

    .field-name-field-image-unique {
      img {
        display: block;
        margin: 0 auto;
      }
    }

    .field-name-field-card {
      .slick-arrow {
        color: $blue;
        font-weight: bold;
        cursor: pointer;

        &.slick-disabled {
          visibility: hidden;
        }

        &:hover {
          color: $blue_light;
        }
      }

      & > .field-items {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .slick-track {
          display: flex;
          align-items: stretch;
          justify-content: center;
          padding: 10px 5px;

          .slick-slide {
            height: auto;
            margin-right: 20px;

            & > div {
              height: 100%;

              & > div.field-item {
                height: 100%;
                flex: 1;
                max-width: 490px;
                @include box-shadow-content();
                background: $white;
                margin-bottom: 10px;

                article {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                }

                h1 {
                  @include x-rem('font-size', 24px);
                  font-weight: bold;
                  letter-spacing: 2px;
                  line-height: 22px;
                  padding: 30px 20px 0;
                  margin: 0 0 10px;
                }

                .field-name-field-description {
                  padding: 10px 20px 0;
                  p {
                    @include x-rem('font-size', 15px);
                    line-height: 19px;
                    font-weight: 500;
                  }
                }

                .field-name-field-link-ng {
                  padding: 10px 20px 15px;
                  text-align: center;
                  margin-top: auto;

                  a {
                    display: inline-block;
                    background: #fff;
                    border-radius: 30px;
                    border-width: 1px;
                    border-style: solid;
                    @include x-rem('padding', 12px 25px);
                    font-weight: bold;
                    @include x-rem('font-size', 16px);
                    @include x-rem('letter-spacing', 1.70px);
                    text-align: center;
                    cursor: pointer;
                  }
                }
              }
            }

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  ///--- END CARDS ---///

  ///--- CALL TO ACTION ---///
  div.node-call-to-action {
    margin: 0 auto;
    max-width: 1200px;
    padding: 30px 0;

    .field-name-field-description p {
      margin: 0 auto;
      padding: 0 auto;
      font-weight: bold;
      @include x-rem('font-size', 30px);
      text-align: center;
      padding-bottom: 25px;
    }

    .field-name-field-link-ng {
      .field-item {
        text-align: center;
      }

      a {
        display: inline-block;
        border-radius: 30px;
        font-weight: bold;
        @include x-rem('padding', 10px 60px);
        @include x-rem('font-size', 16px);
        @include x-rem('letter-spacing', 1.70px);
        text-align: center;
        cursor: pointer;
      }
    }
  }
  ///--- END OF CALL TO ACTION ---///

  ///--- ICONS INFO ---///
  div.node-icons-info {
    margin: 0 auto;
    max-width: 1200px;
    padding: 30px 0;

    .field-name-field-icon-info {
      .field-item {
        display: inline-block;
      }

      & > .field-items {
        display: flex;
        justify-content: center;
        align-items: center;

        & > .field-item {
          flex: 0 0 33%;
          margin: 0 20px;
          text-align: center;

          .field-name-field-image-unique {
            .field-item {
              width: 105px;
              height: 105px;
              border-radius: 50%;
              margin: auto;

              img {
                display: block;
                margin: 22.5px auto;
              }
            }
          }

          .field-name-field-description p {
            text-align: center;
            @include x-rem('font-size', 18px);
          }
        }
      }
    }
  }
  ///--- END OF ICONS INFO ---///

  ///--- TABS ---///
  article.node-tabs {
    padding: 0 0 30px;
    .tabs-btn-container {
      background: $white;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      .centered-container {
        max-width:1200px;
        margin:0 auto;
        ul.tabs-btn {
          margin: 0 auto;
          padding: 0;
          max-width: 1200px;
          display: flex;
          justify-content: center;

          li.tab {
            text-align: center;
            @include x-rem('padding', 20px 0);
            flex: 1;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 3px;
            color: $grey_label;
            cursor: pointer;
            position: relative;
            align-self: flex-end;
            
            &.active:before {
              bottom: -7px;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid;
              position: absolute;
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
              content: '';
            }

            &.active:after {
              bottom: -2px;
              left: 0;
              height: 3px;
              width: 100%;
              border-radius: 180px;
              background: $blue;
              position: absolute;
              content: '';
            }

            &.active,
            &:hover {
              color: $blue;
            }

            &.active {
              cursor: default;
            }

            &:before {
              content: '';
            }
          }
        }
      }
    }

    div.tabs-list {
      margin: 0 auto;
      max-width: 1200px;
      overflow: hidden;

      .field-name-field-tabs {
        & > .field-items {
          & > .field-item {
            article.node-tab {
              display: none;
              @include x-rem('margin', 30px 0 0);
              overflow: hidden;

              h1 {
                line-height: 28px;
                @include x-rem('font-size', 26px);
              }

              h2 {
                margin: 0 0 20px;
                font-weight: bold;
                @include x-rem('font-size', 35px);
                letter-spacing: 2px;
                line-height: 40px;
              }
              ul {
                display: block;
                li {
                  ul {
                    padding-left: 15px;
                  }
                }
              }
              div.content {
                display: flex;
                justify-content: space-between;

                div.field-name-field-description {
                  flex: 0 0 50%;

                  p {
                    margin: 0 0 20px;
                    font-weight: 500;
                  }
                }

                div.field-name-field-image-unique {
                  img {
                    display: block;
                  }
                }

              }
            }

            &:first-child {
              article.node-tab {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  ///--- END TABS ---///

  ///--- SLIDER ---///
  article.node-slider {
    padding: 30px 10px;

    .field-name-field-slides-ng {
      margin: 0 auto;
      max-width: 1200px;
      overflow: hidden;

      .slick-slider.single .node-slide .slide-right {
        padding-top: 0;
      }
    }

    .node-slide {
      display: flex;
      padding: 0 0 10px;

      .slide-left {
        flex: 0 0 490px;
        margin: 0 80px 0 0;

        img {
          max-width: 100%;
        }
      }

      .slide-right {
        flex: auto;
        padding-top: 80px;

        h1 {
          margin: 0 0 15px;
          padding: 0;
        }

        .field-name-field-textlist {
          .field-item {
            overflow: hidden;

            i {
              float: left;
            }

            p {
              margin: 0 0 0 30px;
            }
          }
        }

        .field-name-field-link-ng {
          margin-top: 30px;

          a {
            display: inline-block;
            background: transparent;
            border-radius: 30px;
            border-width: 1px;
            border-style: solid;
            @include x-rem('padding', 12px 25px);
            font-weight: bold;
            @include x-rem('font-size', 12px);
            @include x-rem('letter-spacing', 1.70px);
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      left: 600px;
      top: 0;
      cursor: pointer;
      z-index: 2;

      &.slick-arrow-right {
        left: 640px;
      }
    }

    .slick-dots {
      @extend .spacing-reset;
      @include x-rem('margin', 30px 0 0);
      text-align: center;
      display: block!important;

      li {
        display: inline-block;
        @include x-rem('margin', 0 5px);
        position: relative;

        button {
          background: $blue;
          width: 20px; // We have to force width/height to not have bad behavior with REM.
          height: 20px; // We have to force width/height to not have bad behavior with REM.
          max-width: 20px;
          max-height: 20px;
          border: 4px solid rgba(255, 255, 255, 1);
          border-radius: 50%;
          display: block;
          color: transparent;
          cursor: pointer;
          overflow: hidden;
          transition: all 0.5s ease-out;
          padding: 0;

          &[aria-selected="true"] {
            box-shadow: 0 0 0 1px $blue;
          }

          &:hover {
            box-shadow: 0 0 0 1px $blue_light;
            background: $blue_light;
          }
        }

        &:before {
          display: none;
        }
      }
    }
  }
  ///--- END SLIDER ---///

  // FLEXBOX
  .flexbox-component {
    padding: 30px 40px;

    h2 {
      text-align: center;
    }

    .field-name-field-flexbox-items {
      & > .field-items {
        display: flex;
        justify-content: space-around;

        & > .field-item {
          flex: 0 0 22%;
        }
      }
    }

    .flexbox-item {
      text-align: center;
      font-size: 16px;
      color: #252D5C;
      
      svg {
        height: 120px;
        width: 120px;
        fill: $blue_light;
      }

      .item-title {
        font-weight: 800;
        font-size: 19px;
      }
    }
  }
  // END FLEXBOX

  ///--- PRESENTATION ---///
  article.node-presentation {
    padding: 30px 10px;

    .pres-container {
      margin: 0 auto;
      max-width: 1200px;
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      &.reverse {
        flex-direction: row;

        .content{
          .field-name-field-description {
            padding: 0 0 20px 30px;
          }
          h1 {
            padding: 15px 0 10px 30px;
          }
        }
      }

      .content {
        flex: auto;
        align-self: center;

        h1 {
          margin: 0;
          padding: 15px 0 10px;
        }

        .field-name-field-title-display {
          padding-right: 30px;
        }

        .field-name-field-description {
          padding: 0 30px 20px 0;
        }
      }
      .media {
        flex: 0 0 600px;

        .field-name-field-youtube-embed {
          max-height: 385px;
        }

        img {
          display: block;
        }
      }
    }

  }
  ///--- END PRESENTATION ---///

  ///--- TECHNICAL SHEET ---///
  article.node-technical-sheet {
    padding: 30px 10px;

    .ts-container {
      margin: 0 auto;
      max-width: 1000px;
      overflow: hidden;
      background: $white;

      .top-container {
        display: flex;

        .content {
          flex: auto;
          padding: 40px;

          h1 {
            @include x-rem('font-size', 35px);
            letter-spacing: 1.6px;
            font-weight: bold;
          }

          .field-name-field-technical-features {
            & > .field-items {
              display: flex;
              flex-wrap: wrap;

              & > .field-item {
                flex: 0 0 50%;

                article.node-technical-sheet-element {
                  margin-bottom: 15px;
                }

                span {
                  @include x-rem('font-size', 16px);
                  letter-spacing: 2.5px;
                  font-weight: 500;
                }

                .field-name-field-description {
                  p {
                    margin: 0;
                    padding: 0;
                  }
                }
              }
            }
          }
        }

        .image {
          flex: 0 0 500px;

          img {
            display: block;
          }
        }
      }

      .bottom-container {
        border-top: 2px solid transparent;
        padding: 20px 10px;

        .field-name-field-technical-icons {
          .field-items {
            display: flex;
            justify-content: center;

            .field-item {
              text-align: center;
              flex: 0 0 20%;
            }
          }
        }
      }
    }
  }
  ///--- END TECHNICAL SHEET ---///

  ///--- BIG BANNER ---///
  article.node-big-banner {
    position: relative;

    .media {
      max-width: 1920px;
      margin: 0 auto;

      .node-video-html5 {
        height: 750px;
        overflow: hidden;

        .video-js {
          position: relative !important;
          width: 100% !important;
          max-width: 1920px;
          margin: 0 auto;
          height: auto !important;

          .vjs-tech {
            position: relative !important;
            max-width: 1920px;
            margin: 0 auto;
            width: 100% !important;
            height: auto !important;
          }
        }
      }

      img {
        display: block;
        position: relative;
        left: 50%;
        margin-left: -960px;
        max-width: none;
      }
    }

    .container {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      max-width: 1200px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      height: inherit;

      .content {
        width: 500px;

        h1, h2 {
          @include x-rem('font-size', 34px);
          letter-spacing: 2px;
          line-height: 34px;
          margin: 0;
          padding: 0;
        }
      }
    }

  }
  ///--- END BIG BANNER ---///

  ///--- TEXT BLOCK ---///
  article.node-text-block {
    margin: 0 auto;
    padding: 30px 0;

    div.node-text-block {
      max-width: 1200px;
      margin: 0 auto;

      .field-name-body {
        p {
          padding: 0;
        }
      }

      h1 {
        padding: 0;
      }
    }
  }
  ///-- END TEXT BLOCK ---//

  article.node-wngh-clients {
    margin-top: 30px;
  }
  
  ///--- PAGER LANDING ---///
  .pager-landing {
    position: fixed;
    top: 50%;
    left: 15px;
    transform: translate(-50%, -50%);
    z-index: 50;
    //background: $grey_light;
    padding: 15px 10px;
    //border-radius: 0 8px 8px 0;
    //@include box-shadow-content();

    .item-pager {
      position: relative;
      width: 10px;
      height: 10px;
      background: $grey_bullet;
      border-radius: 50%;
      display: block;
      margin: 30px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        background: $white;
      }

      &:not(:last-child):after {
        display: block;
        top: 20px;
        left: 4px;
        position: absolute;
        content: '';
        height: 10px;
        width: 1px;
        background: $grey_bullet;
      }
    }
  }
  ///--- END PAGER LANDING ---///
}

