.well-being {
  .characteristic-block {
    padding: 60px 0;

    &:first-child {
      padding-top: 0;
    }

    .container {
      display: flex;

      .characteristic {
        flex: 0 0 50%;
        display: flex;
        align-items: center;

        .characteristic-image {
          display: inline-block;
          background: $white;
          padding: 25px;
          @include box-shadow-content();
          border-radius: 50%;
          margin-right: 15px;
          min-width: 150px; // IE11

          svg {
            height: 100px;
            width: auto;
            fill: url(#icon-gradient) $blue_light;
            vertical-align: middle;
          }
        }

        .characteristic-body {
          padding: 0 40px 0 15px;

          h2 {
            margin-top: 0;
            @include x-rem('font-size', 20px);
          }
        }
      }
    }

    &:nth-child(even) {
      background: $white_medium;
    }
  }
}
