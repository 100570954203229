// MOBILE < 490
@media only screen and (max-width: $size-medium-1){
  .view-faq-wng {
    display: block;

    .view-filters {
      margin: 0 0 20px;
    }

    .view-content {
    }
  }
}
